import React, { useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Button, Flex, Logo, Text, Input } from 'ui';

const LOGIN = gql`
  query Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      email
      id
      token
    }
  }
`;

export const Login = () => {
  const [goodCreds, setCreds] = useState(true);
  const [tryLogin, { loading }] = useLazyQuery(LOGIN, {
    onError: () => setCreds(false),
    onCompleted: (data) => {
      localStorage.setItem('token', data.login.token);
      window.location.reload();
    },
  });

  const { handleSubmit, register } = useForm();

  const handleLogin = handleSubmit((values) => {
    tryLogin({ variables: { email: values.email, password: values.password } });
  });

  return (
    <Flex
      alignItems="center"
      backgroundColor="white"
      height="100vh"
      justifyContent="center"
      width="100%"
    >
      <Flex
        alignItems="center"
        as="form"
        bg="baseLight2"
        flexDirection="column"
        mt="lg"
        onSubmit={handleLogin}
        p="xl"
        verticalGap="md"
        sx={{
          borderColor: 'base',
          borderRadius: 'md',
          borderStyle: 'solid',
          borderWidth: 'md',
          justifyItems: 'center',
          minWidth: 350,
        }}
      >
        <Logo />
        <Input
          label="Email"
          name="email"
          ref={register}
          onChange={() => setCreds(true)}
          type="text"
        />
        <Input
          label="Password"
          name="password"
          ref={register}
          onChange={() => setCreds(true)}
          type="password"
        />
        {!goodCreds && <Text color="error">Wrong user name or password</Text>}
        <Flex justifyContent="center" mt="lg">
          <Button text="Login" isLoading={loading} />
        </Flex>
      </Flex>
    </Flex>
  );
};
