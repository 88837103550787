import React, { forwardRef } from 'react';
import { Flex as FlexR } from 'rebass';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Flex = forwardRef(
  (
    { horizontalGap, rowResponsive = {}, sx, verticalGap, wrapGap, ...props },
    ref
  ) => {
    const verticalGapStyle = verticalGap && {
      '& > *:not(:last-child)': {
        marginBottom: verticalGap,
      },
    };

    const horizontalGapStyle = horizontalGap && {
      '& > *:not(:last-child)': {
        marginRight: horizontalGap,
      },
    };

    return (
      <FlexStyled
        {...props}
        ref={ref}
        rowResponsive={rowResponsive}
        wrapGap={wrapGap}
        sx={{
          ...sx,
          ...verticalGapStyle,
          ...horizontalGapStyle,
        }}
      />
    );
  }
);

export default Flex;

const FlexStyled = styled(FlexR)`
  ${({ wrapGap, theme }) =>
    wrapGap &&
    css`
      flex-flow: wrap;

      margin: calc(-1 * ${theme.space[wrapGap]}) 0 0
        calc(-1 * ${theme.space[wrapGap]});

      width: calc(100% + ${theme.space[wrapGap]});

      & > * {
        margin: ${theme.space[wrapGap]} 0 0 ${theme.space[wrapGap]};
      }
    `}

  ${({ rowResponsive: { screen, gap = 'md', isReverse, alignItems }, theme }) =>
    screen &&
    css`
      flex-direction: row;

      & > *:not(:last-child) {
        margin-right: ${theme.space[gap]};
      }

      @media (max-width: ${theme.mediaBreakpoints[screen]}) {
        flex-direction: ${isReverse ? 'column-reverse' : 'column'};

        ${alignItems &&
        css`
          align-items: ${alignItems};
        `}

        & > *:not(:last-child) {
          margin-right: 0;

          ${isReverse
            ? css`
                margin-top: ${theme.space[gap]};
              `
            : css`
                margin-bottom: ${theme.space[gap]};
              `}
        }
      }
    `}
`;
