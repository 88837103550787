import React from 'react';

import { Box, Text, Flex, Input, H2 } from 'ui';

export const FieldItem = ({ header, values, name, onChange }) => (
  <Box>
    <H2>{header}</H2>
    <Flex alignItems="center" mt="md">
      <Input
        label="From"
        type="number"
        id="from"
        name={name.from}
        value={values.from}
        onChange={(e) => onChange(name.from, e.target.value)}
      />
      <Text mx="lg" mt="md">
        -
      </Text>
      <Input
        label="To"
        type="number"
        id="to"
        name={name.to}
        value={values.to}
        onChange={(e) => onChange(name.to, e.target.value)}
      />
    </Flex>
  </Box>
);
