import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { SelectMultiple, Flex, Text, H2 } from 'ui';

export const Tags = ({ store_id }) => {
  const GET_STORE_TAGS = gql`
    query GetStoreTags($id: Int!) {
      stores_by_pk(id: $id) {
        id
        tags
      }
    }
  `;

  const UPDATE_STORE_TAGS = gql`
    mutation UpdateTags($store_id: Int!, $tags: jsonb!) {
      update_stores_by_pk(
        pk_columns: { id: $store_id }
        _set: { tags: $tags }
      ) {
        id
        tags
      }
    }
  `;

  const { data } = useQuery(GET_STORE_TAGS, {
    variables: { id: store_id },
  });

  const [updateTags] = useMutation(UPDATE_STORE_TAGS, {
    variables: { store_id },
    // refetchQueries: ['UpdateTags']
  });

  const handleUpdateTags = (tags) => {
    updateTags({ variables: { store_id, tags } });
  };

  const tags =
    data &&
    data.stores_by_pk &&
    data.stores_by_pk.tags !== null &&
    data.stores_by_pk.tags.map((t) => {
      return { label: t, value: t };
    });

  return (
    <Flex flexDirection="column">
      <H2>Add custom tags you can use to flag contacts.</H2>
      <Text mt="lg">
        You can use the search filter only with the tags that are recorder here.
      </Text>
      <Text fontWeight="semiBold" isBlock mt="lg">
        Warning:
      </Text>
      <Text as="p" mb="lg">
        {`If you remove tags you already used to flag
        a contact you won't be able to filter those tags in search. Filter uses
        only tags that are recorded here.`}
      </Text>
      <SelectMultiple
        existingOptions={tags}
        label="Tags"
        updateOptions={handleUpdateTags}
      />
    </Flex>
  );
};
