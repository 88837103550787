import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export const useAssignedAgentsQuery = ({ storeId } = {}) => {
  const { data, loading, error } = useQuery(UsersFetchAllQuery);

  const users = data?.users || [];

  const checkUserAssignedToStore = (user_id) => {
    for (const u of users) {
      for (const r of u.user_roles) {
        if (r.store_id === storeId && u.id === user_id) {
          return true;
        }
      }
    }

    return false;
  };

  const usersByStore = useMemo(
    () => users.filter((user) => checkUserAssignedToStore(user.id)),
    [users, storeId]
  );

  return { users, usersByStore, checkUserAssignedToStore, loading, error };
};

const UsersFetchAllQuery = gql`
  query UsersFetchAll {
    users(order_by: { name: asc }) {
      id
      name
      user_roles {
        store_id
      }
    }
  }
`;
