import React, { useEffect } from 'react';

import AccordionItem from './AccordionItem';

const Accordion = ({
  defaultOpenIndex = [],
  openIndex,
  isMultiOpen = false,
  items = [],
  onItemClick,
}) => {
  const [activeList, setActiveList] = React.useState(
    openIndex || items.map((_, index) => defaultOpenIndex.includes(index))
  );

  useEffect(() => {
    setActiveList(openIndex);
  }, [openIndex]);

  const handleClick = (clickedIndex) => {
    const newActiveList = activeList.map((isActive, index) => {
      if (index === clickedIndex) {
        return !isActive;
      }

      return isMultiOpen ? isActive : false;
    });

    setActiveList(newActiveList);

    onItemClick?.({
      index: clickedIndex,
      item: items[clickedIndex],
      list: newActiveList,
    });
  };

  return (
    <>
      {items.map(
        ({ label, content, isHidden }, index) =>
          !isHidden && (
            <AccordionItem
              key={index}
              isCollapsed={!activeList[index]}
              label={label}
              onClick={() => handleClick(index)}
            >
              {content}
            </AccordionItem>
          )
      )}
    </>
  );
};

export default Accordion;
