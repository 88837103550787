import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import validate from 'validate.js';
import toastr from 'toastr';

import { Button, Flex, Input } from 'ui';

export const CRM = ({ store_id }) => {
  const [CRMEmail, setCRMEmail] = useState('');
  const [valid, setValid] = useState(true);

  useQuery(
    gql`
      query StoreCRM($store_id: Int!) {
        store_communication_by_pk(store_id: $store_id) {
          store_crm_email
        }
      }
    `,
    {
      variables: { store_id: Number(store_id) },
      onCompleted: (vals) => {
        const email = vals.store_communication_by_pk.store_crm_email;
        setCRMEmail(email ? email : '');
      },
    }
  );

  const [saveEmail] = useMutation(
    gql`
      mutation SaveEmail($store_id: Int!, $email: String!) {
        update_store_communication_by_pk(
          pk_columns: { store_id: $store_id }
          _set: { store_crm_email: $email }
        ) {
          store_crm_email
        }
      }
    `,
    {
      refetchQueries: ['StoreCRM'],
      onCompleted: () =>
        toastr.success('Saved', '', {
          positionClass: 'toast-top-center',
          proggressBar: true,
          timeOut: 1000,
        }),
    }
  );

  return (
    <Flex flexDirection="column" alignItems="flex-start">
      <Input
        type="text"
        label="CRM Email"
        error={!valid && 'Invalid email'}
        onChange={(e) => setCRMEmail(e.target.value)}
        value={CRMEmail}
      />
      <Button
        mt="md"
        onClick={() => {
          if (validate({ email: CRMEmail }, { email: { email: true } })) {
            return setValid(false);
          }

          setValid(true);

          saveEmail({
            variables: { email: CRMEmail, store_id: Number(store_id) },
          });
        }}
        text="Save"
      />
    </Flex>
  );
};
