import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Messages } from 'components';
import { GET_MESSAGES } from 'graphql/queries/Messages';
import { GET_VEHICLE_DETAILS } from 'graphql/queries/Vehicles';
import { DISMISS_VEHICLE, UPDATE_STATUS } from 'graphql/mutations/Vehicles';
import {
  SEND_MESSAGE,
  DELETE_SCHEDULED_MESSAGE,
  TOGGLE_SEEN,
} from 'graphql/mutations/Messages';
import { NEW_MESSAGE } from 'graphql/subscriptions/Messages';
import { useToggle } from 'lib';

export const MessagesContainer = ({
  isActive,
  isResponsive,
  onBack,
  onNext,
}) => {
  const { vehicle_id, contact_id } = useParams();
  const [madeUnread, setUnread] = useState(false);
  const [open, toggle] = useToggle(false);
  const { handleSubmit, register, reset } = useForm();

  const { subscribeToMore, data, loading, error } = useQuery(GET_MESSAGES, {
    variables: { contact_id: contact_id ? Number(contact_id) : 0 },
    fetchPolicy: 'network-only',
  });

  const [dismiss] = useMutation(DISMISS_VEHICLE);
  const [toggleSeen] = useMutation(TOGGLE_SEEN);

  const [moveTo] = useMutation(UPDATE_STATUS, {
    update(
      cache,
      {
        data: {
          update_vehicle_status_by_pk: { vehicle: movedVehicle },
        },
      }
    ) {
      cache.modify({
        fields: {
          vehicles(existingVehicles = [], { readField }) {
            const movedMove = existingVehicles.filter(
              (x) => movedVehicle.id !== readField('id', x)
            );

            return [...movedMove];
          },
        },
      });
    },
  });

  const defaultStatusFlags = {
    active: false,
    appointment: false,
    appointment_full: false,
    archived: false,
    contacted: false,
    dismissed: false,
    disqualified: false,
    dormant: false,
    follow_up: false,
    human_appraised: false,
    incomplete: false,
    machine_appraised: false,
    purchased: false,
    qualified: false,
    unavailable: false,
    sent_to_crm: false,
  };

  const handleMoveTo = (moveToStatus) => {
    const vars = {
      vehicle_id: Number(vehicle_id),
      _set: {
        ...defaultStatusFlags,
      },
    };
    vars._set[moveToStatus] = true;

    moveTo({
      variables: {
        ...vars,
      },
    });
  };

  const { loading: vehicleLoading, data: vehicleDetails } = useQuery(
    GET_VEHICLE_DETAILS,
    {
      variables: { id: vehicle_id ? Number(vehicle_id) : 0 },
    }
  );

  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [deleteScheduledMessage] = useMutation(DELETE_SCHEDULED_MESSAGE, {
    refetchQueries: ['Messages'],
  });

  const messages = data ? data.messages : [];

  const vehicle =
    vehicleDetails && vehicleDetails.vehicles_by_pk
      ? vehicleDetails.vehicles_by_pk
      : '';

  const handleWriteMessage = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
      e.preventDefault();
      reset();
    }
  };

  const toggleDismiss = (id) => {
    dismiss({
      variables: { vehicle_id: id, dismissed: toggle, active: !toggle },
    });
  };

  const handleSendMessage = handleSubmit(({ message }) => {
    if (message === '') {
      return;
    }

    sendMessage({
      variables: {
        delivered: false,
        direction: 'outbound',
        store_id: vehicle.store_id,
        content: JSON.stringify({ message }),
        contact_id: vehicle.contact.id,
        vehicle_id: vehicle.id,
        created_at: new Date(),
      },
      refetchQueries: ['Messages'],
    });

    reset();
  });

  // This might leave a lead without any status
  const handleDeleteScheduledMessage = (msg) => {
    let status = {};

    switch (msg.type) {
      case 'schedule_follow_up':
        status = { follow_up: false };
        break;
      case 'schedule_appointment':
        status = { appointment: false };
        break;
      default:
        break;
    }

    deleteScheduledMessage({
      variables: {
        msg_id: msg.id,
        vehicle_id: msg.vehicle_id,
        status,
      },
    });
  };

  useEffect(() => {
    if (madeUnread) {
      setUnread(false);
    } else {
      if (vehicle_id) {
        toggleSeen({
          variables: {
            set_seen: true,
            vehicle_id,
          },
        });
      }
    }
  }, [vehicle_id]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: NEW_MESSAGE,
      variables: { contact_id: Number(contact_id) },
      updateQuery: (prev, { subscriptionData }) => {
        toggleSeen({ variables: { set_seen: true, vehicle_id } });
        if (isEmpty(prev.messages)) {
          return;
        }
        if (isEmpty(prev) && !isEmpty(subscriptionData)) {
          return subscriptionData.data;
        }
        if (subscriptionData.data.messages.length && prev.messages.length) {
          const newMessages = subscriptionData.data.messages;
          let hasMessageAlready = false;

          for (const msg of prev.messages) {
            if (msg.id === newMessages[0].id) {
              hasMessageAlready = true;
            }
          }

          if (hasMessageAlready) {
            return { ...prev, messages: [...prev.messages] };
          }

          return { ...prev, messages: [...prev.messages, ...newMessages] };
        }
      },
    });

    reset();

    if (unsubscribe) {
      return () => unsubscribe();
    }
  }, [vehicle_id, subscribeToMore]);

  return (
    <Messages
      error={error}
      handleDeleteScheduledMessage={handleDeleteScheduledMessage}
      handleMoveTo={handleMoveTo}
      handleSendMessage={handleSendMessage}
      handleWriteMessage={handleWriteMessage}
      isActive={isActive}
      isResponsive={isResponsive}
      loading={loading}
      messages={messages}
      onBack={onBack}
      onNext={onNext}
      open={open}
      register={register}
      toggle={toggle}
      toggleDismiss={toggleDismiss}
      vehicle={vehicle}
      vehicleLoading={vehicleLoading}
    />
  );
};
