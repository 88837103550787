/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import ReactDOM from 'react-dom';
import { split } from 'apollo-link';
import { ApolloClient, HttpLink, ApolloProvider } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from 'apollo-utilities';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';
import { cache } from './cache';
import { HasuraUrl, HasuraWebsocketUrl } from './lib';
import App from './App';

TimeAgo.addDefaultLocale(en);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      ...(token && { authorization: token ? `Bearer ${token}` : '' }),
    },
  };
});

const httpLink = new HttpLink({ uri: HasuraUrl });
const wsLink = new WebSocketLink({
  uri: HasuraWebsocketUrl,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
