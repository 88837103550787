import React, { useState, useRef, useEffect } from 'react';
import { uniq } from 'ramda';
import styled from '@emotion/styled';

import MessageAlertOwner from './MessageAlertOwner';
import MessageBubble from './MessageBubble';
import MessageDatePicker from './MessageDatePicker';
import MessagesSchedule from './MessagesSchedule';
import MessagesTitleBar from './MessagesTitleBar';
import {
  Box,
  Button,
  ButtonIcon,
  EmptyVehiclePlaceholder,
  ErrorPlaceholder,
  Flex,
  Loader,
  Modal,
  Scrollable,
  Textarea,
} from 'ui';

export const Messages = ({
  error,
  handleDeleteScheduledMessage,
  handleMoveTo,
  handleSendMessage,
  handleWriteMessage,
  isActive,
  isResponsive,
  loading,
  messages,
  onBack,
  onNext,
  open,
  register,
  toggle,
  vehicle,
}) => {
  const [isShowDateModal, setShowDateModal] = useState(false);
  const [typeModal, setModalType] = useState('');
  const messagesContainer = useRef(null);

  const { make, id, contact, store_id, contact_handle, owner_id } = vehicle;
  const hasSelectedVehicle = Boolean(vehicle);
  const isChatDisabled = !hasSelectedVehicle || !owner_id;

  const handleChooseTypeMessage = (type) => {
    setModalType(type);
    setShowDateModal(true);
    toggle();
  };

  useEffect(() => {
    if (messagesContainer && messagesContainer.current) {
      const msg = messagesContainer.current;

      return msg.scrollTo({
        top: msg.scrollHeight,
      });
    }
  });

  const getTextareaTooltip = () => {
    if (isChatDisabled) {
      if (!hasSelectedVehicle) {
        return 'Please select a vehicle first';
      }

      if (!owner_id) {
        return 'Please assign to a user first';
      }
    }

    return '';
  };

  const renderMessages = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <ErrorPlaceholder />;
    }

    if (!hasSelectedVehicle) {
      return <EmptyVehiclePlaceholder />;
    }

    return messages
      .filter((message) => message.sent === true)
      .map((message) => <MessageBubble {...message} key={message.id} />);
  };

  return (
    <Flex
      bg="white"
      display={isResponsive && !isActive && 'none !important'}
      flexDirection="column"
      height="full"
    >
      <MessagesTitleBar
        contactHandle={contact_handle}
        handleMoveTo={handleMoveTo}
        hasResponsiveControls={isResponsive}
        onBack={onBack}
        onNext={onNext}
        toggle={toggle}
        vehicle={vehicle}
      />
      <Flex flexDirection="column">
        {uniq(messages)
          .filter((message) => message.sent === false)
          .map((message, index) => (
            <MessagesSchedule
              key={index}
              message={message}
              deleteMessage={handleDeleteScheduledMessage}
            />
          ))}
      </Flex>
      {vehicle && !loading && !error && (
        <MessageAlertOwner
          hasOwnerId={Boolean(owner_id)}
          ownerId={owner_id}
          storeId={store_id}
          vehicleId={id}
        />
      )}
      <Scrollable
        my="sm"
        pl="lg"
        pr="md"
        ref={messagesContainer}
        sx={{ flex: '1', overflowX: 'hidden' }}
      >
        {renderMessages()}
      </Scrollable>
      <Box
        p="lg"
        sx={{
          position: 'relative',
          backgroundColor: 'baseLight2',
          borderTopWidth: 'md',
          borderTopStyle: 'solid',
          borderTopColor: 'base',
        }}
        as="form"
        onSubmit={handleSendMessage}
        name="general_message"
      >
        <TextareaStyled
          name="message"
          onKeyPress={handleWriteMessage}
          placeholder="Enter message..."
          isDisabled={isChatDisabled}
          ref={register}
          title={getTextareaTooltip()}
        />
        <ButtonIcon
          icon="send"
          isDisabled={isChatDisabled}
          sx={{
            position: 'absolute',
            right: '45px',
            top: '55%',
            transform: 'translateY(-65%)',
          }}
        />
      </Box>
      <Modal header="Schedule" cancel="Close" isOpen={open} onClose={toggle}>
        <Flex flexDirection="column" verticalGap="md" alignItems="center">
          <Button
            variant="secondary"
            text="Follow Up"
            onClick={() => handleChooseTypeMessage('Follow Up')}
          />
          <Button
            variant="secondary"
            text="Reminder"
            onClick={() => handleChooseTypeMessage('Reminder')}
          />
          <Button
            variant="secondary"
            text="Appointment"
            onClick={() => handleChooseTypeMessage('Appointment')}
          />
        </Flex>
      </Modal>
      <MessageDatePicker
        contact_id={contact?.id}
        isShowDateModal={isShowDateModal}
        make={make}
        setShowDateModal={setShowDateModal}
        showTimeInput
        store_id={store_id}
        type={typeModal}
        vehicle_id={id}
        withMessage
      />
    </Flex>
  );
};

const TextareaStyled = styled(Textarea)`
  textarea {
    padding-right: 80px;
  }
`;
