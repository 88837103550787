import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';

import Text from './Text';

const FormControl = ({ label, error, children, ...props }) => (
  <Flex {...props} flexDirection="column" width="full">
    {label && (
      <Text as="label" mb="xs">
        {label}
      </Text>
    )}
    {children}
    {error && (
      <Text mt="xs" color="error">
        {error}
      </Text>
    )}
  </Flex>
);

FormControl.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node,
};

export default FormControl;
