import React from 'react';

const IconSend = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="19.476"
    height="18.891"
    viewBox="0 0 19.476 18.891"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" />
      </linearGradient>
    </defs>
    <g id="noun_send_887299" transform="translate(-7.538 -5.371)">
      <g id="Group_12" data-name="Group 12" transform="translate(7.538 5.372)">
        <path
          id="Path_16"
          data-name="Path 16"
          d="M.534,9.566c-.738.387-.707.954.066,1.26l2.291.906a2.936,2.936,0,0,0,2.6-.372l9.949-7.846c.654-.514.724-.439.156.17L7.733,12.1a.951.951,0,0,0,.375,1.651l.268.1c.776.3,2.042.8,2.813,1.118L13.73,16.01a1.314,1.314,0,0,0,1.816-.859L19.418.916c.219-.8-.206-1.144-.943-.758Z"
          transform="translate(0 0)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          d="M9.735,26.778c-.045.136,1.567-2.334,1.567-2.334a1.207,1.207,0,0,0-.573-1.842l-1.758-.72c-.77-.315-1.139.055-.82.824C8.15,22.706,9.781,26.638,9.735,26.778Z"
          transform="translate(-2.913 -7.893)"
          fill="url(#linear-gradient)"
        />
      </g>
    </g>
  </svg>
);

export default IconSend;
