import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';

import {
  Profile,
  Filter,
  AutoSMS,
  Tags,
  CRM,
  AssignedAgents,
} from './components';
import SettingsThemeSwitch from './SettingsThemeSwitch';
import {
  Box,
  Button,
  ButtonIcon,
  Flex,
  Select,
  Text,
  Tab,
  Loader,
  Scrollable,
} from 'ui';
import { useWindowSpec, useRouteValue } from 'lib';

const TabLabel = {
  AssignedAgents: 'Assigned Agents',
  AutoSMS: 'Auto SMS',
  CRM: 'CRM',
  Filter: 'Filter',
  General: 'General',
  Tags: 'Tags',
};

const Panel = {
  StoreList: 1,
  Tab: 2,
};

export const Settings = ({ stores, isLoading, error }) => {
  const history = useHistory();
  const isMaster = jwtDecode(localStorage.getItem('token')).name === 'Master';
  const [activeStore, setActiveStore] = useState('');

  const handleStoreChange = useRouteValue({
    name: 'store',
    onChange: setActiveStore,
    isNumber: true,
  });

  useEffect(() => {
    if (activeStore) {
      return;
    }

    const defaultActiveStore = stores[0]?.id;
    if (defaultActiveStore) {
      handleStoreChange(defaultActiveStore);
    }
  }, [activeStore, stores]);

  const [activePanel, setActivePanel] = useState(Panel.StoreList);
  const { checkResponsive } = useWindowSpec();
  const isResponsive = checkResponsive('md');

  useEffect(() => setActivePanel(Panel.StoreList), [isResponsive]);

  const handleBack = () => isResponsive && setActivePanel(activePanel - 1);
  const handleNext = () => isResponsive && setActivePanel(activePanel + 1);

  const tabs = [
    {
      id: 'general',
      label: TabLabel.General,
      render: () => (
        <SettingsTabContainer>
          <Profile store_id={activeStore} />
        </SettingsTabContainer>
      ),
    },
    {
      id: 'filter',
      label: TabLabel.Filter,
      render: () => (
        <SettingsTabContainer>
          <Filter store_id={activeStore} />
        </SettingsTabContainer>
      ),
    },
    {
      id: 'autosms',
      label: TabLabel.AutoSMS,
      render: () => (
        <SettingsTabContainer>
          <AutoSMS store_id={activeStore} />
        </SettingsTabContainer>
      ),
    },
    {
      id: 'tags',
      label: TabLabel.Tags,
      render: () => (
        <SettingsTabContainer>
          <Tags store_id={activeStore} />
        </SettingsTabContainer>
      ),
    },
    {
      id: 'crm',
      label: TabLabel.CRM,
      render: () => (
        <SettingsTabContainer>
          <CRM store_id={activeStore} />
        </SettingsTabContainer>
      ),
    },
    {
      id: 'agents',
      label: TabLabel.AssignedAgents,
      render: () => (
        <SettingsTabContainer>
          <AssignedAgents store_id={activeStore} />
        </SettingsTabContainer>
      ),
    },
  ].filter(
    (tab) =>
      isMaster ||
      (!isMaster &&
        ![TabLabel.Filter, TabLabel.AssignedAgents].includes(tab.label))
  );

  const convertStoreToOptions = (param) => {
    return param.map((x) => {
      return { label: x.name, value: x.id };
    });
  };

  const storeNameByID = (id) => {
    for (const s of stores) {
      if (s.id === id) {
        return s.name;
      }
    }
  };

  const handleLogoutClick = () => {
    history.push('/');
    localStorage.removeItem('token');
  };

  if (isLoading) {
    return (
      <Box width="full" height="full" backgroundColor="white">
        <Loader p="lg" />
      </Box>
    );
  }

  if (error) {
    return (
      <Text p="lg" isBlock>
        {error}
      </Text>
    );
  }

  return (
    <Flex height="full">
      <Scrollable
        sx={{
          borderRightColor: 'base',
          borderRightStyle: 'solid',
          borderRightWidth: 'md',
          ...(isResponsive
            ? {
                display: activePanel !== Panel.StoreList ? 'none' : 'block',
                flex: '1',
              }
            : { flex: '0 0 350px' }),
        }}
        bg="baseLight2"
        color="black"
      >
        <Box p="lg">
          <Select
            label="Stores"
            onChange={(e) => handleStoreChange(e.value)}
            options={convertStoreToOptions(stores)}
            value={{
              value: activeStore,
              label: storeNameByID(activeStore),
            }}
          />
        </Box>
        <Flex flexDirection="column" height="full">
          {stores.map((store) => {
            const isSelected = activeStore === store.id;

            return (
              <Text
                key={store.id}
                onClick={() => {
                  handleStoreChange(store.id);
                  handleNext();
                }}
                color={isSelected ? 'primaryText' : 'dark'}
                p="lg"
                as="p"
                sx={{
                  cursor: 'pointer',
                  backgroundColor: isSelected ? 'primary' : 'baseLight2',
                  ...(!isSelected && {
                    ':hover': {
                      backgroundColor: 'baseLight',
                    },
                  }),
                }}
              >
                {store.name}
              </Text>
            );
          })}
        </Flex>
      </Scrollable>
      <Tab
        tabs={tabs}
        height="full"
        display={isResponsive && activePanel !== Panel.Tab && 'none !important'}
        preAction={
          isResponsive && (
            <ButtonIcon
              icon="arrow-left"
              mr="auto"
              onClick={handleBack}
              title="Back to Messages"
              variant="secondary"
            />
          )
        }
        postAction={
          <Flex
            sx={{
              '& > *': { flex: '0' },
            }}
          >
            <SettingsThemeSwitch mr="md" minWidth="auto" />
            <Button
              onClick={handleLogoutClick}
              size="xs"
              text="Logout"
              variant="danger"
            />
          </Flex>
        }
      />
    </Flex>
  );
};

const SettingsTabContainer = (props) => {
  const { checkResponsive } = useWindowSpec();
  const isResponsive = checkResponsive('lg');

  return <Box {...props} width={isResponsive ? 'full' : '50%'} />;
};
