import { gql } from '@apollo/client';

export const DRILLDOWN = gql`
  query DRILLDOWN(
    $country: CountryEnum = UNITED_STATES
    $make: String
    $year: String
  ) {
    drilldown(country: $country, make: $make, year: $year) {
      class_list {
        year_list {
          make_list {
            name
            model_list {
              name
              series_list {
                name
                style_list {
                  name
                  uvc
                }
              }
            }
          }
        }
      }
    }
  }
`;
