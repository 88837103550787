import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';

import MainLayoutHeader from './MainLayoutHeader';

const MainLayout = ({ children, ...props }) => (
  <Flex flexDirection="column" height="100vh">
    <MainLayoutHeader {...props} />
    <Box
      sx={{
        flex: '1',
        minHeight: 0,
      }}
    >
      {children}
    </Box>
  </Flex>
);

MainLayout.propTypes = {
  ...MainLayoutHeader.propTypes,
  children: PropTypes.node,
};

export default MainLayout;
