import React from 'react';

import { Flex, Text, TextLabel, H2 } from 'ui';

const LookupDetail = ({ isAll, onClick, vehicle }) => {
  const {
    contact_id,
    contact_name,
    description,
    email,
    km,
    make,
    mi,
    mobile,
    model,
    notes,
    store_name,
    updated_at,
    vehicle_id,
    vin,
    year,
  } = vehicle ?? {};

  return (
    <Flex
      flexDirection="column"
      key={`${contact_id}-${vehicle_id}`}
      onKeyDown={(e) => e.key === 'Enter' && onClick(e)}
      onClick={onClick}
      p="lg"
      role="button"
      tabIndex="0"
      verticalGap={isAll ? 'sm' : 'xs'}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'baseLight2',
        },
        '&:focus-visible': {
          outline: 'none',
          backgroundColor: 'baseLight',
        },
      }}
    >
      <H2 mb={isAll}>
        {year} {make} {model}
      </H2>
      <TextLabel label="Contact" text={`${mobile} ${contact_name}`} />
      <TextLabel label="Mileage (km)" text={Number(km).toLocaleString()} />
      <TextLabel label="Mileage (mi)" text={Number(mi).toLocaleString()} />
      <TextLabel label="Store" text={store_name} />
      {isAll && (
        <>
          <TextLabel
            label="Last Updated"
            text={new Date(updated_at).toLocaleString()}
          />
          <TextLabel label="Vehicle ID" text={vehicle_id} />
          <TextLabel label="Email" text={email} />
          <TextLabel label="Notes" text={notes} />
          <TextLabel label="VIN" text={vin} upperCase />
          <Text weight="semiBold">Description:</Text>
          <Text as="p" sx={{ whiteSpace: 'pre-wrap' }}>
            {description?.trim()}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default LookupDetail;
