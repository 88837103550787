import React from 'react';
import styled from '@emotion/styled';

import { Loader, CardVehicleBox } from 'ui';

const ContactsLoader = ({ isLoading, isClean }) => {
  if (!isLoading) {
    return null;
  }

  return isClean ? (
    new Array(10).fill(0).map((_, index) => (
      <CardVehicleBoxStyled
        key={index}
        sx={{
          ':hover': 'none',
          backgroundColor: 'base',
          borderColor: 'base',
          cursor: 'default',
        }}
      />
    ))
  ) : (
    <Loader isFullHeight={false} />
  );
};

export default ContactsLoader;

const CardVehicleBoxStyled = styled(CardVehicleBox)`
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;
