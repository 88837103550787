import React from 'react';
import PropTypes from 'prop-types';
import { Text as TextR } from 'rebass';

export const TextColor = {
  Dark: 'dark',
  Error: 'error',
  ErrorText: 'errorText',
  Gray: 'gray',
  ImportantText: 'importantText',
  Light: 'light',
  PendingText: 'pendingText',
  Primary: 'primary',
  PrimaryText: 'primaryText',
  SuccessText: 'successText',
};

const TextWeight = {
  Bold: 'bold',
  SemiBold: 'semiBold',
  Normal: 'normal',
};

const TextSize = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
};

const Text = ({
  as = 'span',
  color = TextColor.Dark,
  isBlock = false,
  isOverflow = false,
  isUppercase = false,
  size = TextSize.MD,
  sx,
  textAlign,
  weight = TextWeight.Normal,
  children,
  ...props
}) => (
  <TextR
    {...props}
    {...(isOverflow && { title: children })}
    as={as}
    sx={{
      ...sx,
      ...TextStyleBase,
      ...(textAlign && { textAlign }),
      ...(isBlock && { display: 'block' }),
      ...(isUppercase && { textTransform: 'uppercase' }),
      ...(isOverflow && {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
      fontSize: size,
      fontWeight: weight,
      color: setTextColor(color),
    }}
  >
    {children}
  </TextR>
);

Text.propTypes = {
  as: PropTypes.string,
  color: PropTypes.oneOf(Object.values(TextColor)),
  size: PropTypes.oneOf(Object.values(TextSize)),
  sx: PropTypes.object,
  weight: PropTypes.oneOf(Object.values(TextWeight)),
};

export default Text;

const TextStyleBase = {
  fontFamily: 'primary',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  lineHeight: 'normal',
};

const setTextColor = (color) => {
  switch (color) {
    case TextColor.Dark:
      return 'black';
    case TextColor.Light:
      return 'white';
    case TextColor.PrimaryText:
      return 'primaryText';
    case TextColor.SuccessText:
      return 'successText';
    case TextColor.PendingText:
      return 'pendingText';
    case TextColor.ErrorText:
      return 'errorText';
    case TextColor.Gray:
      return 'baseDark2';
    case TextColor.Primary:
      return 'primary';
    case TextColor.Error:
      return 'error';
    case TextColor.ImportantText:
      return 'importantText';
    default:
      console.error(`Unknown text color: ${color}`);
      return '';
  }
};
