import { gql } from '@apollo/client';

export const NEW_MESSAGE = gql`
  subscription NewMessage($contact_id: Int!) {
    # messages(where: { vehicle_id: { _eq: $vehicle_id }}, order_by: { created_at: desc }) {
    messages(
      where: { contact_id: { _eq: $contact_id } }
      order_by: { dispatch_at: desc_nulls_last }
      limit: 1
    ) {
      id
      contact {
        id
        name
      }
      # vehicle {
      #   id
      # }
      id
      content
      created_at
      delivered
      direction
      vehicle_id
      sent
      seen
      type
      dispatch_at
    }
  }
`;
