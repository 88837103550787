import { questionsRaw } from 'api/questions';

const DisclosureQuestionType = {
  Boolean: 'boolean',
  Checkbox: 'checkbox',
  Int: 'int',
  Radio: 'radio',
  String: 'string',
};

const DisclosureBooleanType = {
  True: 'Yes',
  False: 'No',
};

export const getAnswers = (answersData) => {
  const answers = [];
  Object.keys(questionsRaw).forEach((key) => {
    let answer = answersData[key];
    const { id: questionId, type: questionType } = questionsRaw[key];

    const hasValidAnswer =
      (!Array.isArray(answer) || answer?.length !== 0) &&
      typeof answer !== 'undefined' &&
      answer !== null;

    if (!hasValidAnswer) {
      // Set blank for optional answers - string, radio and checkboxes
      answer =
        questionType === DisclosureQuestionType.String ||
        questionType === DisclosureQuestionType.Int
          ? ''
          : [''];
    } else if (questionType === DisclosureQuestionType.Radio) {
      // API requires radio button value to be an array
      answer = [answer];
    }

    answers.push({
      answer,
      question: { id: questionId, name: key },
    });
  });

  return answers;
};

export const getDisclosureList = (questions = [], answers = {}) =>
  questions
    .map((q) => {
      if (shouldSkipQuestion(q)) {
        return null;
      }

      // Force capitalize each word
      let { label } = q;
      if (label) {
        label = label
          .split(' ')
          .map((w) => {
            try {
              return w[0].toUpperCase() + w.substring(1);
            } catch (err) {
              return w;
            }
          })
          .filter(Boolean)
          .join(' ')
          .trim();
      }

      let answer = answers[q.key] ?? '';
      if (Array.isArray(answer)) {
        answer = answer.join(', ');
      } else if (typeof answer === 'boolean') {
        answer = answer
          ? DisclosureBooleanType.True
          : DisclosureBooleanType.False;
      }

      answer = answer.trim();

      // Skip if no answer and is a sub question
      if (!answer && q.meta?.parentKey) {
        return null;
      }

      if (!label) {
        answer = `${answer}`;
      } else {
        // Set N/A to empty main questions
        answer = answer || '-';
      }

      return { label, answer };
    })
    .filter(Boolean);

/** Skip string and int types like vin, make and model. These are natively asked in app. */
const shouldSkipQuestion = ({ type, key } = {}) =>
  [DisclosureQuestionType.String, DisclosureQuestionType.Int].includes(type) ||
  key === 'color';
