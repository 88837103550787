import { gql } from '@apollo/client';
import { vehicleInfoSmallFragment } from '../queries/Vehicles';

// This should be only for inbound but can't figure it out now
export const MESSAGE_FROM_CONTACT = gql`
  subscription MessageFromContact {
    # messages(order_by: { dispatch_at: desc_nulls_last }, limit: 1, where: {sent: {_eq: true}, direction: {_eq: "inbound"}}) {
    vehicles(
      order_by: { message_last: { dispatch_at: desc_nulls_last } }
      limit: 1
      where: { message_last: { sent: { _eq: true } } }
    ) {
      ...VehicleInfoSmall
    }
  }
  ${vehicleInfoSmallFragment}
`;
