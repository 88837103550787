import React, { useState, useRef } from 'react';
import { Box, Text, Flex } from 'rebass';

import { useOnClickOutside } from 'lib';

const Placement = {
  Left: 'left',
  Right: 'right',
};

const Popover = ({
  children,
  header,
  placement = Placement.Left,
  triggerComponent,
}) => {
  const [show, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  useOnClickOutside(popoverRef, () => setShowPopover(false));

  return (
    <Box ref={popoverRef} sx={{ position: 'relative' }}>
      <Box onClick={() => setShowPopover(!show)}>{triggerComponent}</Box>
      {show && (
        <Flex
          onClick={() => setShowPopover(false)}
          flexDirection="column"
          sx={{
            backgroundColor: 'white',
            borderRadius: 'md',
            boxShadow: 'md',
            position: 'absolute',
            top: '100%',
            zIndex: 100,
            ...(placement === Placement.Left ? { left: 0 } : { right: 0 }),
          }}
        >
          <Text alignSelf="center" color="primary" fontSize="22px">
            {header}
          </Text>
          {children}
        </Flex>
      )}
    </Box>
  );
};

export default Popover;
