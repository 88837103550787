import React from 'react';

import { Flex, Icon, Text } from 'ui';

const ErrorPlaceholder = ({
  headline = 'Something went wrong',
  message = 'Please reload the page',
}) => (
  <Flex alignItems="center" justifyContent="center" height="full">
    <Flex flexDirection="column" alignItems="center" padding="md">
      <Icon color="error" size="lg" icon="error" />
      <Text size="md" weight="semiBold">
        {headline}
      </Text>
      <Text size="sm" textAlign="center">
        {message}
      </Text>
    </Flex>
  </Flex>
);

export default ErrorPlaceholder;
