export const displayCurrency = (value) => {
  const amount = value || 0;
  return `$${Number(amount).toLocaleString()}`;
};

/** Convert snake case text to a user friendly one */
export const normalizeSnakeCase = (text = '') =>
  text
    .split('_')
    .map((str) => str.slice(0, 1).toUpperCase() + str.slice(1, str.length))
    .join(' ');

/** Capitalize first letter of a word */
export const upperFirst = (string = '') => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};
