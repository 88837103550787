import { gql } from '@apollo/client';

export const VehicleFindAllBySearch = gql`
  query VehicleFindAllBySearch($search: String, $limit: Int) {
    vehicle_lookup(args: { search: $search }, limit: $limit) {
      active
      appointment
      appointment_cancelled
      appointment_full
      archived
      contact_handle
      contact_id
      contact_name
      contacted
      description
      dismissed
      disqualified
      dormant
      email
      follow_up
      human_appraised
      incomplete
      km
      machine_appraised
      make
      mi
      mobile
      model
      notes
      purchased
      qualified
      sent_to_crm
      store_name
      unavailable
      updated_at
      vehicle_id
      vehicleid
      vin
      year
    }
  }
`;
