import React from 'react';

import Text from './Text';

const Status = ({ text, bg = 'black', color = 'light', sx, ...props }) => (
  <Text
    {...props}
    sx={{
      ...sx,
      borderRadius: 'md',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
    p="xs"
    size="xs"
    bg={bg}
    color={color}
  >
    {text}
  </Text>
);

export default Status;
