import { gql } from '@apollo/client';

const vehicleAppraisalsFragment = gql`
  fragment VehicleAppraisals on vehicles {
    appraisals(order_by: { created_at: desc }) {
      id
      mode
      offer
      offer_floor
      offer_top
      offer_bb
      offer_acv
      offer_range_acv
      offer_displayed
      created_at
      sourced_by
      disclosures {
        color
        zip_code
        odometer
        accident
        trade_in
        condition
        disclosure_questions
        disclosure_answers
        disclosure_response
      }
    }
  }
`;

// This can use some work
// - Fragments should be used properly - right now it's just one big fragment
// even thought I don't need all this stuff every time I ask for it
export const vehicleInfoSmallFragment = gql`
  fragment VehicleInfoSmall on vehicles {
    id
    store_id
    contact_id
    km
    mi
    make
    trim
    source_link
    source_platform
    model
    posted_at
    year
    asking_price
    tags
    vin
    contact_handle
    store {
      id
      name
      country
    }
    contact {
      id
      name
      first_name
      last_name
      mobile
    }
    status {
      dismissed
    }
    adjusted_whole_avg
    appraised_price
    last_message_at
    message_last {
      id
      created_at
      vehicle_id
      dispatch_at
      direction
      type
      channel
      seen
    }
    owner_id
    created_at
  }
`;

export const vehicleInfoFragment = gql`
  fragment VehicleInfo on vehicles {
    id
    store_id
    appraiser_id
    contact_id
    km
    extra
    make
    source_link
    model
    offer_range
    posted_at
    year
    asking_price
    tags
    title
    trim
    style
    mi
    source_platform
    appointment_date
    appraised_price
    bb_uvc
    contact_handle
    adjusted_whole_avg
    adjusted_whole_clean
    adjusted_whole_xclean
    adjusted_whole_rough
    description
    vin
    vehicle_images {
      link
    }
    status {
      dismissed
      active
      follow_up
      unavailable
      dormant
      appointment
      disqualified
      sent_to_crm
    }
    messages(order_by: { dispatch_at: desc }, limit: 1) {
      created_at
      direction
      type
      channel
      seen
    }
    contact {
      id
      name
      mobile
      email
    }
    store {
      id
      name
      country
      store_communication {
        store_crm_email
      }
      tags
    }
    appraiser_id
    offer
    offer_range
    uuid
    owner_id
    created_at
  }
`;

// This populates the Contacts component (the left sidebar chat card part of the UI)
export const GET_VEHICLES = gql`
  query GetVehicles(
    $where: vehicles_bool_exp = {}
    $offset: Int!
    $limit: Int!
    $order_by: [vehicles_order_by!]!
  ) {
    vehicles(
      where: $where
      # order_by: { messages_aggregate: { max: { created_at: desc_nulls_last } } }
      order_by: $order_by
      offset: $offset
      limit: $limit
    ) {
      owner_id
      ...VehicleInfoSmall
    }
  }
  ${vehicleInfoSmallFragment}
`;

// This is search in the Contacts component
export const SEARCH_VEHICLES = gql`
  query SearchVehicles(
    $offset: Int!
    $limit: Int!
    $where: vehicles_bool_exp = {}
    $query: String!
  ) {
    search_vehicles(
      args: { search: $query }
      where: $where
      limit: $limit
      # order_by: { last: { max: { created_at: desc_nulls_last } } }
      order_by: { message_last: { dispatch_at: desc_nulls_last } }
      offset: $offset
    ) {
      owner_id
      ...VehicleInfoSmall
    }
  }
  ${vehicleInfoSmallFragment}
`;

// ContactVehicles component
export const GET_CONTACT_VEHICLES = gql`
  query ContactVehicles($where: vehicles_bool_exp = {}) {
    vehicles(where: $where, order_by: { created_at: desc }) {
      notes
      ...VehicleInfo
      ...VehicleAppraisals
    }
  }
  ${vehicleInfoFragment}
  ${vehicleAppraisalsFragment}
`;

// Vehicle details component
export const GET_VEHICLE_DETAILS = gql`
  query VehicleDetail($id: Int!) {
    vehicles_by_pk(id: $id) {
      id
      model
      year
      make
      style
      trim
      km
      active
      mi
      source_link
      title
      notes
      description
      posted_at
      asking_price
      store_id
      appointment_date
      contact_handle
      store {
        id
        country
        name
        store_communication {
          store_crm_email
        }
      }
      vin
      status {
        dismissed
        unavailable
        active
        follow_up
        dormant
        appointment
        sent_to_crm
      }
      contact_id
      adjusted_whole_avg
      adjusted_whole_clean
      adjusted_whole_xclean
      adjusted_whole_rough
      vehicle_images {
        link
      }
      contact {
        id
        name
        mobile
        email
        vehicles {
          title
        }
      }
      owner_id
      created_at
      ...VehicleAppraisals
    }
  }
  ${vehicleAppraisalsFragment}
`;

// This we don't really need
export const GET_VEHICLE_IMAGE = gql`
  query GetVehicleImage($id: Int!) {
    vehicle_images_by_pk(id: $id) {
      link
    }
  }
`;
