import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';

const ButtonTextSize = {
  SM: 'sm',
  MD: 'md',
};

const ButtonText = ({
  isActive,
  text,
  size = ButtonTextSize.MD,
  sx,
  ...props
}) => (
  <Button
    {...props}
    sx={{
      ...sx,
      py: 'xs',
      px: 'md',
      border: '0',
      borderRadius: 'md',
      minWidth: 'unset',
      fontSize: size,
      fontWeight: 'normal',
      color: 'black',
      letterSpacing: 'normal',
      lineHeight: 'normal',
      height: 'unset',
      background: 'none',
      textTransform: 'none',
      whiteSpace: 'nowrap',
      ':hover': {
        color: 'primary',
        background: 'none',
      },
      ...(isActive && {
        color: 'primary',
      }),
    }}
  >
    {text}
  </Button>
);

ButtonText.propTypes = {
  text: PropTypes.string,
};

export default ButtonText;
