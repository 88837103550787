import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { find, propEq } from 'ramda';

import VehicleActionsAssignUserModal from './VehicleActionsAssignUserModal';
import {
  ButtonText,
  ButtonIcon,
  Flex,
  Modal,
  Popover,
  Select,
  Text,
  HR,
} from 'ui';

const VehicleActions = ({
  handleMoveTo,
  hasAssignUser = false,
  onScheduleClick,
  vehicles = [],
}) => {
  const [showMoveTo, setShowMoveTo] = useState(false);
  const [showMoveToStore, setShowMoveToStore] = useState(false);
  const [showAssignUser, setShowAssignUser] = useState(false);

  const moveTo = (to) => {
    handleMoveTo(to);
    setShowMoveTo(false);
  };

  const { data: stores } = useQuery(gql`
    {
      stores {
        id
        name
        area {
          id
        }
      }
    }
  `);

  const [moveToStoreMutation] = useMutation(gql`
    mutation MoveToStore($store_id: Int!, $vehicle_id: Int!, $area_id: Int!) {
      update_vehicles_by_pk(
        pk_columns: { id: $vehicle_id }
        _set: { area_id: $area_id, store_id: $store_id }
      ) {
        id
        store_id
        area_id
      }
    }
  `);

  const findStoreByID = (id, s) => {
    return find(propEq('id', id))(s.stores);
  };

  const moveToStore = (val) => {
    const store = findStoreByID(val, stores);

    vehicles.forEach(({ id }) => {
      moveToStoreMutation({
        variables: {
          vehicle_id: id,
          area_id: store.area.id,
          store_id: store.id,
        },
      });
    });
  };

  const convertStoreToOptions = (data) => {
    return data?.stores.map((x) => {
      return { label: x.name, value: x.id };
    });
  };

  const singleVehicle = vehicles[0] ?? {};

  return (
    <>
      <Popover
        placement="right"
        triggerComponent={
          <ButtonIcon
            icon="menu"
            onClick={() => setShowMoveToStore(false)}
            title="Actions"
            variant="secondary"
          />
        }
        toggle={showMoveTo}
      >
        <Flex alignItems="flex-start" flexDirection="column" p="lg">
          {hasAssignUser && (
            <ButtonText
              text="Assign to User"
              onClick={() => {
                setShowMoveTo(false);
                setShowAssignUser(true);
              }}
            />
          )}
          <ButtonText
            onClick={() => {
              setShowMoveTo(false);
              setShowMoveToStore(true);
            }}
            mt="xs"
            isDisabled={!stores}
            text="Move to Store"
          />
          {onScheduleClick && (
            <ButtonText onClick={onScheduleClick} mt="xs" text="Schedule" />
          )}
          {handleMoveTo && (
            <>
              <HR mt="md" />
              <Text mt="md" mb="md" size="sm" weight="semiBold">
                Change Status
              </Text>
              <ButtonText
                mb="xs"
                onClick={() => moveTo('active')}
                variant="secondary"
                text="Active"
              />
              <ButtonText
                mb="xs"
                onClick={() => moveTo('follow_up')}
                variant="secondary"
                text="Follow up"
              />
              <ButtonText
                mb="xs"
                onClick={() => moveTo('appointment')}
                variant="secondary"
                text="Appointment"
              />
              <ButtonText
                mb="xs"
                onClick={() => moveTo('unavailable')}
                variant="secondary"
                text="Unavailable"
              />
              <ButtonText
                mb="xs"
                onClick={() => moveTo('disqualified')}
                variant="secondary"
                text="Disqualified"
              />
              <ButtonText
                onClick={() => moveTo('dismissed')}
                variant="secondary"
                text="Dismissed"
              />
            </>
          )}
        </Flex>
      </Popover>
      <Modal
        cancel="Close"
        header="Move to Store"
        isOpen={showMoveToStore}
        onClose={() => setShowMoveToStore(false)}
      >
        <Select
          label="Store"
          defaultValue={{
            label: singleVehicle.storeName,
            value: singleVehicle.storeId,
          }}
          onChange={(e) => moveToStore(e.value)}
          options={convertStoreToOptions(stores)}
        />
      </Modal>
      {hasAssignUser && (
        <VehicleActionsAssignUserModal
          isOpen={showAssignUser}
          onClose={() => setShowAssignUser(false)}
          ownerId={singleVehicle.ownerId}
          storeId={singleVehicle.storeId}
          vehicleId={singleVehicle.id}
        />
      )}
    </>
  );
};

export default VehicleActions;
