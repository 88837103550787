import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import { GET_VEHICLE_DETAILS } from 'graphql/queries/Vehicles';

import { Flex, Box, Text, Image, Checkbox, Label } from 'ui';
import { displayCurrency } from 'lib';

export const AppraisalVehicle = () => {
  const history = useHistory();
  const { vehicle_id } = useParams();

  const mockVehicle = {
    make: 'BMW',
    model: '3-series',
    year: '2010',
    vin: 'WBAJA7C59KWW17833',
    asking_price: '36700',
    mi: 21002,
    extra_clean: 39800,
    clean: 35000,
    average: 36200,
    rough: 33400,
    med_mi: 6555,
    med_price: 51765,
    market_day_supply: 65,
    days_to_turn: 56,
  };

  const { data } = useQuery(GET_VEHICLE_DETAILS, {
    variables: {
      id: Number(vehicle_id),
    },
  });

  const temp = data && data.vehicles_by_pk ? data.vehicles_by_pk : {};

  const {
    make = mockVehicle.make,
    model = mockVehicle.model,
    year = mockVehicle.year,
    vin = mockVehicle.vin,
    asking_price = mockVehicle.asking_price,
    vehicle_images = mockVehicle.vehicle_images,
    mi = mockVehicle.mi,
  } = temp;

  return (
    <Box p={2} variant="header">
      <Image src={logo} width={160} onClick={() => history.goBack()} />
      <Flex maxWidth="1024px" p={2} mx="auto" flexDirection="column">
        <Image
          width="300px"
          src={vehicle_images && vehicle_images[0] && vehicle_images[0].link}
        />
        <Text as="h2">
          {year} {make} {model}
        </Text>
        <Box p={10}>
          <Text as="h3" my={2}>
            {vin}
          </Text>
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: '25px',
              '& > :nth-of-type(n)': {
                width: '400px',
              },
            }}
          >
            <Box color="gray">
              <Box>Buy price: ${asking_price}</Box>
              <Box>Retail profit %: 29.1</Box>
              <Box>Retail Spread: {displayCurrency('15063')}</Box>
            </Box>
            <Box color="gray">
              <Box>Mileage: {mi}</Box>
              <Box>Wholesale profit %: </Box>
              <Box>Wholesale Spread: -{displayCurrency(asking_price)}</Box>
            </Box>
          </Flex>
          <Flex
            sx={{
              '& >:nth-of-type(n):not(:last-child)': {
                marginRight: '40px',
              },
            }}
          >
            <Box>
              <Flex flexDirection="column">
                <Text color="gray" mb={2}>
                  Wholesale values
                </Text>
                <Box as="table" variant="appraisal_table">
                  <Box as="tr">
                    <Box as="td">Extra clean</Box>
                    <Box as="td">${temp.adjusted_whole_xclean}</Box>
                  </Box>
                  <Box as="tr">
                    <Box as="td">Clean</Box>
                    <Box as="td">${temp.adjusted_whole_clean}</Box>
                  </Box>
                  <Box as="tr">
                    <Box as="td">Average</Box>
                    <Box as="td">${temp.adjusted_whole_avg}</Box>
                  </Box>
                  <Box as="tr">
                    <Box as="td">Rough</Box>
                    <Box as="td">${temp.adjusted_whole_rough}</Box>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex flexDirection="column">
                <Text color="gray" mb={2}>
                  Retail statistics
                </Text>
                {/* <Box as='table' variant='appraisal_table'>
								<Box as='tr'>
									<Box as='td'>Median mileage</Box>
									<Box as='td'>{med_mi}</Box>
								</Box>
								<Box as='tr'>
									<Box as='td'>Median price</Box>
									<Box as='td'>${med_price}</Box>
								</Box>
								<Box as='tr'>
									<Box as='td'>Market day supply</Box>
									<Box as='td'>{market_day_supply}</Box>
								</Box>
								<Box as='tr'>
									<Box as='td'>Days to turn</Box>
									<Box as='td'>{days_to_turn}</Box>
								</Box>
							</Box> */}
              </Flex>
            </Box>
          </Flex>
          <Box mt={3}>
            <Text as="h3" my={2}>
              Options:
            </Text>
            <Flex
              sx={{
                color: 'gray',
                '& > :nth-of-type(n):not(:last-child)': {
                  marginRight: '80px',
                },
              }}
            >
              <Box
                sx={{
                  width: '350px',
                  maxWidth: '400px',
                  '& > :nth-of-type(n)': {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <Flex>
                  <Label>
                    <Checkbox />
                    Cooled seats
                  </Label>
                  $500
                </Flex>
                <Flex>
                  <Label>
                    <Checkbox />
                    Driver assist plus
                  </Label>
                  $850
                </Flex>
                <Flex>
                  <Label>
                    <Checkbox />M Sport Package
                  </Label>
                  $2400
                </Flex>
                <Flex>
                  <Label>
                    <Checkbox />
                    w/o Premium Package
                  </Label>
                  $-900
                </Flex>
              </Box>
              <Box
                maxWidth="400px"
                sx={{
                  width: '350px',
                  '& > :nth-of-type(n)': {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <Flex>
                  <Label>
                    <Checkbox />
                    Executive Package
                  </Label>
                  $700
                </Flex>
                <Flex>
                  <Label>
                    <Checkbox />
                    Rear Seat Ent Sys
                  </Label>
                  $900
                </Flex>
                <Flex>
                  <Label>
                    <Checkbox />
                    w/o Heated Seats
                  </Label>
                  $-500
                </Flex>
                <Flex>
                  <Label>
                    <Checkbox />
                    w/o Leather
                  </Label>
                  $-1600
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
