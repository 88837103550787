import { useEffect, useReducer, useRef } from 'react';
import axios from 'axios';

const DefaultState = {
  data: null,
  error: null,
  isLoading: false,
};

const Action = {
  Start: 'loading',
  Success: 'fetched',
  Failure: 'error',
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case Action.Start:
      return {
        ...DefaultState,
        isLoading: true,
      };
    case Action.Success:
      return {
        ...DefaultState,
        data: action.payload,
        isLoading: false,
      };
    case Action.Failure:
      return {
        ...DefaultState,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

/** Perform standard http requests */
export const useFetch = () => {
  const [state, dispatch] = useReducer(fetchReducer, DefaultState);

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef(false);

  useEffect(() => {
    // Use the cleanup function for avoiding a possibly state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
  }, []);

  const fetchData = async (config) => {
    cancelRequest.current = false;
    dispatch({ type: Action.Start });

    try {
      const response = await axios(config);
      if (cancelRequest.current) {
        return;
      }

      dispatch({ type: Action.Success, payload: response.data });
      if (config.onCompleted) {
        config.onCompleted({ data: response.data });
      }
    } catch (error) {
      if (cancelRequest.current) {
        return;
      }

      dispatch({ type: Action.Failure, payload: error });
      if (config.onError) {
        config.onError(error);
      }
    }
  };

  return [fetchData, state];
};
