import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button, H1, Input, MainLayout } from 'ui';

export const SellerDocs = () => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    async function sendData(url) {
      const formData = new FormData();

      formData.append('licence', data.licence[0]);
      formData.append('title', data.title[0]);

      setLoading(true);

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const status = await response.status;
      if (status === 200) {
        setLoading(false);
      }
    }

    sendData('http://localhost:8080/upload');
  };

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Box p="lg" sx={{ display: 'grid', gridGap: 'md' }}>
          <H1>Upload Images</H1>
          <Input
            label="Upload Licence Image"
            ref={register}
            name="licence"
            type="file"
          />
          <Input
            label="Upload Title Image"
            ref={register}
            name="title"
            type="file"
          />
          <Box>
            <Button text="Upload" isLoading={loading} />
          </Box>
        </Box>
      </form>
    </MainLayout>
  );
};
