import React from 'react';
import ReactTimeAgo from 'react-time-ago';

import { Button, Flex, Text, Scrollable } from 'ui';

const MessagesSchedule = ({ message, deleteMessage }) => (
  <Flex
    alignItems="center"
    bg="pendingLight"
    justifyContent="space-between"
    key={message.id}
    p="lg"
    rowResponsive={{
      screen: 'xl',
      gap: 'md',
    }}
  >
    <Scrollable sx={{ maxHeight: 150 }}>
      <Text fontWeight="semiBold" mr="xs">
        {message.dispatch_at && (
          <ReactTimeAgo date={message.dispatch_at} locale="en-US" />
        )}
      </Text>
      <Text>{JSON.parse(message.content).message}</Text>
    </Scrollable>
    <Button
      onClick={() => deleteMessage(message)}
      size="xs"
      text="Cancel"
      variant="danger"
    />
  </Flex>
);

export default MessagesSchedule;
