import { useState } from 'react';

import { useEventListener } from './useEventListener';

export const useSelection = (defaultValue = []) => {
  const [selected, setSelected] = useState(defaultValue);

  const checkSelected = (id) => Boolean(selected.find((item) => item === id));
  const handleSelectClear = () => setSelected([]);

  const handleSelectChange = (_, value) => {
    const isSelected = checkSelected(value);
    if (isSelected) {
      setSelected(selected.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 27) {
      handleSelectClear();
    }
  };

  useEventListener('keyup', handleKeyUp, { isMuted: !selected.length });

  const handleShiftClick = (event, value) => {
    if (event.getModifierState('Shift')) {
      event.stopPropagation();
      event.preventDefault();

      handleSelectChange(event, value);
    } else {
      // clear selection when shift isn't used upon click
      handleSelectClear();
    }
  };

  return [
    selected,
    {
      checkSelected,
      onSelectChange: handleSelectChange,
      onSelectClear: handleSelectClear,
      onShiftClick: handleShiftClick,
    },
  ];
};
