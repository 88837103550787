import React from 'react';

import { Switch, Flex, Text } from 'ui';
import { useColor } from 'lib';
import { ColorMode } from 'theme';

const SettingsThemeSwitch = (props) => {
  const [color, setColor] = useColor();
  const isDarkMode = color === ColorMode.Dark;

  return (
    <Flex {...props} alignItems="center">
      <Switch
        name="color"
        handleToggle={() =>
          setColor(isDarkMode ? ColorMode.Light : ColorMode.Dark)
        }
        isOn={isDarkMode}
      />
      <Text ml="xs" isOverflow>
        Dark Mode
      </Text>
    </Flex>
  );
};

export default SettingsThemeSwitch;
