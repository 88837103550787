import React from 'react';
import DatePicker from 'react-datepicker';

import { Modal, Input } from 'ui';

const VehicleDetailsSetAppointmentModal = ({
  isOpen,
  onClose,
  appointmentDate,
  onChange,
}) => (
  <Modal
    cancel="Close"
    header="Set Appointment"
    isOpen={isOpen}
    onClose={onClose}
  >
    <DatePicker
      selected={appointmentDate && new Date(appointmentDate)}
      onChange={onChange}
      showTimeSelect
      timeIntervals={10}
      customInput={<Input />}
    />
  </Modal>
);

export default VehicleDetailsSetAppointmentModal;
