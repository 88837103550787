import React from 'react';
import { Box } from 'rebass';

const HR = ({ sx, ...props }) => (
  <Box
    {...props}
    sx={{
      ...sx,
      borderTopColor: 'base',
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      height: 1,
      width: 'full',
    }}
  />
);

export default HR;
