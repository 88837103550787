import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

import { ButtonText } from './button';
import Flex from './Flex';
import Scrollable from './Scrollable';
import { useRouteValue } from 'lib';

const Tab = ({ tabs = [], preAction, postAction, ...props }) => {
  const [active, setActive] = useState('');

  const handleTabClick = useRouteValue({
    name: 'tab',
    onChange: (id) => setActive(id),
  });

  const renderActiveTab = () => {
    const activeTab = tabs.find(({ id }) => id === active);
    return activeTab?.render();
  };

  return (
    <Flex {...props} flexDirection="column" width="full">
      <Flex
        bg="baseLight2"
        py="lg"
        pr="lg"
        pl="xs"
        sx={{
          borderBottomWidth: 'md',
          borderBottomStyle: 'solid',
          borderBottomColor: 'base',
          '& > *': {
            flex: '0',
          },
          overflow: 'auto',
        }}
        horizontalGap="md"
        alignItems="center"
      >
        {preAction && <Box minWidth="auto">{preAction}</Box>}
        {tabs.map(
          (tab, index) =>
            tab && (
              <ButtonText
                isActive={tab.id === active}
                key={index}
                onClick={() => handleTabClick(tab.id)}
                text={tab.label}
              />
            )
        )}
        {postAction && (
          <Box ml="auto" minWidth="auto">
            {postAction}
          </Box>
        )}
      </Flex>
      <Scrollable
        p="lg"
        minHeight="0"
        sx={{
          backgroundColor: 'white',
          flex: '1',
        }}
      >
        {renderActiveTab()}
      </Scrollable>
    </Flex>
  );
};

Tab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      render: PropTypes.func,
    })
  ),
};

export default Tab;
