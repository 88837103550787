import { gql } from '@apollo/client';

export const UPDATE_STORE_VEHICLE_PREF = gql`
  mutation AddVehiclePreference(
    $max_km: Int!
    $max_price: Int!
    $max_year: Int!
    $min_km: Int!
    $min_price: Int!
    $min_year: Int!
    $makes: jsonb
    $store_id: Int!
  ) {
    addVehiclePreference(
      max_price: $max_price
      min_price: $min_price
      max_year: $max_year
      min_year: $min_year
      max_km: $max_km
      min_km: $min_km
      makes: $makes
      store_id: $store_id
    ) {
      store {
        id
      }
    }
  }
`;

export const UPDATE_STORE_BOT_OPTION = gql`
  mutation UpdateStoreBotOption($_eq: Int!, $_set: store_bot_option_set_input) {
    update_store_bot_option(where: { store_id: { _eq: $_eq } }, _set: $_set) {
      returning {
        send_limit
        store {
          id
        }
      }
    }
  }
`;

// export const UPDATE_STORE_VEHICLE_PREF = gql`
//   mutation UpdateStoreVehiclePref(
//     $store_id: Int!
//     $_set: store_vehicle_preference_set_input
//   ) {
//     update_store_vehicle_preference_by_pk(
//       pk_columns: { store_id: $store_id }
//       _set: $_set
//     ) {
//       store_id
//       max_year
//       min_year
//       max_km
//       min_km
//       max_price
//       min_price
//       makes
//       # store {
//       #   id
//       # }
//     }
//   }
// `;
