import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';

import {
  Alert,
  Flex,
  H1,
  InputSearch,
  Loader,
  MainLayout,
  Modal,
  Scrollable,
} from 'ui';
import { useRouteValue } from 'lib';
import { Login } from 'pages/MainPage/Login';
import { VehicleFindAllBySearch } from 'lookup/graphql/Lookup.gql';
import LookupPlaceholder from 'lookup/LookupPlaceholder';
import LookupDetail from 'lookup/LookupDetail';

const Lookup = () => {
  const containerRef = useRef(null);
  const [term, setTerm] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const { data, loading, error } = useQuery(VehicleFindAllBySearch, {
    variables: {
      limit: 100,
      search: `%${term.trim().replace(/\s+/, '%')}%`,
    },
  });

  const handleTermChange = useRouteValue({
    name: 'q',
    onChange: (val) => {
      containerRef.current.scrollTop = 0;
      setTerm(val);
    },
  });

  const renderContent = () => {
    if (loading) {
      return <Loader isFullHeight />;
    }

    const vehicles = data?.vehicle_lookup;
    if (!vehicles?.length) {
      return <LookupPlaceholder hasTerm={Boolean(term)} />;
    }

    return vehicles.map((vehicle) => {
      const { contact_id, vehicle_id } = vehicle;

      return (
        <LookupDetail
          vehicle={vehicle}
          key={`${contact_id}-${vehicle_id}`}
          onClick={() => setSelectedVehicle(vehicle)}
        />
      );
    });
  };

  if (!localStorage.getItem('token')) {
    return <Login />;
  }

  return (
    <MainLayout>
      <Flex
        flexDirection="column"
        p="lg"
        verticalGap="md"
        height="full"
        sx={{ backgroundColor: 'white' }}
      >
        <H1>Search Vehicles</H1>
        <InputSearch value={term} onChange={handleTermChange} />
        <Scrollable ref={containerRef} sx={{ flex: '1', minHeight: 0 }}>
          {renderContent()}
        </Scrollable>
      </Flex>
      <Modal
        isOpen={Boolean(selectedVehicle)}
        onClose={() => setSelectedVehicle(null)}
        cancel="Close"
      >
        <LookupDetail vehicle={selectedVehicle} isAll />
      </Modal>
      <Alert message={error?.message} type="error" />
    </MainLayout>
  );
};

export default Lookup;
