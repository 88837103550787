import React from 'react';
import { dissoc, filter, keys } from 'ramda';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { VehicleDetails } from './components/VehicleDetails';
import { ErrorPlaceholder, Flex, Loader, Text } from 'ui';

const getStatus = (vehicle) => {
  let status = filter((x) => x, vehicle.status || { active: true });
  status = dissoc('__typename', status);
  return keys(status);
};

export const ContactVehicles = ({
  contactVehicles,
  error,
  isResponsive,
  loading,
  onBack,
  ...props
}) => {
  const { vehicle_id } = useParams();
  const history = useHistory();
  const { search } = useLocation();

  const selectedVehicle = contactVehicles.find(
    (x) => x.id === Number(vehicle_id)
  );

  const status = selectedVehicle && getStatus(selectedVehicle);

  if (loading) {
    return <Loader p="lg" />;
  }

  if (error) {
    return <ErrorPlaceholder />;
  }

  const handleSelectVehicle = (vehicle) => {
    const { id, contact_id } = vehicle;
    const path = `/chat/contact/${contact_id}/vehicle/${id}`;
    history.push({ pathname: path, search });
  };

  return (
    <>
      {contactVehicles.length >= 2 && (
        <Flex flexDirection="column">
          {contactVehicles.map((vehicle) => {
            return (
              <Flex
                key={vehicle.id}
                alignItems="center"
                onClick={() => handleSelectVehicle(vehicle)}
                sx={{
                  boxShadow: '5px 5px 5px 5px rgba(202,199,219,0.41)',
                  // background: isShow.id == vehicle.id && "#efefef",
                  borderRadius: '8px',
                  margin: '10px',
                  cursor: 'pointer',
                  padding: '10px',
                }}
              >
                <Text mr={10}>{vehicle.make}</Text>
                <Text mr={10}>{vehicle.model}</Text>
                <Text>{vehicle.year}</Text>
                {vehicle.status.active ? (
                  <Text
                    p={1}
                    backgroundColor="white"
                    ml="auto"
                    alignSelf="flex-end"
                    sx={{
                      borderRadius: '8px',
                    }}
                  >
                    ACTIVE
                  </Text>
                ) : null}
              </Flex>
            );
          })}
        </Flex>
      )}
      {selectedVehicle && (
        <VehicleDetails
          {...props}
          vehicle={selectedVehicle}
          status={status}
          hasResponsiveControls={isResponsive}
          onBack={onBack}
        />
      )}
    </>
  );
};
