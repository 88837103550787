import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rebass';

import { Icon } from '../icon';
import { ButtonStyle } from './Button';

const ButtonIconVariant = {
  Primary: 'primary',
  Secondary: 'secondary',
};

const ButtonIcon = forwardRef(
  (
    {
      icon,
      isDisabled,
      isLoading,
      sx,
      variant = ButtonIconVariant.Primary,
      ...props
    },
    ref
  ) => {
    const isVoid = isDisabled || isLoading;

    return (
      <Button
        {...props}
        disabled={isVoid}
        ref={ref}
        sx={{
          ...sx,
          variant: `buttonIcon.${variant}`,
        }}
      >
        {isLoading ? (
          <Icon icon="spinner" />
        ) : (
          <Icon
            icon={icon}
            color={
              variant === ButtonIconVariant.Primary ? 'primaryText' : 'dark'
            }
          />
        )}
      </Button>
    );
  }
);

ButtonIcon.propTypes = {
  icon: PropTypes.string,
};

export default ButtonIcon;

const ButtonIconBaseStyle = {
  width: 'control',
  height: 'control',
  border: '0',
  borderRadius: '50px',
  minWidth: 'unset',
  padding: 0,
};

export const ButtonIconStyle = {
  buttonIcon: {
    [ButtonIconVariant.Primary]: {
      ...ButtonStyle.buttons.primary,
      ...ButtonIconBaseStyle,
      backgroundColor: 'primary',
      '> svg': {
        color: 'primaryText',
      },
    },
    [ButtonIconVariant.Secondary]: {
      ...ButtonStyle.buttons.secondary,
      ...ButtonIconBaseStyle,
      backgroundColor: 'transparent',
      '> svg': {
        color: 'black',
      },
    },
  },
};
