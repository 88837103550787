import React, { useState, useEffect } from 'react';
import { components } from 'react-select';

import { Icon, Select, H3, Box } from 'ui';
import { useRouteValue } from 'lib';

const ContactsFilter = ({
  containerRef,
  menuTitle,
  name = '',
  options = [],
  onChange,
  value,
  ...props
}) => {
  const [menuSpec, setMenuSpec] = useState({});

  const handleFilterChange = useRouteValue({
    name: name.toLowerCase(),
    onChange,
  });

  useEffect(() => {
    const handleResize = () => {
      const spec = containerRef.current?.getBoundingClientRect() ?? {};

      setMenuSpec({
        menuLeft: spec.left,
        menuWidth: spec.width,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef]);

  const renderedValue = (options || []).find(
    (option) => `${option.value}` === `${value}`
  );

  const customSubComponents = {
    Menu: ({ children, ...menuProps }) => (
      <components.Menu {...menuProps}>
        {menuTitle && (
          <Box p="md">
            <H3>{menuTitle}</H3>
          </Box>
        )}
        <Box>{children}</Box>
      </components.Menu>
    ),
    ClearIndicator: ContactsFilterClearIndicator,
    DropdownIndicator: ({ hasValue, ...dropdownProps }) =>
      !hasValue && (
        <components.DropdownIndicator {...dropdownProps} hasValue={hasValue}>
          <Icon icon="down" color="dark" size="sm" />
        </components.DropdownIndicator>
      ),
  };

  return (
    <Select
      {...props}
      components={customSubComponents}
      name={name}
      onChange={(data) => handleFilterChange(data?.value || '')}
      options={options}
      styles={getContactsFilterStyles(menuSpec)}
      value={renderedValue}
    />
  );
};

export default ContactsFilter;

const ContactsFilterClearIndicator = (props) => {
  const {
    hasValue,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div {...restInnerProps} ref={ref}>
      <Icon color={hasValue ? 'primaryText' : 'dark'} icon="close" size="sm" />
    </div>
  );
};

const getContactsFilterStyles = ({ menuLeft, menuWidth }) => ({
  input: (s, { hasValue, selectProps: { theme } }) => ({
    ...s,
    color: hasValue ? theme.colors.primaryText : theme.colors.black,
  }),
  menuPortal: (s) => ({
    ...s,
    left: menuLeft,
    width: menuWidth,
  }),
  control: (s, { isFocused, isSelected, selectProps: { theme, value } }) => ({
    ...s,
    boxShadow: 'none',
    borderRadius: theme.radii.md,
    borderWidth: theme.borderWidths.md,
    borderStyle: 'solid',
    borderColor: theme.colors.baseLight,
    backgroundColor: theme.colors.base,
    cursor: 'pointer',
    caretColor: 'transparent',
    height: 'auto',
    minHeight: 'unset',
    ...((isFocused || isSelected) && {
      borderColor: theme.colors.primary,
      borderWidth: theme.borderWidths.md,
      outline: 'none',
      backgroundColor: theme.colors.white,
    }),
    ...(value && {
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.primary,
    }),
  }),
  singleValue: (s, { selectProps: { theme, value } }) => ({
    ...s,
    color: value ? theme.colors.primaryText : theme.colors.black,
    top: '47%',
    fontFamily: theme.fonts.primary,
    fontStyle: 'normal',
    fontWeight: theme.fontWeights.normal,
    fontSize: theme.fontSizes.sm,
    letterSpacing: 'normal',
    lineHeight: 'normal',
  }),
  indicatorsContainer: (s, { selectProps: { theme } }) => ({
    ...s,
    paddingRight: theme.space.sm,
    paddingLeft: theme.space.sm,
  }),
  dropdownIndicator: (s) => ({
    ...s,
    padding: 0,
  }),
  valueContainer: (s) => ({
    ...s,
    paddingRight: 'xs',
    paddingLeft: 'xs',
    paddingTop: 0,
    paddingBottom: 0,
  }),
  placeholder: (s, { selectProps: { theme } }) => ({
    ...s,
    color: theme.colors.black,
    fontFamily: theme.fonts.primary,
    fontStyle: 'normal',
    fontWeight: theme.fontWeights.normal,
    fontSize: theme.fontSizes.sm,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    top: '47%',
  }),
});
