import { useEffect, useState } from 'react';

export const useDebounce = (value, { onChange, delay = 500 } = {}) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (debouncedValue !== value) {
      const id = setTimeout(() => {
        setDebouncedValue(value);
        onChange?.(value);
      }, delay);

      return () => clearTimeout(id);
    }
  }, [debouncedValue, value, delay]);

  return debouncedValue;
};
