import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Button, Box, H1, Input, Select, MainLayout } from 'ui';

const CREATE_STORE = gql`
  mutation CreateStore(
    $store_name: String!
    $area_name: String!
    $twilio_phone: String!
    $country: String!
  ) {
    insert_store_communication_one(
      object: {
        twilio_phone: $twilio_phone
        store: {
          data: {
            name: $store_name
            country: $country
            store_bot_options: { data: {} }
            store_vehicle_preferences: {
              data: {
                max_year: 0
                min_year: 0
                min_km: 0
                max_km: 0
                max_price: 0
                min_price: 0
              }
            }
            user_roles: { data: { role: "admin", user_id: 10 } }
          }
        }
      }
    ) {
      store {
        id
        name
      }
    }
  }
`;

export const CreateStore = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (e) => {
    createStore({ variables: e });
  };

  const [createStore] = useMutation(CREATE_STORE);

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p="lg" sx={{ display: 'grid', gridGap: 'md' }}>
          <H1>Create Store</H1>
          <Input
            label="Store Name"
            name="store_name"
            {...register({ required: true })}
          />
          <Input
            label="Area Name"
            name="area_name"
            {...register({ required: true })}
          />
          <Input
            label="Twilio Phone"
            error={errors.twilio_phone?.message}
            type="tel"
            name="twilio_phone"
            {...register({ required: true })}
          />
          <Select
            label="Country"
            name="country"
            options={[
              { label: 'Canada', value: 'CA' },
              { label: 'United States', value: 'US' },
            ]}
            {...register({ required: true })}
          />
          <Box>
            <Button text="Create" type="submit" />
          </Box>
        </Box>
      </form>
    </MainLayout>
  );
};
