import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { getTheme } from 'theme';
import { useColor, useWindowSpec } from 'lib';
import { MainPage } from 'pages/MainPage/MainPage';
import { AppraisalVehicle } from 'pages/AppraisalVehicle/AppraisalVehicle';
import { SellerDocs } from 'pages/SellerDocs/SellerDocs';
import { SettingsContainer } from 'containers/SettingsContainer';
import { CreateStore } from 'pages/Admin/CreateStore/CreateStore';
import Lookup from 'lookup/Lookup';

const App = () => {
  const [color] = useColor();
  const { checkResponsive } = useWindowSpec();
  const theme = getTheme(color, checkResponsive('xl'));

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route
            path="/chat/contact/:contact_id/vehicle/:vehicle_id/:status?/:store?/:tag?"
            component={MainPage}
          />
          <Route path="/settings" component={SettingsContainer} />
          <Route path="/lookup" component={Lookup} />
          <Route path="/admin" component={CreateStore} />
          <Route path="/appraisal/:vehicle_id" component={AppraisalVehicle} />
          <Route path="/upload_docs" component={SellerDocs} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
