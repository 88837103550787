import React from 'react';
import { Flex } from 'rebass';

import { Icon } from './icon';

const Loader = ({ isFullHeight = true, size, ...props }) => (
  <Flex
    {...props}
    justifyContent="center"
    sx={{
      ...(isFullHeight && {
        height: 'full',
        alignItems: 'center',
      }),
    }}
  >
    <Icon size={size} icon="spinner" />
  </Flex>
);

export default Loader;
