import React, { useMemo, useState, useEffect } from 'react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { isEmpty, omit } from 'ramda';
import { useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import styled from '@emotion/styled';

import VehicleDetailsToolbar from './VehicleDetailsToolbar';
import VehicleDetailsImage from './VehicleDetailsImage';
import {
  AcvDealerId,
  CloudFunctionsUrl,
  displayCurrency,
  getAnswers,
  getDisclosureList,
  normalizeSnakeCase,
  OfferPageUrl,
  roundMiles,
  upperFirst,
  UsedCarClubUrl,
  useDebouncedState,
  useWindowSpec,
} from 'lib';
import {
  Accordion,
  Alert,
  Box,
  Flex,
  Icon,
  Select,
  Status,
  Textarea,
  TextLabel,
} from 'ui';
import { useLocalStorage, useFetch, useAssignedAgentsQuery } from 'hooks';
import { USED_VEHICLES } from 'graphql/queries/UsedVehicles';
import {
  UPDATE_APPOINTMENT_DATE,
  UPDATE_BB_DATA,
  UPDATE_STATUS,
  UPDATE_VEHICLE,
  INSERT_APPRAISAL_WITH_DISCLOSURES,
} from 'graphql/mutations/Vehicles';
import { CREATE_NEW_NOTE } from 'graphql/mutations/Notes';

const CRMPriceType = {
  Offer: 'offer',
  Appraisal: 'appraisal',
};

const CRMVehicleInterest = {
  Sell: 'sell',
  TradeIn: 'trade-in',
};

const VehicleBuyNewCarYes = 'yes';
const VehicleTradeIn = 'trade-in';

// Open notes section by default
const DefaultOpenSections = [false, false, false, true];

export const VehicleDetails = ({
  hasResponsiveControls,
  onBack,
  vehicle,
  ...props
}) => {
  const [alertDetails, setAlertDetails] = useState({});
  const [vehicleEdited, setVehicleEdited] = useState({});
  const [createNewNote] = useMutation(CREATE_NEW_NOTE);
  const [updateVehicle] = useMutation(UPDATE_VEHICLE, {
    onCompleted: () => {
      setAlertDetails({
        message: 'Vehicle updated',
        type: 'success',
      });
    },
    onError: () =>
      setAlertDetails({
        message: 'Failed to update vehicle',
        type: 'error',
      }),
  });
  // eslint-disable-next-line no-unused-vars
  const [insertVehicle] = useMutation(INSERT_APPRAISAL_WITH_DISCLOSURES, {
    refetchQueries: ['ContactVehicles'],
  });
  const [updateBBData] = useMutation(UPDATE_BB_DATA, {
    onCompleted: () => {
      handleEditedVehicle();
    },
  });
  const [updateStatus] = useMutation(UPDATE_STATUS);
  const [updateAppointmentDate] = useMutation(UPDATE_APPOINTMENT_DATE);
  const { users } = useAssignedAgentsQuery();
  const [openSections, setOpenSections] = useLocalStorage(
    'open-sections',
    DefaultOpenSections
  );

  const { checkResponsive } = useWindowSpec();
  const isResponsive = checkResponsive('xl');

  const history = useHistory();
  const handleNotesChange = (val) => {
    createNewNote({
      variables: {
        id: vehicle.id,
        notes: val,
      },
    });
  };

  const uvc = vehicle?.bb_uvc;
  const [fetchPhoto, photo] = useFetch();
  // eslint-disable-next-line no-unused-vars
  const [fetchDisclosures, offerUpdated] = useFetch();
  const [notes, setNotes] = useDebouncedState(vehicle?.notes ?? '', {
    onChange: handleNotesChange,
  });

  const [updateTags] = useMutation(UPDATE_VEHICLE_TAGS);
  const [sendToCRM, crmResult] = useLazyQuery(SEND_TO_CRM, {
    onCompleted: () =>
      setAlertDetails({ message: 'CRM email sent', type: 'success' }),
    onError: () =>
      setAlertDetails({
        message: 'Failed to send CRM email',
        type: 'error',
      }),
  });

  const [getBBData] = useLazyQuery(USED_VEHICLES, {
    onCompleted: (data) => {
      const bb = omit(['__typename'], data.usedvehicles.usedvehicles[0]);
      updateBBData({
        variables: {
          bb_data: bb,
          vehicle_id: vehicle.id,
        },
      });
    },
  });

  const [tags, setTags] = useState();

  const {
    adjusted_whole_avg,
    adjusted_whole_clean,
    adjusted_whole_rough,
    adjusted_whole_xclean,
    appraiser_id,
    appraisals,
    contact_handle,
    contact,
    created_at,
    km,
    make,
    mi,
    model,
    notes: vehicle_notes,
    offer_range,
    owner_id,
    source_link,
    source_platform,
    store,
    store_id,
    style,
    title,
    trim,
    uuid,
    vehicle_images,
    vin,
    year,
  } = vehicle;

  let priceType = CRMPriceType.Offer;

  const latestAppraisal = appraisals?.[0] || {};
  const disclosures = latestAppraisal.disclosures || {};

  const disclosureQuestions =
    (Array.isArray(disclosures?.disclosure_questions)
      ? disclosures?.disclosure_questions
      : disclosures?.disclosure_questions?.list) || [];

  const disclosureQuestionsRequest =
    disclosures?.disclosure_questions?.request?.body || {};

  const disclosureAnswers = disclosures?.disclosure_answers || {};

  let vehicleInterest =
    disclosures.trade_in === VehicleBuyNewCarYes
      ? CRMVehicleInterest.TradeIn
      : CRMVehicleInterest.Sell;

  // Adjust for vehicles added through appraisal mode
  if (appraiser_id) {
    priceType = CRMPriceType.Appraisal;
    vehicleInterest =
      disclosures.trade_in === VehicleTradeIn
        ? CRMVehicleInterest.TradeIn
        : CRMVehicleInterest.Sell;
  }

  const handleSendToCRM = () => {
    // Require setting a crm email
    if (!vehicle.store.store_communication.store_crm_email) {
      return setAlertDetails({
        message: 'Need to setup store CRM email inside Settings->CRM',
        type: 'error',
      });
    }

    const {
      appointment_date,
      contact: { mobile: contact_phone },
      id,
      created_at: responded_at,
      store: { name: store_name, country },
    } = vehicle;

    // Get the lower end for range offers
    const price = offer_range
      ? offer_range
          .split(',')
          .map((val) => Number(val) || 0)
          .filter(Boolean)[0]
      : Number(latestAppraisal.offer);

    const odometerByCountry = {
      US: { value: mi, units: 'mi' },
      CA: { value: km, units: 'km' },
    };

    const crmPayload = {
      ...(appointment_date && { appointment_at: appointment_date }),
      contact_name: contactName || '',
      contact_phone,
      country,
      exterior_color: disclosures.color,
      extra: {
        accident: disclosures.accident,
        buyNewCar: disclosures.trade_in,
        condition: disclosures.condition,
        extendedDisclosure: disclosureList,
        notes: vehicle_notes,
        zip: String(disclosures.zip_code),
      },
      id,
      make,
      model,
      postal_code: latestAppraisal.zip,
      price_type: priceType,
      price,
      responded_at,
      store_crm_email: store.store_communication.store_crm_email,
      store_name,
      title,
      trim,
      vehicle_interest: vehicleInterest,
      vin: vin && vin.toUpperCase(),
      year,
      odometer: odometerByCountry[country || 'US'].value,
      odometer_units: odometerByCountry[country || 'US'].units,
    };

    sendToCRM({ variables: crmPayload });

    updateStatus({
      variables: {
        vehicle_id: vehicle.id,
        _set: {
          sent_to_crm: true,
        },
      },
    });
  };
  const handleUpdateTags = () => {
    if (vehicle) {
      if (vehicle.tags) {
        const newTags = vehicle.tags.map((val) => ({
          label: val,
          value: val,
        }));
        setTags(newTags);
      } else {
        setTags([]);
      }
    }
  };
  const handleEditSubmit = (data) => {
    const executeUpdate = hasJustContactNameChanges(data)
      ? updateVehicleOnSubmit
      : addReviewedOfferToVehicle;

    executeUpdate(data);
  };

  const hasJustContactNameChanges = (data) =>
    data.contact_handle !== vehicle.contact_handle && !hasVehicleChanges(data);

  const hasYMMChanges = (data) =>
    data.model !== vehicle.model ||
    data.style !== vehicle.style ||
    data.trim !== vehicle.trim ||
    data.make !== vehicle.make;

  const hasVehicleChanges = (data) =>
    data.km !== vehicle.km ||
    data.mi !== vehicle.mi ||
    hasYMMChanges(data) ||
    data.vin !== vehicle.vin ||
    data.year !== vehicle.year;

  const getValidEditedValue = (data, keyEdited) =>
    data[keyEdited] && data[keyEdited] !== vehicle[keyEdited]
      ? data[keyEdited]
      : vehicle[keyEdited];

  const addReviewedOfferToVehicle = async (data) => {
    if (hasVehicleChanges(data) && !isEmpty(disclosureQuestionsRequest)) {
      getBBData({
        variables: {
          make: data.make,
          mileage: data.mi,
          model: data.model,
          style: data.style,
          trim: data.trim,
          year: data.year,
        },
      });
      const vehicleUpdated = await updateVehicleOnSubmit(data);
      const updatedData = {
        km: getValidEditedValue(data, 'km'),
        mi: getValidEditedValue(data, 'mi'),
        odometer: getValidEditedValue(data, 'mi'),
        make: getValidEditedValue(data, 'make'),
        model: getValidEditedValue(data, 'model'),
        style: getValidEditedValue(data, 'style'),
        trim: getValidEditedValue(data, 'trim'),
        vin: getValidEditedValue(data, 'vin'),
        year: getValidEditedValue(data, 'year'),
        uvc: getValidEditedValue(data, 'bb_uvc'),
      };
      setVehicleEdited(vehicleUpdated && updatedData);
    }
  };

  const updateVehicleOnSubmit = (data) => {
    return updateVehicle({
      variables: {
        bb_uvc: data.bb_uvc,
        contact_handle: data.contact_handle,
        id: vehicle.id,
        km: data.km,
        make: data.make,
        model: data.model,
        style: data.style,
        trim: data.trim,
        vin: hasYMMChanges(data) ? null : data.vin?.toUpperCase(),
        year: data.year,
      },
    });
  };

  const handleAppointmentDateChange = (date) => {
    updateAppointmentDate({
      variables: {
        appointment_date: date.toLocaleString(),
        id: vehicle.id,
      },
    });
  };

  const disclosureList = useMemo(
    () => getDisclosureList(disclosureQuestions, disclosureAnswers),
    [disclosureQuestions, disclosureAnswers]
  );

  const getSourceHref = () => {
    try {
      return new URL(source_link);
    } catch (error) {
      return '';
    }
  };

  const getPhotoUvcChanged = () => {
    fetchPhoto({
      params: { size: 'large' },
      url: `${UsedCarClubUrl}/Photos/${uvc}`,
    });
  };

  const insertVehicleOnUpdate = (
    updatedVehicle = {},
    disclosureQuestionsAppraisal
  ) => {
    const [offer_floor, offer_top] = latestAppraisal?.offer_range?.split(
      ','
    ) || [0, 0];

    insertVehicle({
      variables: {
        accident: disclosures?.accident,
        appraiser_id,
        bb_adjusted_whole_avg: adjusted_whole_avg,
        bb_adjusted_whole_clean: adjusted_whole_clean,
        bb_adjusted_whole_rough: adjusted_whole_rough,
        bb_adjusted_whole_xclean: adjusted_whole_xclean,
        condition: disclosures?.condition,
        disclosure_answers: disclosureAnswers,
        disclosure_id: latestAppraisal.disclosure_id,
        disclosure_questions:
          disclosureQuestionsAppraisal || disclosureQuestions,
        disclosure_response: updatedVehicle?.data
          ? updatedVehicle
          : disclosures?.disclosure_response,
        engine: 'acv',
        mode: 'make_model',
        offer: updatedVehicle?.data.price,
        offer_accepted: latestAppraisal.offer_accepted,
        offer_acv: latestAppraisal.offer_acv,
        offer_bb: latestAppraisal.offer_bb,
        offer_displayed: latestAppraisal.offer_displayed,
        offer_floor,
        offer_range_acv: latestAppraisal.offer_range_acv,
        offer_range_bb: latestAppraisal.offer_range_bb,
        offer_top,
        sourced_by: 'reviewed_offer',
        store_id,
        trade_in: disclosures?.trade_in,
        vehicle_id: vehicle.id,
        color: disclosures?.color,
        zip_code: disclosures?.zip_code,
        odometer: updatedVehicle?.data?.mi || vehicleEdited?.mi,
      },
    });
  };

  const handleEditedVehicle = () => {
    if (!isEmpty(vehicleEdited)) {
      const answers = getAnswers({ ...disclosureAnswers, ...vehicleEdited });

      const payloadDisclosures = {
        answers,
        partner_id: disclosureQuestionsRequest?.partner_id || AcvDealerId,
        zip: disclosureQuestionsRequest?.zip || disclosures?.zip_code,
      };

      const disclosureQuestionsAppraisal = {
        list: disclosureQuestions,
        request: {
          body: payloadDisclosures,
        },
      };

      const basedUpdatedVehicle = { data: { price: 0 } };

      fetchDisclosures({
        url: `${CloudFunctionsUrl}/VehicleDisclosure`,
        method: 'POST',
        data: JSON.stringify(payloadDisclosures),
        onCompleted: (updatedVehicle = {}) => {
          insertVehicleOnUpdate(
            { ...basedUpdatedVehicle, ...updatedVehicle },
            disclosureQuestionsAppraisal
          );
        },
        onError: (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          insertVehicleOnUpdate(
            basedUpdatedVehicle,
            disclosureQuestionsAppraisal
          );
          setAlertDetails({
            message: 'Failed to update Offer for Reviewed Offer',
            type: 'error',
          });
        },
      });
    }
  };

  const offerPageFullUrl = new URL(uuid, OfferPageUrl);
  const isLoadingPhoto = photo.isLoading;
  const status = props.status;
  const vehicleImageData = photo.data?.photo?.file_contents;
  const contactName = contact_handle ? contact_handle : contact?.name;
  const vehicleImage = vehicle_images?.[0]?.link;
  const ownerName = users.find(({ id }) => id === owner_id)?.name ?? '';
  const expirationDate = new Date(created_at);
  expirationDate.setDate(expirationDate.getDate() + 7);
  const offerDisplayed =
    typeof latestAppraisal.offer_displayed === 'boolean'
      ? upperFirst(`${latestAppraisal.offer_displayed}`)
      : '';

  const offerValue = offer_range
    ? offer_range
        .split(',')
        .map((val) => (val ? displayCurrency(val) : ''))
        .filter(Boolean)
        .join(' - ')
    : displayCurrency(latestAppraisal.offer);

  useEffect(() => {
    getPhotoUvcChanged();
  }, [uvc]);

  useEffect(() => {
    handleUpdateTags();
  }, [vehicle]);

  return (
    <Flex
      flexDirection="column"
      height={!isResponsive && 'full'}
      verticalGap="lg"
    >
      <Alert message={alertDetails.message} type={alertDetails.type} />
      <VehicleDetailsToolbar
        hasResponsiveControls={hasResponsiveControls}
        isCrmSendLoading={crmResult.loading}
        onAppointmentDateChange={handleAppointmentDateChange}
        onAppraisalClick={() => history.push(`/appraisal/${vehicle.id}`)}
        onBack={onBack}
        onCrmSendClick={handleSendToCRM}
        onEditSubmit={handleEditSubmit}
        vehicle={vehicle}
      />
      <Flex>
        <VehicleDetailsImage
          isLoadingPhoto={isLoadingPhoto}
          vehicleImage={vehicleImage}
          vehicleImageData={vehicleImageData}
        />
        <Flex flexDirection="column" ml="md">
          <TextLabel label="Year" text={year} shouldWrap isOverflow />
          <TextLabel label="Make" text={make} shouldWrap isOverflow />
          <TextLabel label="Model" text={model} shouldWrap isOverflow />
          <TextLabel label="Trim" text={trim} shouldWrap isOverflow />
          <TextLabel label="Style" text={style} shouldWrap isOverflow />
          <TextLabel label="VIN" text={vin} shouldWrap isOverflow upperCase />
          <TextLabel
            label="Odometer"
            text={roundMiles(mi)}
            shouldWrap
            isOverflow
          />
        </Flex>
      </Flex>
      <Accordion
        isMultiOpen
        openIndex={openSections}
        onItemClick={({ list }) => setOpenSections(list)}
        items={[
          {
            label: 'Lead',
            content: (
              <Flex flexDirection="column" verticalGap="2xs">
                <TextLabel label="Name" text={contactName} />
                <TextLabel
                  label="Intent"
                  text={normalizeSnakeCase(vehicleInterest)}
                />
                <TextLabel label="Postal Code" text={disclosures.zip_code} />
                <TextLabel
                  label="Posted"
                  text={new Date(created_at).toLocaleDateString()}
                />
                <TextLabel
                  label="Source"
                  content={
                    <VehicleDetailsExternalLink
                      href={getSourceHref()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {source_platform}
                      <Icon icon="external-link" color="primary" />
                    </VehicleDetailsExternalLink>
                  }
                />
                <TextLabel
                  label="Selection Mode"
                  text={normalizeSnakeCase(latestAppraisal.mode)}
                />
                <TextLabel label="Store" text={store?.name ?? ''} />
                <TextLabel label="Assigned To" text={ownerName} mb="2xs" />
                <Box>
                  <Flex
                    sx={{
                      flexFlow: 'wrap',
                      marginTop: -5,
                      marginLeft: -5,
                    }}
                  >
                    <TextLabel
                      content={status?.map((value, index) => (
                        <Status
                          {...(VehicleStatusStyle[value] || { text: value })}
                          key={index}
                          sx={{ marginTop: 5, marginLeft: 5 }}
                        />
                      ))}
                    />
                  </Flex>
                </Box>
              </Flex>
            ),
          },
          {
            label: 'Offer',
            content: (
              <Flex flexDirection="column" verticalGap="2xs">
                <TextLabel
                  label="Displayed Online Offer"
                  text={offerDisplayed}
                />
                <TextLabel
                  label="Online Offer"
                  content={
                    <VehicleDetailsExternalLink
                      href={offerPageFullUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {offerValue}
                      <Icon icon="external-link" color="primary" />
                    </VehicleDetailsExternalLink>
                  }
                />
                <TextLabel
                  label="Offer Expiration"
                  text={expirationDate.toLocaleDateString()}
                />
                <TextLabel
                  label="Wholesale Avg"
                  text={displayCurrency(adjusted_whole_avg)}
                />
                <TextLabel
                  label="Wholesale Clean"
                  text={displayCurrency(adjusted_whole_clean)}
                />
                <TextLabel
                  label="Wholesale Rough"
                  text={displayCurrency(adjusted_whole_rough)}
                />
              </Flex>
            ),
          },
          {
            label: 'Condition Disclosures',
            isHidden: !disclosureList.length,
            content: (
              <Flex flexDirection="column" verticalGap="2xs">
                {disclosureList.map(({ label, answer }, index) => (
                  <TextLabel
                    shouldWrap
                    key={index}
                    label={label}
                    text={answer}
                  />
                ))}
              </Flex>
            ),
          },
          {
            label: 'More Details',
            content: (
              <>
                <Select
                  label="Tags"
                  isMulti
                  value={tags}
                  name="Tags"
                  mb="md"
                  onChange={(e) => {
                    if (e == null) {
                      setTags(e);
                      return updateTags({
                        variables: { id: vehicle.id, tags: [] },
                      });
                    }

                    updateTags({
                      variables: {
                        id: vehicle.id,
                        tags: e.map(({ label }) => label),
                      },
                    });

                    setTags(e);
                  }}
                  options={
                    vehicle.store &&
                    vehicle.store.tags &&
                    vehicle.store.tags.map((t) => {
                      return { label: t, value: t };
                    })
                  }
                />
                <Box>
                  <Textarea
                    label="Notes"
                    mb="md"
                    onChange={(e) => setNotes(e.target.value)}
                    size="xl"
                    value={notes}
                  />
                </Box>
              </>
            ),
          },
        ]}
      />
    </Flex>
  );
};

const VehicleDetailsExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: capitalize;
  text-decoration: none;

  display: inline-flex;
  align-items: center;

  > :last-child {
    margin-left: 2px;
  }
`;

const VehicleStatusStyle = {
  dismissed: {
    bg: 'errorLight',
    color: 'errorText',
    text: 'Dismissed',
  },
  unavailable: {
    bg: 'baseDark',
    color: 'dark',
    text: 'Unavailable',
  },
  active: {
    bg: 'success',
    color: 'successText',
    text: 'Active',
  },
  follow_up: {
    bg: 'pending',
    color: 'pendingText',
    text: 'Follow Up',
  },
  dormant: {
    bg: 'baseDark2',
    color: 'dark',
    text: 'Dormat',
  },
  appointment: {
    bg: 'pendingDark',
    color: 'pendingText',
    text: 'Appointment',
  },
  sent_to_crm: {
    bg: 'successDark',
    color: 'successText',
    text: 'CRM Sent',
  },
  disqualified: {
    bg: 'error',
    color: 'errorText',
    text: 'Disqualified',
  },
};

const UPDATE_VEHICLE_TAGS = gql`
  mutation UpdateVehicleTags($id: Int!, $tags: jsonb!) {
    update_vehicles_by_pk(pk_columns: { id: $id }, _set: { tags: $tags }) {
      tags
      id
    }
  }
`;

const SEND_TO_CRM = gql`
  query SendCRMEmail(
    $appointment_at: String
    $contact_name: String! = "Unknown"
    $contact_phone: String!
    $country: String
    $description: String! = "" # deprecated
    $exterior_color: String
    $extra: jsonb!
    $from_crm_email: String! = "leads@mail1.drivably.com"
    $id: Int!
    $make: String!
    $model: String
    $odometer_units: String
    $odometer: Int
    $postal_code: String
    $price_type: String
    $price: Int!
    $responded_at: String!
    $store_crm_email: String!
    $store_name: String!
    $title: String!
    $trim: String
    $vehicle_interest: String
    $vin: String
    $year: Int!
  ) {
    sendCRMEmail(
      appointment_at: $appointment_at
      contact_name: $contact_name
      contact_phone: $contact_phone
      country: $country
      description: $description # deprecated
      exterior_color: $exterior_color
      extra: $extra
      from_crm_email: $from_crm_email
      id: $id
      make: $make
      model: $model
      odometer_units: $odometer_units
      odometer: $odometer
      postal_code: $postal_code
      price_type: $price_type
      price: $price
      responded_at: $responded_at
      store_crm_email: $store_crm_email
      store_name: $store_name
      title: $title
      trim: $trim
      vehicle_interest: $vehicle_interest
      vin: $vin
      year: $year
    ) {
      success
    }
  }
`;
