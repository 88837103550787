import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { Modal, Flex, Textarea } from 'ui';
import { SCHEDULED_MESSAGE } from 'graphql/mutations/Messages';

const MessageDatePicker = ({
  withMessage,
  type,
  make,
  contact_id,
  vehicle_id,
  store_id,
  isShowDateModal,
  setShowDateModal,
}) => {
  const template = (t, m, time) => {
    switch (t) {
      case 'Follow Up':
        return `Have you sold your ${m}?`;
      case 'Reminder':
        return 'Can I call you now?';
      case 'Appointment':
        return `Is our meeting for ${time} still on?`;
      default:
        break;
    }
  };

  const typeEnums = Object.freeze({
    follow_up: 'schedule_follow_up',
    reminder: 'schedule_reminder',
    appointment: 'schedule_appointment',
  });

  const [scheduledMessage] = useMutation(SCHEDULED_MESSAGE);
  const [startDate, setStartDate] = useState(new Date());
  const { handleSubmit, control } = useForm({
    defaultValues: {
      content: template(type, make, startDate.toLocaleTimeString().slice(0, 5)),
      time: startDate,
    },
  });

  const handleSubmitScheduledMessage = handleSubmit(({ content }) => {
    let status = {};

    switch (type) {
      case 'Follow Up':
        status = { follow_up: true };
        break;
      case 'Appointment':
        status = { appointment: true };
        break;
      default:
        console.error(`Unknown type ${type}`);
        break;
    }

    scheduledMessage({
      variables: {
        status,
        type:
          // eslint-disable-next-line no-nested-ternary
          type === 'Follow Up'
            ? typeEnums.follow_up
            : type === 'Reminder'
            ? typeEnums.reminder
            : typeEnums.appointment,
        delivered: false,
        direction: 'outbound',
        content: JSON.stringify({ message: content }),
        store_id,
        contact_id,
        vehicle_id,
        dispatch_at: startDate.toISOString(),
      },
      refetchQueries: ['Messages'],
    });

    setShowDateModal(false);
  });

  return (
    <Modal
      header="Schedule"
      isOpen={isShowDateModal}
      onClose={() => setShowDateModal(false)}
      confirm="Save"
      onConfirm={handleSubmitScheduledMessage}
    >
      <Flex
        as="form"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {withMessage && (
          <Controller
            as={<Textarea label="Your Message" marginBottom="20px" />}
            defaultValue=""
            name="content"
            control={control}
          />
        )}
        <Controller
          render={() => (
            <DatePicker
              inline
              minDate={new Date()}
              onChange={(date) => setStartDate(date)}
              selected={startDate}
              shouldCloseOnSelect={false}
              showTimeInput={false}
              showTimeSelect
              timeIntervals={10}
            />
          )}
          defaultValue={startDate}
          type="date"
          name="time"
          control={control}
        />
      </Flex>
    </Modal>
  );
};

export default MessageDatePicker;
