import React from 'react';

import { Flex, Text } from 'ui';

const LookupPlaceholder = ({ hasTerm }) => (
  <Flex justifyContent="center" alignItems="center" height="full">
    <Text color="gray">
      {hasTerm
        ? `No results found. Try changing the search term.`
        : `Start searching to see the list of vehicles.`}
    </Text>
  </Flex>
);

export default LookupPlaceholder;
