import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';

import Flex from './Flex';
import HR from './HR';
import Text from './Text';
import Icon from './icon/Icon';

const AccordionItem = ({ label, isCollapsed, onClick, children }) => {
  return (
    <>
      <Flex
        role="button"
        onClick={onClick}
        flexDirection="column"
        sx={{
          cursor: 'pointer',
        }}
      >
        <Flex mb="xs" justifyContent="space-between">
          <Text weight="bold" color="gray">
            {label}
          </Text>
          <Icon icon={isCollapsed ? 'down' : 'up'} color="gray" />
        </Flex>
        <HR />
      </Flex>
      <AccordionItemBlock isCollapsed={isCollapsed} aria-expanded={isCollapsed}>
        {children}
      </AccordionItemBlock>
    </>
  );
};

export default AccordionItem;

const AccordionItemBlock = styled(Box)`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
`;
