import React from 'react';

import { Box, Flex, Image, Text } from 'ui';

const MessageBubble = ({ id, dispatch_at, direction, type, ...message }) => {
  const content = JSON.parse(message.content);
  const media = content.media && content.media[0] ? content.media[0] : null;
  const text = content.message ? content.message : null;
  const contentType = content.content_type ? content.content_type : '';

  const isInternal = type === 'internal';
  const isOutbound = direction === 'outbound';
  const isDelivered = Boolean(message.delivered);
  const isAutomated = type !== null;
  const isMessageOutbound = type === null && !isOutbound;
  const alignStyle = isOutbound ? 'flex-end' : 'flex-start';

  if (isInternal) {
    return (
      <Flex
        alignItems="center"
        flexDirection="column"
        key={id}
        mb="md"
        minWidth="0"
        marginLeft="auto"
      >
        <Text weight="semiBold" mb="xs" sx={{ whiteSpace: 'pre-wrap' }}>
          {text}
        </Text>
        <Text color="gray" size="xs">
          {new Date(dispatch_at).toString().split(' GMT')[0]}
        </Text>
      </Flex>
    );
  }

  let bubbleStyle = {
    background: 'base',
    color: 'dark',
    textAlign: 'left',
  };

  if (isAutomated) {
    bubbleStyle = {
      background: isDelivered ? 'baseLight' : 'baseLight2',
      color: 'dark',
      textAlign: 'right',
    };
  } else if (!isMessageOutbound) {
    bubbleStyle = {
      background: isDelivered ? 'primary' : 'primaryLight',
      color: 'primaryText',
      textAlign: 'right',
      sx: {
        '::selection': {
          backgroundColor: 'primaryText',
          color: 'primary',
        },
      },
    };
  }

  return (
    <Flex
      alignItems={alignStyle}
      flexDirection="column"
      key={id}
      maxWidth="70%"
      mb="md"
      minWidth="0"
      {...(isOutbound && {
        marginLeft: 'auto',
      })}
    >
      <Box
        p="md"
        mb="xs"
        sx={{
          backgroundColor: bubbleStyle.background,
          borderRadius: 'md',
        }}
      >
        {text && (
          <Text
            color={bubbleStyle.color}
            sx={{
              whiteSpace: 'pre-wrap',
              ...bubbleStyle.sx,
            }}
          >
            {text}
          </Text>
        )}
        {media && contentType.includes('video') ? (
          <video controls width="auto" height="200px" src={media} />
        ) : (
          media && (
            <a target="_blank" href={media} rel="noreferrer">
              <Image width="auto" height="200px" src={media} />
            </a>
          )
        )}
      </Box>
      <Flex mx="md" mb="xs">
        <Text color="gray" size="xs" textAlign={bubbleStyle.textAlign} mr="xs">
          {new Date(dispatch_at).toString().split(' GMT')[0]}
        </Text>
        {!isDelivered && (
          <Text color="error" size="xs">
            Not Delivered
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default MessageBubble;
