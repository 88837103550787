import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $delivered: Boolean = false
    $direction: String = "outbound"
    $content: jsonb!
    # $channel: String!
    $contact_id: Int!
    $vehicle_id: Int!
    $store_id: Int!
    $created_at: timestamptz
  ) {
    insert_messages_one(
      object: {
        type: null
        sent: true
        seen: true
        channel: sms
        delivered: $delivered
        direction: $direction
        content: $content
        contact_id: $contact_id
        vehicle_id: $vehicle_id
        store_id: $store_id
        created_at: $created_at
      }
    ) {
      vehicle {
        id
      }
      contact {
        id
      }
      id
      vehicle_id
      contact_id
      dispatch_at
      content
    }
  }
`;

export const SCHEDULED_MESSAGE = gql`
  mutation ScheduledMessage(
    $delivered: Boolean = false
    $direction: String = "outbound"
    $content: jsonb!
    $contact_id: Int!
    $vehicle_id: Int!
    # $created_at: timestamptz
    $store_id: Int!
    $dispatch_at: timestamptz
    $type: msg_type_enum
    $status: vehicle_status_set_input = {}
  ) {
    insert_messages_one(
      object: {
        type: $type
        channel: sms
        sent: false
        seen: false
        delivered: $delivered
        direction: $direction
        store_id: $store_id
        content: $content
        contact_id: $contact_id
        vehicle_id: $vehicle_id
        # created_at: $created_at
        dispatch_at: $dispatch_at
      }
    ) {
      id
      vehicle {
        id
        status {
          follow_up
          appointment
        }
      }
      vehicle_id
      contact_id
      content
      type
    }
    update_vehicle_status(
      where: { vehicle_id: { _eq: $vehicle_id } }
      _set: $status
    ) {
      returning {
        vehicle {
          id
          status {
            appointment
            follow_up
          }
        }
      }
    }
  }
`;

export const DELETE_SCHEDULED_MESSAGE = gql`
  mutation DeleteScheduledMessage(
    $msg_id: Int!
    $vehicle_id: Int!
    $status: vehicle_status_set_input = {}
  ) {
    delete_messages_by_pk(id: $msg_id) {
      id
      contact {
        id
      }
      vehicle {
        id
      }
    }
    update_vehicle_status(
      where: { vehicle_id: { _eq: $vehicle_id } }
      _set: $status
    ) {
      returning {
        vehicle {
          id
          status {
            follow_up
            appointment
          }
        }
      }
    }
  }
`;

export const TOGGLE_SEEN = gql`
  mutation ToggleSeen(
    $vehicle_id: Int!
    $seen: Boolean = false
    $set_seen: Boolean!
  ) {
    update_messages(
      where: {
        _and: { vehicle_id: { _eq: $vehicle_id }, seen: { _eq: $seen } }
      }
      _set: { seen: $set_seen }
    ) {
      returning {
        id
        seen
        direction
        content
        vehicle {
          id
          last_message
        }
      }
    }
  }
`;
