import React, { useState } from 'react';

import VehicleDetailsSetAppointmentModal from './VehicleDetailsSetAppointmentModal';
import VehicleDetailsEditModal from './VehicleDetailsEditModal';
import { ButtonText, ButtonIcon, Flex, Popover } from 'ui';

const VehicleDetailsToolbar = ({
  hasResponsiveControls,
  isCrmSendLoading,
  onAppointmentDateChange,
  onAppraisalClick,
  onBack,
  onCrmSendClick,
  onEditSubmit,
  vehicle,
}) => {
  const [showAppointment, setShowAppointment] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <Flex justifyContent="flex-end" alignItems="center" mb="lg">
      {hasResponsiveControls && (
        <ButtonIcon
          icon="arrow-left"
          mr="auto"
          onClick={onBack}
          title="Back to Messages"
          variant="secondary"
        />
      )}
      <Popover
        placement="right"
        triggerComponent={
          <ButtonIcon icon="menu" title="Actions" variant="secondary" />
        }
      >
        <Flex alignItems="flex-start" flexDirection="column" p="lg">
          <ButtonText onClick={() => setShowEdit(true)} text="Edit" />
          <ButtonText onClick={onAppraisalClick} text="View Appraisal Data" />
          <ButtonText
            isLoading={isCrmSendLoading}
            onClick={onCrmSendClick}
            text="Send to CRM"
          />
          {vehicle?.status?.appointment && (
            <ButtonText
              onClick={() => setShowAppointment(true)}
              text="Set Appointment"
            />
          )}
        </Flex>
      </Popover>
      <VehicleDetailsSetAppointmentModal
        appointmentDate={vehicle?.appointment_date}
        isOpen={showAppointment}
        onChange={onAppointmentDateChange}
        onClose={() => setShowAppointment(false)}
      />
      <VehicleDetailsEditModal
        vehicle={vehicle}
        onSubmit={onEditSubmit}
        isOpen={showEdit}
        onClose={() => setShowEdit(false)}
      />
    </Flex>
  );
};

export default VehicleDetailsToolbar;
