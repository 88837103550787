import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ContactVehicles } from 'components';
import { GET_CONTACT_VEHICLES } from 'graphql/queries/Vehicles';

import { Scrollable } from 'ui';

export const ContactVehiclesContainer = ({
  isActive,
  isResponsive,
  onBack,
}) => {
  const [contactVehicles, setContactVehicles] = useState([]);

  const { contact_id } = useParams();
  const { data, loading, error } = useQuery(GET_CONTACT_VEHICLES, {
    variables: {
      where: {
        contact_id: { _eq: contact_id ? Number(contact_id) : 0 },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (dt) => {
      if (dt?.vehicles[0]?.appraisals.length !== 0) {
        setContactVehicles(data.vehicles);
        return;
      }

      const extraVehicle = dt?.vehicles[0];
      const extra = extraVehicle?.extra;

      const appraisal = {
        vehicle_id: extraVehicle.id,
        created_at: extraVehicle.created_at,
        updated_at: extraVehicle.updated_at,
        disclosure_id: 0,
        engine: 'none',
        mode: extra?.mode,
        offer: extraVehicle.offer,
        // offer_floor: extra
        // offer_top:
        offer_displayed: extra?.isOfferDisplayed,
        offer_bb: extra?.pricing.offerBB,
        offer_acv: extra?.pricing.offerACV,
        bb_adjusted_whole_clean:
          extra?.pricing.estimatesBB.adjusted_whole_clean,
        bb_adjusted_whole_xclean:
          extra?.pricing.estimatesBB.adjusted_whole_xclean,
        bb_adjusted_whole_rough:
          extra?.pricing.estimatesBB.adjusted_whole_rough,
        bb_adjusted_whole_avg: extra?.pricing.estimatesBB.adjusted_whole_avg,
        acv_estimate: extra?.pricing.estimatesACV,
        appraiser_id: extraVehicle.appraiser_id,
        offer_range_bb: extra?.pricing.offerRangeBB,
        offer_range_acv: extra?.pricing.offerRangeACV,
        offer_accepted: true,
        sourced_by: 'online_offer',
      };
      const disclosures = [
        {
          id: 0,
          acv_disclosure_id: 0,
          disclosure_questions: extra?.disclosure_questions,
          disclosure_answers: extra?.disclosure_answers,
          disclosure_response: extra?.apiResponses?.acvPricing,
          created_at: extraVehicle.created_at,
          updated_at: extraVehicle.updated_at,
          vehicle_id: extraVehicle.id,
          store_id: extraVehicle.store_id,
          inspection_id: 0,
          accident: extra?.accident,
          trade_in: extra?.buyNewCar,
          condition: extra?.condition,
          color: extra?.color,
          zip_code: extra?.zipCode,
          odometer: extraVehicle.mi,
        },
      ];

      const appr = {
        ...appraisal,
        disclosures,
      };
      setContactVehicles([{ ...extraVehicle, appraisals: [appr] }]);
    },
  });

  return (
    <Scrollable
      p="lg"
      isFullHeight
      bg="baseLight2"
      sx={{
        borderLeftWidth: 'md',
        borderLeftStyle: 'solid',
        borderLeftColor: 'base',
        display: isResponsive && !isActive ? 'none' : 'block',
      }}
    >
      <ContactVehicles
        contactVehicles={contactVehicles}
        error={error}
        isResponsive={isResponsive}
        loading={loading}
        onBack={onBack}
      />
    </Scrollable>
  );
};
