import { gql } from '@apollo/client';

export const GET_MESSAGES = gql`
  query Messages($contact_id: Int!) {
    messages(
      where: { contact_id: { _eq: $contact_id }, channel: { _eq: sms } }
      order_by: { dispatch_at: asc }
    ) {
      id
      contact {
        name
        mobile
      }
      content
      created_at
      delivered
      direction
      vehicle_id
      sent
      seen
      type
      dispatch_at
    }
  }
`;
