export const questionsRaw = {
  vin: {
    id: 115,
    isRequired: true,
    isUnsupported: false,
    key: 'vin',
    label: 'Vin',
    options: [],
    subQuestions: [],
    title: 'Vin',
    type: 'string',
    meta: {
      childKeys: [],
    },
  },
  uvc: {
    id: 180,
    isRequired: true,
    key: 'uvc',
    label: 'UVC Code',
    options: [],
    subQuestions: [],
    title: 'UVC Code',
    type: 'string',
    meta: {
      childKeys: [],
    },
  },
  trim: {
    id: 139,
    isRequired: true,
    isUnsupported: false,
    key: 'trim',
    label: 'Trim',
    options: [],
    subQuestions: [],
    title: 'Trim',
    type: 'string',
    meta: {
      childKeys: [],
    },
  },
  style: {
    id: 140,
    isRequired: true,
    isUnsupported: false,
    key: 'style',
    label: 'Style',
    options: [],
    subQuestions: [],
    title: 'Style',
    type: 'string',
    meta: {
      childKeys: [],
    },
  },
  engine: {
    id: 116,
    isRequired: true,
    isUnsupported: true,
    key: 'engine',
    label: 'Engine',
    options: [],
    subQuestions: [],
    title: 'Engine',
    type: 'string',
    meta: {
      childKeys: [],
    },
  },
  odometer: {
    id: 141,
    isRequired: true,
    isUnsupported: false,
    key: 'odometer',
    label: 'Miles',
    options: [],
    subQuestions: [],
    title: 'Miles',
    type: 'int',
    meta: {
      childKeys: [],
    },
  },
  color: {
    id: 117,
    isRequired: true,
    isUnsupported: false,
    key: 'color',
    label: 'Color',
    options: [
      {
        id: 67,
        label: 'Black',
        value: 'Black',
      },
      {
        id: 68,
        label: 'White',
        value: 'White',
      },
      {
        id: 69,
        label: 'Silver',
        value: 'Silver',
      },
      {
        id: 70,
        label: 'Red',
        value: 'Red',
      },
      {
        id: 71,
        label: 'Gray',
        value: 'Gray',
      },
      {
        id: 72,
        label: 'Green',
        value: 'Green',
      },
      {
        id: 73,
        label: 'Blue',
        value: 'Blue',
      },
      {
        id: 74,
        label: 'Purple',
        value: 'Purple',
      },
      {
        id: 75,
        label: 'Orange',
        value: 'Orange',
      },
      {
        id: 76,
        label: 'Beige',
        value: 'Beige',
      },
      {
        id: 77,
        label: 'Brown',
        value: 'Brown',
      },
      {
        id: 78,
        label: 'Yellow',
        value: 'Yellow',
      },
      {
        id: 79,
        label: 'Gold',
        value: 'Gold',
      },
      {
        id: 80,
        label: 'Blue (light)',
        value: 'Blue (light)',
      },
      {
        id: 81,
        label: 'Blue (dark)',
        value: 'Blue (dark)',
      },
      {
        id: 82,
        label: 'Tan',
        value: 'Tan',
      },
      {
        id: 83,
        label: 'Maroon',
        value: 'Maroon',
      },
      {
        id: 84,
        label: 'Pearl',
        value: 'Pearl',
      },
      {
        id: 85,
        label: 'Pink',
        value: 'Pink',
      },
    ],
    subQuestions: [],
    title: 'Color',
    type: 'radio',
    meta: {
      childKeys: [],
    },
  },
  option_equipment: {
    id: 118,
    isRequired: false,
    isUnsupported: false,
    key: 'option_equipment',
    label: 'Optional Equipment',
    options: [
      {
        id: 1,
        label: 'Leather',
        value: 'Leather',
      },
      {
        id: 2,
        label: 'Navigation',
        value: 'Navigation',
      },
      {
        id: 3,
        label: 'Sunroof',
        value: 'Sunroof',
      },
    ],
    subQuestions: [],
    title: 'Optional Equipment',
    type: 'checkbox',
    meta: {
      childKeys: [],
    },
  },
  title_present: {
    id: 119,
    isRequired: true,
    isUnsupported: false,
    key: 'title_present',
    label: 'Title Present',
    options: [
      {
        id: 4,
        label: 'Yes',
        value: true,
      },
      {
        id: 5,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [],
    title: 'Title Present',
    type: 'boolean',
    meta: {
      childKeys: [],
    },
  },
  title_branded: {
    id: 120,
    isRequired: true,
    isUnsupported: false,
    key: 'title_branded',
    label: 'Title Branded',
    options: [
      {
        id: 6,
        label: 'Yes',
        value: true,
      },
      {
        id: 7,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [],
    title: 'Title Branded',
    type: 'boolean',
    meta: {
      childKeys: [],
    },
  },
  frame_damage: {
    id: 121,
    isRequired: true,
    isUnsupported: false,
    key: 'frame_damage',
    label: 'Frame Damage',
    options: [
      {
        id: 8,
        label: 'Yes',
        value: true,
      },
      {
        id: 9,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [],
    title: 'Frame Damage',
    type: 'boolean',
    meta: {
      childKeys: [],
    },
  },
  glass_damage: {
    id: 181,
    isRequired: true,
    isUnsupported: false,
    key: 'glass_damage',
    label: 'Glass Damage',
    options: [
      {
        id: 175,
        label: 'Yes',
        value: true,
      },
      {
        id: 176,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [],
    title: 'Glass Damage',
    type: 'boolean',
    meta: {
      childKeys: [],
    },
  },
  rust_damage_severity: {
    id: 123,
    isRequired: true,
    isUnsupported: false,
    key: 'rust_damage_severity',
    label: '',
    options: [
      {
        id: 12,
        label: 'Major',
        value: 'Major',
      },
      {
        id: 13,
        label: 'Moderate',
        value: 'Moderate',
      },
      {
        id: 14,
        label: 'Minor',
        value: 'Minor',
      },
    ],
    title: 'Level of Damage',
    type: 'radio',
    meta: {
      parentKey: 'rust_damage',
    },
  },
  rust_damage: {
    id: 122,
    isRequired: true,
    isUnsupported: false,
    key: 'rust_damage',
    label: 'Rust Damage',
    options: [
      {
        id: 10,
        label: 'Yes',
        value: true,
      },
      {
        id: 11,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 123,
        isRequired: true,
        isUnsupported: false,
        key: 'rust_damage_severity',
        label: '',
        options: [
          {
            id: 12,
            label: 'Major',
            value: 'Major',
          },
          {
            id: 13,
            label: 'Moderate',
            value: 'Moderate',
          },
          {
            id: 14,
            label: 'Minor',
            value: 'Minor',
          },
        ],
        title: 'Level of Damage',
        type: 'radio',
      },
    ],
    title: 'Rust Damage',
    type: 'boolean',
    meta: {
      childKeys: ['rust_damage_severity'],
    },
  },
  body_damage_severity: {
    id: 125,
    isRequired: true,
    isUnsupported: false,
    key: 'body_damage_severity',
    label: '',
    options: [
      {
        id: 17,
        label: 'Major',
        value: 'Major',
      },
      {
        id: 18,
        label: 'Moderate',
        value: 'Moderate',
      },
      {
        id: 19,
        label: 'Minor',
        value: 'Minor',
      },
    ],
    title: 'Level of Damage',
    type: 'radio',
    meta: {
      parentKey: 'body_damage',
    },
  },
  body_damage: {
    id: 124,
    isRequired: true,
    isUnsupported: false,
    key: 'body_damage',
    label: 'Body Damage',
    options: [
      {
        id: 15,
        label: 'Yes',
        value: true,
      },
      {
        id: 16,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 125,
        isRequired: true,
        isUnsupported: false,
        key: 'body_damage_severity',
        label: '',
        options: [
          {
            id: 17,
            label: 'Major',
            value: 'Major',
          },
          {
            id: 18,
            label: 'Moderate',
            value: 'Moderate',
          },
          {
            id: 19,
            label: 'Minor',
            value: 'Minor',
          },
        ],
        title: 'Level of Damage',
        type: 'radio',
      },
    ],
    title: 'Body Damage',
    type: 'boolean',
    meta: {
      childKeys: ['body_damage_severity'],
    },
  },
  engine_issues_types: {
    id: 127,
    isRequired: true,
    isUnsupported: false,
    key: 'engine_issues_types',
    label: '',
    options: [
      {
        id: 22,
        label: 'Engine Noise',
        value: 'Engine Noise',
      },
      {
        id: 23,
        label: 'Excessive Smoke',
        value: 'Excessive Smoke',
      },
      {
        id: 24,
        label: 'Runs Rough',
        value: 'Runs Rough',
      },
      {
        id: 25,
        label: 'Does Not Start',
        value: 'Does Not Start',
      },
      {
        id: 26,
        label: "Won't Stay Running",
        value: "Won't Stay Running",
      },
    ],
    title: 'Issues',
    type: 'checkbox',
    meta: {
      parentKey: 'engine_issues',
    },
  },
  engine_issues: {
    id: 126,
    isRequired: true,
    isUnsupported: false,
    key: 'engine_issues',
    label: 'Engine Issues',
    options: [
      {
        id: 20,
        label: 'Yes',
        value: true,
      },
      {
        id: 21,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 127,
        isRequired: true,
        isUnsupported: false,
        key: 'engine_issues_types',
        label: '',
        options: [
          {
            id: 22,
            label: 'Engine Noise',
            value: 'Engine Noise',
          },
          {
            id: 23,
            label: 'Excessive Smoke',
            value: 'Excessive Smoke',
          },
          {
            id: 24,
            label: 'Runs Rough',
            value: 'Runs Rough',
          },
          {
            id: 25,
            label: 'Does Not Start',
            value: 'Does Not Start',
          },
          {
            id: 26,
            label: "Won't Stay Running",
            value: "Won't Stay Running",
          },
        ],
        title: 'Issues',
        type: 'checkbox',
      },
    ],
    title: 'Engine Issues',
    type: 'boolean',
    meta: {
      childKeys: ['engine_issues_types'],
    },
  },
  interior_issue_types: {
    id: 129,
    isRequired: true,
    isUnsupported: false,
    key: 'interior_issue_types',
    label: '',
    options: [
      {
        id: 29,
        label: 'Airbag',
        value: 'Airbag',
      },
      {
        id: 30,
        label: 'Electronics',
        value: 'Electronics',
      },
      {
        id: 31,
        label: 'Odor',
        value: 'Odor',
      },
      {
        id: 32,
        label: 'Seat Damage',
        value: 'Seat Damage',
      },
      {
        id: 33,
        label: 'Other Damage',
        value: 'Other Damage',
      },
    ],
    title: 'Issues',
    type: 'checkbox',
    meta: {
      parentKey: 'interior_issues',
    },
  },
  interior_issues: {
    id: 128,
    isRequired: true,
    isUnsupported: false,
    key: 'interior_issues',
    label: 'Interior Issues',
    options: [
      {
        id: 27,
        label: 'Yes',
        value: true,
      },
      {
        id: 28,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 129,
        isRequired: true,
        isUnsupported: false,
        key: 'interior_issue_types',
        label: '',
        options: [
          {
            id: 29,
            label: 'Airbag',
            value: 'Airbag',
          },
          {
            id: 30,
            label: 'Electronics',
            value: 'Electronics',
          },
          {
            id: 31,
            label: 'Odor',
            value: 'Odor',
          },
          {
            id: 32,
            label: 'Seat Damage',
            value: 'Seat Damage',
          },
          {
            id: 33,
            label: 'Other Damage',
            value: 'Other Damage',
          },
        ],
        title: 'Issues',
        type: 'checkbox',
      },
    ],
    title: 'Interior Issues',
    type: 'boolean',
    meta: {
      childKeys: ['interior_issue_types'],
    },
  },
  mechanical_issue_types: {
    id: 131,
    isRequired: true,
    isUnsupported: false,
    key: 'mechanical_issue_types',
    label: '',
    options: [
      {
        id: 36,
        label: 'AC',
        value: 'AC',
      },
      {
        id: 37,
        label: 'Head Gasket',
        value: 'Head Gasket',
      },
      {
        id: 38,
        label: 'Oil Leak',
        value: 'Oil Leak',
      },
      {
        id: 39,
        label: 'Suspension',
        value: 'Suspension',
      },
      {
        id: 40,
        label: 'Steering',
        value: 'Steering',
      },
      {
        id: 41,
        label: 'Transmission',
        value: 'Transmission',
      },
    ],
    title: 'Issues',
    type: 'checkbox',
    meta: {
      parentKey: 'mechanical_issues',
    },
  },
  mechanical_issues: {
    id: 130,
    isRequired: true,
    isUnsupported: false,
    key: 'mechanical_issues',
    label: 'Mechanical Issues',
    options: [
      {
        id: 34,
        label: 'Yes',
        value: true,
      },
      {
        id: 35,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 131,
        isRequired: true,
        isUnsupported: false,
        key: 'mechanical_issue_types',
        label: '',
        options: [
          {
            id: 36,
            label: 'AC',
            value: 'AC',
          },
          {
            id: 37,
            label: 'Head Gasket',
            value: 'Head Gasket',
          },
          {
            id: 38,
            label: 'Oil Leak',
            value: 'Oil Leak',
          },
          {
            id: 39,
            label: 'Suspension',
            value: 'Suspension',
          },
          {
            id: 40,
            label: 'Steering',
            value: 'Steering',
          },
          {
            id: 41,
            label: 'Transmission',
            value: 'Transmission',
          },
        ],
        title: 'Issues',
        type: 'checkbox',
      },
    ],
    title: 'Mechanical Issues',
    type: 'boolean',
    meta: {
      childKeys: ['mechanical_issue_types'],
    },
  },
  warning_lights_types: {
    id: 133,
    isRequired: true,
    isUnsupported: false,
    key: 'warning_lights_types',
    label: '',
    options: [
      {
        id: 44,
        label: 'Airbag',
        value: 'Airbag',
      },
      {
        id: 45,
        label: 'Battery',
        value: 'Battery',
      },
      {
        id: 46,
        label: 'Brakes',
        value: 'Brakes',
      },
      {
        id: 47,
        label: 'Engine',
        value: 'Engine',
      },
      {
        id: 48,
        label: 'Pressure',
        value: 'Pressure',
      },
      {
        id: 49,
        label: 'Traction',
        value: 'Traction',
      },
    ],
    title: 'Lights',
    type: 'checkbox',
    meta: {
      parentKey: 'warning_lights',
    },
  },
  warning_lights: {
    id: 132,
    isRequired: true,
    isUnsupported: false,
    key: 'warning_lights',
    label: 'Warning Lights',
    options: [
      {
        id: 42,
        label: 'Yes',
        value: true,
      },
      {
        id: 43,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 133,
        isRequired: true,
        isUnsupported: false,
        key: 'warning_lights_types',
        label: '',
        options: [
          {
            id: 44,
            label: 'Airbag',
            value: 'Airbag',
          },
          {
            id: 45,
            label: 'Battery',
            value: 'Battery',
          },
          {
            id: 46,
            label: 'Brakes',
            value: 'Brakes',
          },
          {
            id: 47,
            label: 'Engine',
            value: 'Engine',
          },
          {
            id: 48,
            label: 'Pressure',
            value: 'Pressure',
          },
          {
            id: 49,
            label: 'Traction',
            value: 'Traction',
          },
        ],
        title: 'Lights',
        type: 'checkbox',
      },
    ],
    title: 'Warning Lights',
    type: 'boolean',
    meta: {
      childKeys: ['warning_lights_types'],
    },
  },
  modifications_types: {
    id: 135,
    isRequired: true,
    isUnsupported: false,
    key: 'modifications_types',
    label: '',
    options: [
      {
        id: 52,
        label: 'Exhaust',
        value: 'Exhaust',
      },
      {
        id: 53,
        label: 'Performance',
        value: 'Performance',
      },
      {
        id: 54,
        label: 'Radio',
        value: 'Radio',
      },
      {
        id: 55,
        label: 'Suspension',
        value: 'Suspension',
      },
      {
        id: 56,
        label: 'Wheels',
        value: 'Wheels',
      },
    ],
    title: 'Modified',
    type: 'checkbox',
    meta: {
      parentKey: 'modifications',
    },
  },
  modifications: {
    id: 134,
    isRequired: true,
    isUnsupported: false,
    key: 'modifications',
    label: 'Modifications',
    options: [
      {
        id: 50,
        label: 'Yes',
        value: true,
      },
      {
        id: 51,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 135,
        isRequired: true,
        isUnsupported: false,
        key: 'modifications_types',
        label: '',
        options: [
          {
            id: 52,
            label: 'Exhaust',
            value: 'Exhaust',
          },
          {
            id: 53,
            label: 'Performance',
            value: 'Performance',
          },
          {
            id: 54,
            label: 'Radio',
            value: 'Radio',
          },
          {
            id: 55,
            label: 'Suspension',
            value: 'Suspension',
          },
          {
            id: 56,
            label: 'Wheels',
            value: 'Wheels',
          },
        ],
        title: 'Modified',
        type: 'checkbox',
      },
    ],
    title: 'Modifications',
    type: 'boolean',
    meta: {
      childKeys: ['modifications_types'],
    },
  },
  tire_issues_types: {
    id: 137,
    isRequired: true,
    isUnsupported: false,
    key: 'tire_issues_types',
    label: '',
    options: [
      {
        id: 59,
        label: 'Damaged',
        value: 'Damaged',
      },
      {
        id: 60,
        label: 'Incorrect Size',
        value: 'Incorrect Size',
      },
      {
        id: 61,
        label: 'Mismatched',
        value: 'Mismatched',
      },
      {
        id: 62,
        label: 'Missing Spare',
        value: 'Missing Spare',
      },
      {
        id: 63,
        label: 'Uneven Wear',
        value: 'Uneven Wear',
      },
    ],
    title: 'Issues',
    type: 'checkbox',
    meta: {
      parentKey: 'tire_issues',
    },
  },
  tire_issues: {
    id: 136,
    isRequired: true,
    isUnsupported: false,
    key: 'tire_issues',
    label: 'Tire Issues',
    options: [
      {
        id: 57,
        label: 'Yes',
        value: true,
      },
      {
        id: 58,
        label: 'No',
        value: false,
      },
    ],
    subQuestions: [
      {
        id: 137,
        isRequired: true,
        isUnsupported: false,
        key: 'tire_issues_types',
        label: '',
        options: [
          {
            id: 59,
            label: 'Damaged',
            value: 'Damaged',
          },
          {
            id: 60,
            label: 'Incorrect Size',
            value: 'Incorrect Size',
          },
          {
            id: 61,
            label: 'Mismatched',
            value: 'Mismatched',
          },
          {
            id: 62,
            label: 'Missing Spare',
            value: 'Missing Spare',
          },
          {
            id: 63,
            label: 'Uneven Wear',
            value: 'Uneven Wear',
          },
        ],
        title: 'Issues',
        type: 'checkbox',
      },
    ],
    title: 'Tire Issues',
    type: 'boolean',
    meta: {
      childKeys: ['tire_issues_types'],
    },
  },
  tire_tread: {
    id: 138,
    isRequired: true,
    isUnsupported: false,
    key: 'tire_tread',
    label: 'Tire Tread',
    options: [
      {
        id: 64,
        label: 'Poor',
        value: 'Poor',
      },
      {
        id: 65,
        label: 'Fair',
        value: 'Fair',
      },
      {
        id: 66,
        label: 'Good',
        value: 'Good',
      },
    ],
    subQuestions: [],
    title: 'Tire Tread',
    type: 'radio',
    meta: {
      childKeys: [],
    },
  },
  transmission: {
    id: 142,
    isRequired: true,
    isUnsupported: false,
    key: 'transmission',
    label: 'Transmission',
    options: [
      {
        id: 86,
        label: 'Auto',
        value: 'Auto',
      },
      {
        id: 87,
        label: 'Manual',
        value: 'Manual',
      },
    ],
    subQuestions: [],
    title: 'Transmission',
    type: 'radio',
    meta: {
      childKeys: [],
    },
  },
};
