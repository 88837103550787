import React, { useState, useEffect } from 'react';

import Portal from './Portal';
import Flex from './Flex';

const AlertType = {
  Success: 'success',
  Error: 'error',
};

const Alert = ({ message, type, timeout = 5000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const id = setTimeout(() => setVisible(false), timeout);
      return () => clearTimeout(id);
    }
  }, [message]);

  const getColorProps = () => {
    switch (type) {
      case AlertType.Success:
        return { backgroundColor: 'success', color: 'successText' };
      case AlertType.Error:
        return { backgroundColor: 'error', color: 'errorText' };
      default:
        return {};
    }
  };

  return (
    visible && (
      <Portal>
        <Flex
          alignItems="center"
          height="control"
          justifyContent="center"
          sx={{ ...getColorProps(), position: 'fixed', left: 0, bottom: 0 }}
          width="full"
        >
          {message}
        </Flex>
      </Portal>
    )
  );
};

export default Alert;
