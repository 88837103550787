import React from 'react';
import { useQuery } from '@apollo/client';

import { Settings } from 'pages/Settings/Settings';
import { GET_STORES } from 'graphql/queries/Stores';

import { MainLayout } from 'ui';

export const SettingsContainer = () => {
  const { data, loading, error } = useQuery(GET_STORES);

  return (
    <MainLayout>
      <Settings isLoading={loading} error={error} stores={data?.stores || []} />
    </MainLayout>
  );
};
