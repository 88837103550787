import React from 'react';

import Flex from './Flex';
import Text from './Text';

const TextLabel = ({
  label,
  text,
  isOverflow,
  shouldWrap,
  placeholderText = '-',
  hasPlaceholder = true,
  children,
  content,
  upperCase,
  ...props
}) => (
  <Flex
    {...props}
    alignItems="center"
    sx={{
      ...(shouldWrap && { flexFlow: 'wrap' }),
      ...(upperCase && { textTransform: 'uppercase' }),
    }}
  >
    {label && (
      <Text mr="xs" sx={{ flexShrink: 0 }}>
        {label}:
      </Text>
    )}
    {content || (
      <Text weight="semiBold" isOverflow={isOverflow}>
        {text || (hasPlaceholder && placeholderText)}
      </Text>
    )}
  </Flex>
);

export default TextLabel;
