import { gql } from '@apollo/client';

export const UPDATE_APPOINTMENT_DATE = gql`
  mutation UpdateAppointmentDate($id: Int!, $appointment_date: timestamp!) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { appointment_date: $appointment_date }
    ) {
      id
      appointment_date
    }
  }
`;

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle(
    $id: Int!
    $make: String!
    $km: Int!
    $year: Int!
    $model: String!
    $vin: String = null
    $trim: String = null
    $style: String = null
    $bb_uvc: String = null
    $contact_handle: String = null
    $asking_price: Int = null
    $appraised_price: Int = null
  ) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: {
        style: $style
        trim: $trim
        make: $make
        model: $model
        year: $year
        km: $km
        vin: $vin
        contact_handle: $contact_handle
        bb_uvc: $bb_uvc
        asking_price: $asking_price
        appraised_price: $appraised_price
      }
    ) {
      id
      km
      mi
      make
      appraised_price
      asking_price
      contact_handle
      model
      year
      vin
    }
  }
`;

export const UPDATE_BB_DATA = gql`
  mutation UpdateBBData($vehicle_id: Int!, $bb_data: vehicles_set_input!) {
    update_vehicles_by_pk(pk_columns: { id: $vehicle_id }, _set: $bb_data) {
      id
      bb_uvc
      km
      mi
      adjusted_whole_avg
      adjusted_whole_clean
      adjusted_whole_rough
      adjusted_whole_xclean
    }
  }
`;

export const DISMISS_VEHICLE = gql`
  mutation DismissVehicle(
    $vehicle_id: Int!
    $active: Boolean!
    $dismissed: Boolean!
  ) {
    update_vehicle_status_by_pk(
      pk_columns: { vehicle_id: $vehicle_id }
      _set: { dismissed: $dismissed, active: $active }
    ) {
      vehicle {
        id
        status {
          dismissed
        }
      }
    }
  }
`;

export const UPDATE_CONTACT_HANDLE = gql`
  mutation UpdateContactHandle($id: Int!, $contact_handle: String!) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { contact_handle: $contact_handle }
    ) {
      contact_handle
      id
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateVehicleStatus(
    $vehicle_id: Int!
    $_set: vehicle_status_set_input!
  ) {
    update_vehicle_status_by_pk(
      pk_columns: { vehicle_id: $vehicle_id }
      _set: $_set
    ) {
      vehicle {
        id
        contact_id
        store_id
        status {
          dismissed
          follow_up
          appointment
          sent_to_crm
          dormant
        }
      }
    }
  }
`;

export const INSERT_APPRAISAL_WITH_DISCLOSURES = gql`
  mutation InsertAppraisalWithDisclosures(
    $bb_adjusted_whole_avg: Int
    $bb_adjusted_whole_clean: Int
    $bb_adjusted_whole_rough: Int
    $bb_adjusted_whole_xclean: Int
    $accident: String
    $condition: String
    $disclosure_answers: jsonb
    $disclosure_questions: jsonb
    $disclosure_response: jsonb
    $disclosure_id: Int
    $store_id: Int
    $trade_in: String
    $vehicle_id: Int
    $engine: String
    $mode: String
    $offer: Int
    $offer_acv: Int
    $offer_bb: Int
    $offer_displayed: Boolean
    $offer_floor: Int
    $offer_range_acv: String
    $offer_range_bb: String
    $offer_top: Int
    $offer_accepted: Boolean
    $appraiser_id: Int
    $sourced_by: String
    $color: String
    $zip_code: Int
    $odometer: Int
  ) {
    insert_appraisal_one(
      object: {
        bb_adjusted_whole_avg: $bb_adjusted_whole_avg
        bb_adjusted_whole_clean: $bb_adjusted_whole_clean
        bb_adjusted_whole_rough: $bb_adjusted_whole_rough
        bb_adjusted_whole_xclean: $bb_adjusted_whole_xclean
        disclosures: {
          data: {
            accident: $accident
            color: $color
            zip_code: $zip_code
            odometer: $odometer
            condition: $condition
            disclosure_answers: $disclosure_answers
            disclosure_questions: $disclosure_questions
            disclosure_response: $disclosure_response
            store_id: $store_id
            trade_in: $trade_in
            acv_disclosure_id: $disclosure_id
            vehicle_id: $vehicle_id
          }
        }
        engine: $engine
        mode: $mode
        offer: $offer
        offer_acv: $offer_acv
        offer_bb: $offer_bb
        offer_displayed: $offer_displayed
        offer_floor: $offer_floor
        offer_range_acv: $offer_range_acv
        offer_range_bb: $offer_range_bb
        offer_top: $offer_top
        offer_accepted: $offer_accepted
        appraiser_id: $appraiser_id
        sourced_by: $sourced_by
        vehicle_id: $vehicle_id
      }
    ) {
      id
      disclosure_id
    }
  }
`;
