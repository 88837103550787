import React from 'react';

import Text from './Text';

const Heading =
  (as, { sx: headingStyle, ...headingProps }) =>
  ({ sx, ...props }) =>
    (
      <Text
        {...headingProps}
        {...props}
        as={as}
        sx={{ ...sx, ...headingStyle }}
      />
    );

export const H1 = Heading('h1', {
  fontWeight: 'semiBold',
  fontSize: 'xl',
});

export const H2 = Heading('h2', {
  fontWeight: 'semiBold',
  fontSize: 'lg',
});

export const H3 = Heading('h3', {
  fontWeight: 'semiBold',
  fontSize: 'md',
});
