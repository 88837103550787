import { useEffect, useState } from 'react';

import { useDebounce } from './useDebounce';

export const useDebouncedState = (
  defaultValue,
  { onChange, delay = 500 } = {}
) => {
  const [value, setValue] = useState(defaultValue);
  useDebounce(value, { onChange, delay });

  useEffect(() => {
    if (!value && defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return [value, setValue];
};
