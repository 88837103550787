import React, { useState, useEffect, useMemo } from 'react';
import { isEmpty, split, join, map, has } from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { useAssignedAgentsQuery } from '../hooks';
import { Contacts } from 'components';
import { GET_VEHICLES, SEARCH_VEHICLES } from 'graphql/queries/Vehicles';
import { MESSAGE_FROM_CONTACT } from 'graphql/subscriptions/Vehicles';
import { makeStatusParam, useRouteValue } from 'lib';

const moldSearch = (str) =>
  join(
    ' ',
    map((x) => `%${x}%`, split(' ', str))
  );

export const ContactsContainer = ({ isActive, isResponsive, onNext }) => {
  const { vehicle_id } = useParams();
  const [status, setStatus] = useState('');
  const [tag, setTag] = useState('');
  const [store, setStore] = useState('');
  const [agent, setAgent] = useState('');
  const [query, setQuery] = useState('');
  const [order, setOrder] = useState('desc_nulls_last');
  const history = useHistory();
  const { users } = useAssignedAgentsQuery();

  const [params, setParams] = useState({
    where: {},
    order_by: { message_last: { dispatch_at: 'desc_nulls_first' } },
  });

  const handleSearchChange = useRouteValue({
    name: 'q',
    onChange: setQuery,
  });

  const tags = useQuery(
    gql`
      query GetAllTags {
        stores {
          id
          tags
        }
      }
    `,
    {
      onError: (err) => {
        if (err.toString().includes('JWT')) {
          localStorage.removeItem('token');
          history.push('/');
        }
      },
    }
  );

  const agents = useQuery(gql`
    {
      agents_with_vehicles {
        id
        name
      }
    }
  `);

  const stores = useQuery(gql`
    query GetAllStores {
      stores(order_by: { name: asc }) {
        id
        name
      }
    }
  `);

  useEffect(() => {
    setParams(makeStatusParam(status, tag, store, order, agent));
  }, [status, tag, store, order, agent]);

  const {
    subscribeToMore,
    loading: loadingQuery,
    error,
    data: queryData,
    fetchMore,
  } = useQuery(GET_VEHICLES, {
    skip: query !== '',
    variables: {
      limit: 20,
      offset: 0,
      order_by: params.order_by,
      where: params.where,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const {
    fetchMore: fetchMoreSearch,
    loading: searchLoading,
    data: searchData,
  } = useQuery(SEARCH_VEHICLES, {
    variables: {
      query: query && moldSearch(query),
      where: params.where,
      limit: 20,
      offset: 0,
    },
    fetchPolicy: 'network-only',
    skip: query === '',
  });

  const vehicles = useMemo(() => {
    if (query) {
      return !searchLoading && searchData?.search_vehicles;
    }

    return !loadingQuery && queryData?.vehicles;
  }, [
    loadingQuery,
    query,
    queryData?.vehicles,
    searchData?.search_vehicles,
    searchLoading,
  ]);

  const handleLoadMoreVehicles = () => {
    const assignVehicles = (prev, fetchMoreResult) => {
      if (has('search_vehicles', prev)) {
        return {
          search_vehicles: [
            ...prev.search_vehicles,
            ...fetchMoreResult.search_vehicles,
          ],
        };
      }

      return { vehicles: [...prev.vehicles, ...fetchMoreResult.vehicles] };
    };

    const options = {
      variables: {
        offset: vehicles.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (isEmpty(fetchMoreResult.vehicles)) {
          return prev;
        }

        return { ...prev, ...assignVehicles(prev, fetchMoreResult) };
      },
    };

    if (query) {
      return fetchMoreSearch(options);
    }

    return fetchMore(options);
  };

  useEffect(() => {
    switch (true) {
      case !loadingQuery && vehicles && !isEmpty(vehicles):
        if (!vehicle_id) {
          return history.push(
            `/chat/contact/${vehicles[0].contact_id}/vehicle/${vehicles[0].id}/{$status}`
          );
        }

        break;
      default:
        break;
    }
  }, [loadingQuery, vehicles]);

  // Hot fix prevent scroll to top on new message
  useEffect(() => {
    const [contactsScroll] = document.querySelectorAll(
      '.infinite-scroll-component'
    );

    contactsScroll.scrollTo(0, Number(localStorage.getItem('scroll')));
  });

  useEffect(() => {
    subscribeToMore({
      document: MESSAGE_FROM_CONTACT,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data.vehicles.length === 0) {
          return prev;
        }

        const newMessageVehicle = subscriptionData.data.vehicles[0];
        if (!prev.vehicles) {
          return prev;
        }

        const cleaned = prev.vehicles.filter(
          (x) => x.id !== newMessageVehicle.id
        );

        return {
          ...prev,
          vehicles: [newMessageVehicle, ...cleaned],
        };
      },
    });
  }, []);

  return (
    <Contacts
      error={error}
      handleLoadMoreVehicles={handleLoadMoreVehicles}
      handleSearch={handleSearchChange}
      isActive={isActive}
      isResponsive={isResponsive}
      loading={loadingQuery || searchLoading}
      onNext={onNext}
      order={order}
      query={query}
      setOrder={setOrder}
      setStatus={setStatus}
      setStore={setStore}
      setTag={setTag}
      setAgent={setAgent}
      agent={agent}
      agents={!agents.loading && agents.data?.agents_with_vehicles}
      status={status}
      store={store}
      stores={!stores.loading && stores.data}
      tag={tag}
      tags={!tags.loading && tags.data}
      vehicles={vehicles}
      users={users}
    />
  );
};
