import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Textarea as TextareaRF } from '@rebass/forms';

import FormControl from './FormControl';

const TextareaSize = {
  md: '100px',
  lg: '200px',
  xl: '340px',
};

const Textarea = forwardRef(
  (
    {
      error,
      isDisabled,
      isResponsive,
      label,
      name,
      onChange,
      onKeyPress,
      placeholder,
      size = 'md',
      value,
      ...props
    },
    ref
  ) => {
    const textAreaStyle = {
      backgroundColor: 'white',
      borderColor: 'baseDark',
      borderRadius: 'md',
      borderStyle: 'solid',
      borderWidth: 'md',
      color: 'black',
      fontFamily: 'primary',
      fontSize: 'md',
      fontStyle: 'normal',
      fontWeight: 'normal',
      height: isResponsive ? 'full' : TextareaSize[size],
      letterSpacing: 'normal',
      lineHeight: 'normal',
      padding: 'md',
      resize: 'none',
      width: 'full',
      ':focus-visible': {
        outline: 'none',
        borderColor: 'primary',
      },
      ...(isDisabled && { cursor: 'not-allowed' }),
    };

    return (
      <FormControl
        {...props}
        error={error}
        height={isResponsive && 'full'}
        label={label}
      >
        <TextareaRF
          disabled={isDisabled}
          name={name}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          ref={ref}
          sx={textAreaStyle}
          value={value}
        />
      </FormControl>
    );
  }
);

Textarea.propTypes = {
  ...FormControl.propTypes,
  sx: PropTypes.object,
};

export default Textarea;
