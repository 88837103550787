import React from 'react';

import Text from './Text';

const Badge = ({ name, sx, isReverse, ...props }) => {
  return (
    <Text
      {...props}
      size="xs"
      color={isReverse ? 'primary' : 'primaryText'}
      sx={{
        ...sx,
        backgroundColor: 'primary',
        borderRadius: 'md',
        padding: 'xs',
        textTransform: 'uppercase',

        ...(isReverse && {
          backgroundColor: 'primaryText',
        }),
      }}
    >
      {name}
    </Text>
  );
};

export default Badge;
