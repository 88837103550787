import React, { forwardRef } from 'react';

import Text from './Text';
import Flex from './Flex';

const Checkbox = forwardRef(({ id, label, ...props }, ref) => (
  <Flex py="xs">
    <input ref={ref} {...props} id={id} type="checkbox" />
    <Text as="label" ml="xs" htmlFor={id} isOverflow>
      {label}
    </Text>
  </Flex>
));

export default Checkbox;
