import { pipe, path, find, reduce, propEq, map, assoc } from 'ramda';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { DRILLDOWN } from 'graphql/queries/Drilldown';
import { Modal, Input, Select, Flex, Loader } from 'ui';
import { kmToMiles, milesToKm } from 'lib';

const YearsList = [
  2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009,
  2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996,
  1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983,
  1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971, 1970,
  1969, 1968, 1967, 1966, 1965, 1964, 1963, 1962, 1961, 1960,
];

const moldOption = (option) =>
  (option && { label: option, value: option }) || { label: '', value: '' };

const makePath = path([
  'drilldown',
  'class_list',
  0,
  'year_list',
  0,
  'make_list',
]);

const VehicleDetailsEditModal = ({ isOpen, onClose, onSubmit, vehicle }) => {
  const initialFormState = {
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    km: vehicle.km,
    mi: vehicle.mi,
    trim: vehicle.trim,
    vin: vehicle.vin,
    style: vehicle.style,
    contact_handle: vehicle.contact_handle
      ? vehicle.contact_handle
      : vehicle.contact && vehicle.contact.name,
    bb_uvc: vehicle.bb_uvc,
  };

  const [formData, setFormData] = useState(initialFormState);
  const isUSStore = vehicle.store.country === 'US';

  const { data, loading } = useQuery(DRILLDOWN, {
    fetchPolicy: 'cache-first',
    variables: { year: formData.year },
  });

  useEffect(() => {
    setFormData(initialFormState);
  }, [vehicle]);

  const clearField = (fields, obj) => {
    return reduce((a, v) => assoc(v, '', a), obj, fields);
  };

  const traverseDrilldown = (node, param) => {
    const getByName = (name) => find(propEq('name', name));
    const getFrom = (arr_name) => (obj) => obj[arr_name];

    const getModels = [
      makePath,
      getByName(formData.make),
      getFrom('model_list'),
    ];

    const getSeries = [
      ...getModels,
      getByName(formData.model),
      getFrom('series_list'),
    ];

    const getStyles = [
      ...getSeries,
      getByName(formData.trim),
      getFrom('style_list'),
    ];

    const mold = map((x) => {
      if (x.name !== '') {
        return { value: x.name, label: x.name, uvc: x.uvc };
      }
      return { label: 'None', value: '', uvc: 0 };
    });

    try {
      switch (node) {
        // returns makes for year
        case 'makes':
          return pipe(makePath, mold)(param);
        case 'models':
          return pipe(...getModels, mold)(param);
        case 'trims':
          return pipe(...getSeries, mold)(param);
        case 'styles':
          return pipe(...getStyles, mold)(param);
        default:
          console.error(`Unkown node ${node}`);
          break;
      }
    } catch {
      return [{ value: '', label: '', uvc: '' }];
    }
  };

  return (
    <Modal
      header="Edit Vehicle"
      isOpen={isOpen}
      onClose={onClose}
      confirm="Save"
      onConfirm={() => {
        onSubmit(formData);
        onClose();
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Flex flexDirection="column" verticalGap="md">
          <input
            type="hidden"
            name="vehicle_id"
            value={vehicle ? vehicle.id : ''}
          />
          <Input
            label="Contact Name"
            type="text"
            id="contact_handle"
            name="contact_handle"
            onChange={(e) => {
              const val = e.target.value;
              setFormData((x) => {
                return { ...x, contact_handle: val };
              });
            }}
            value={formData.contact_handle}
          />
          <Input
            label="VIN"
            type="text"
            id="vin"
            name="vin"
            onChange={(e) => {
              const val = e.target.value;

              setFormData((values) => {
                return { ...values, vin: val && val.toUpperCase() };
              });
            }}
            value={formData.vin}
          />
          <Flex rowResponsive={{ screen: 'xl' }}>
            <Select
              mr="md"
              label="Year"
              type="number"
              id="year"
              onChange={(e) => {
                setFormData((x) => {
                  return { ...x, year: e.value };
                });
              }}
              name="year"
              value={moldOption(formData.year)}
              options={map((x) => {
                return { label: x, value: x };
              }, YearsList)}
            />
            <Select
              label="Make"
              type="text"
              id="model"
              onChange={(e) => {
                setFormData((values) => {
                  return {
                    ...clearField(['model', 'trim', 'style'], values),
                    make: e.value,
                  };
                });
              }}
              value={moldOption(formData.make)}
              options={traverseDrilldown('makes', data)}
              name="model"
            />
          </Flex>
          <Flex rowResponsive={{ screen: 'xl' }}>
            <Select
              mr="md"
              label="Model"
              type="text"
              id="model"
              onChange={(e) =>
                setFormData((values) => {
                  return {
                    ...clearField(['trim', 'style'], values),
                    model: e.value,
                  };
                })
              }
              value={moldOption(formData.model)}
              options={traverseDrilldown('models', data)}
              name="model"
            />
            <Select
              label="Trim"
              type="text"
              id="model"
              onChange={(e) =>
                setFormData((values) => {
                  return { ...clearField(['style'], values), trim: e.value };
                })
              }
              value={moldOption(formData.trim)}
              options={traverseDrilldown('trims', data)}
              name="model"
            />
          </Flex>
          <Flex rowResponsive={{ screen: 'xl' }}>
            <Select
              mr="md"
              label="Style"
              type="text"
              id="model"
              onChange={(e) => {
                setFormData((values) => {
                  return { ...values, style: e.value, bb_uvc: e.uvc };
                });
              }}
              value={moldOption(formData.style)}
              options={traverseDrilldown('styles', data)}
              name="model"
            />
            <Input
              label={isUSStore ? 'Miles' : 'KM'}
              type="number"
              id={isUSStore ? 'mi' : 'km'}
              name={isUSStore ? 'mi' : 'km'}
              onChange={(e) => {
                let val = e.target.value;
                if (val === '') {
                  val = 0;
                }
                setFormData((x) => {
                  return isUSStore
                    ? { ...x, mi: val, km: milesToKm(val) }
                    : { ...x, km: val, mi: kmToMiles(val) };
                });
              }}
              value={isUSStore ? formData.mi : formData.km}
            />
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default VehicleDetailsEditModal;
