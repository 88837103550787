import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  GET_STORE_PROFILE,
  UPDATE_APPOINTMENT_TARGET,
  UPDATE_STORE_ACTIVE,
} from 'graphql/queries/Stores';
import { Box, Flex, Text, Input, Checkbox, H1, Loader } from 'ui';

export const Profile = ({ store_id }) => {
  const { data, loading } = useQuery(GET_STORE_PROFILE, {
    variables: { id: Number(store_id) },
  });

  const [updateAppointmentTarget] = useMutation(UPDATE_APPOINTMENT_TARGET);
  const [updateStoreActive] = useMutation(UPDATE_STORE_ACTIVE);

  if (loading || data.stores_by_pk === null) {
    return <Loader />;
  }

  const store = data.stores_by_pk;
  const comms = (store && store.store_communication) || {};

  return (
    <Box>
      <H1>{store.name}</H1>
      <Flex mt="lg" flexDirection="column" verticalGap="lg">
        <Flex flexDirection="column" verticalGap="sm">
          <Box>
            <Text fontWeight="semiBold">Email: </Text>
            <Text>{comms.email ?? 'N/A'}</Text>
          </Box>
          <Box>
            <Text fontWeight="semiBold">Twilio Phone: </Text>
            <Text>{comms.twilio_phone ?? 'N/A'}</Text>
          </Box>
          <Box mb="lg">
            <Text fontWeight="semiBold">Region: </Text>
            <Text>{store?.area?.name ?? 'N/A'}</Text>
          </Box>
        </Flex>
        <Input
          label="Appointment Target"
          key={store.name}
          onChange={(e) =>
            e.target.value !== '' &&
            updateAppointmentTarget({
              variables: {
                id: store.id,
                appointment_target: Number(e.target.value),
              },
            })
          }
          onClick={(e) => (e.target.value = '')}
          onBlur={(e) => (e.target.value = store.appointment_target)}
          defaultValue={store.appointment_target}
        />
        <Checkbox
          label="Active"
          checked={store.active}
          onChange={() => {
            updateStoreActive({
              variables: { id: store.id, active: !store.active },
            });
          }}
        />
      </Flex>
    </Box>
  );
};
