export const makeStatusParam = (status, tag, store, order, agent) => {
  let where = {};

  if (tag) {
    where = { ...where, tags: { _contains: tag } };
  }

  if (status && status !== 'unread') {
    where = { ...where, status: { [`${status}`]: { _eq: true } } };
  }

  if (status === 'unread') {
    where = { ...where, message_last: { seen: { _eq: false } } };
  }

  if (store) {
    where = { ...where, store: { id: { _eq: store } } };
  }

  if (order === '') {
    order = 'desc_nulls_last';
  }

  if (agent && agent !== 'Unassigned') {
    where = { ...where, owner_id: { _eq: agent } };
  }

  if (agent === 'Unassigned') {
    where = { ...where, owner_id: { _is_null: true } };
  }

  const orderEx = { message_last: { dispatch_at: order } };
  return { where, order_by: orderEx };
};

export const makeSearchStatus = (status) => {
  if (status === 'unread') {
    return {};
  } else if (status !== '' && status !== 'any') {
    return { status: { [`${status}`]: { _eq: true } } };
  }
};
