import React from 'react';

import { Flex, Icon, Text } from 'ui';

const EmptyVehiclePlaceholder = ({
  headline = 'No vehicle selected',
  message = 'Select a vehicle to start engagement',
}) => (
  <Flex alignItems="center" justifyContent="center" height="full">
    <Flex flexDirection="column" alignItems="center" padding="md">
      <Icon color="dark" size="lg" icon="info" />
      <Text size="md" weight="semiBold">
        {headline}
      </Text>
      <Text size="sm">{message}</Text>
    </Flex>
  </Flex>
);

export default EmptyVehiclePlaceholder;
