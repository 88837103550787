import React, { useState } from 'react';
import { omit, evolve } from 'ramda';
import { useQuery, useMutation } from '@apollo/client';
import toastr from 'toastr';

import { FieldItem } from './FieldItem';
import { GET_STORE_VEHICLE_PREF } from 'graphql/queries/Stores';
import { UPDATE_STORE_VEHICLE_PREF } from 'graphql/mutations/Stores';
import { Button, Flex, Select } from 'ui';

const makeOptions = [
  { label: 'Acura', value: 'acura' },
  { label: 'Alfa Romeo', value: 'alfa romeo' },
  { label: 'Audi', value: 'audi' },
  { label: 'BMW', value: 'bmw' },
  { label: 'Buick', value: 'buick' },
  { label: 'Cadillac', value: 'cadillac' },
  { label: 'Chevrolet', value: 'chevrolet' },
  { label: 'Chrysler', value: 'chrysler' },
  { label: 'Dodge', value: 'dodge' },
  { label: 'Fiat', value: 'fiat' },
  { label: 'Ford', value: 'ford' },
  { label: 'Genesis', value: 'genesis' },
  { label: 'GMC', value: 'gmc' },
  { label: 'Honda', value: 'honda' },
  { label: 'Hyundai', value: 'hyundai' },
  { label: 'INFINITI', value: 'infiniti' },
  { label: 'Jaguar', value: 'jaguar' },
  { label: 'Jeep', value: 'jeep' },
  { label: 'Kia', value: 'kia' },
  { label: 'Land Rover', value: 'land rover' },
  { label: 'Lexus', value: 'lexus' },
  { label: 'Lincoln', value: 'lincoln' },
  { label: 'Maserati', value: 'maserati' },
  { label: 'Mazda', value: 'mazda' },
  { label: 'Mercedes-Benz', value: 'mercedes-benz' },
  { label: 'Mini', value: 'mini' },
  { label: 'Mitsubishi', value: 'mitsubishi' },
  { label: 'Nissan', value: 'nissan' },
  { label: 'Polestar', value: 'polestar' },
  { label: 'Porsche', value: 'porsche' },
  { label: 'Ram', value: 'ram' },
  { label: 'Subaru', value: 'subaru' },
  { label: 'Tesla', value: 'tesla' },
  { label: 'Toyota', value: 'toyota' },
  { label: 'Volkswagen', value: 'volkswagen' },
  { label: 'Volvo', value: 'volvo' },
  { label: 'Freightliner', value: 'freightliner' },
  { label: 'Smart', value: 'smart' },
  { label: 'Scion', value: 'scion' },
  { label: 'MobilityVenture', value: 'mobilityventure' },
  { label: 'Suzuki', value: 'suzuki' },
  { label: 'Mercury', value: 'mercury' },
  { label: 'Saab', value: 'saab' },
  { label: 'Hummer', value: 'hummer' },
  { label: 'Pontiac', value: 'pontiac' },
  { label: 'Saturn', value: 'saturn' },
  { label: 'Isuzu', value: 'isuzu' },
  { label: 'Oldsmobile', value: 'oldsmobile' },
  { label: 'Daewoo', value: 'daewoo' },
  { label: 'Plymouth', value: 'plymouth' },
  { label: 'Eagle', value: 'eagle' },
  { label: 'Geo', value: 'geo' },
  { label: 'Daihatsu', value: 'daihatsu' },
  { label: 'Peugeot', value: 'peugeot' },
  { label: 'Sterling', value: 'sterling' },
  { label: 'AMC', value: 'amc' },
  { label: 'Renault', value: 'renault' },
];

export const Filter = ({ store_id }) => {
  const [country, setCountry] = useState('');
  const [selectedMakes, setSelectedMakes] = useState(null);
  const [filterValues, setFilterValues] = useState({
    max_year: '',
    min_year: '',
    max_km: '',
    min_km: '',
    max_price: '',
    min_price: '',
    min_mi: '',
    max_mi: '',
  });

  useQuery(GET_STORE_VEHICLE_PREF, {
    variables: { store_id },
    onCompleted: (values) => {
      setFilterValues(
        omit(
          ['__typename', 'store', 'store_id'],
          values.store_vehicle_preference_by_pk
        )
      );
      setCountry(values.store_vehicle_preference_by_pk.store.country);
      const { makes } = values.store_vehicle_preference_by_pk;

      if (makes !== null) {
        // eslint-disable-next-line array-callback-return
        const selected = makes.map((m) => {
          for (const o of makeOptions) {
            if (o.value === m) {
              return o;
            }
          }
        });
        setSelectedMakes(selected);
      } else {
        setSelectedMakes(null);
      }
    },
  });

  const [updateStorePref] = useMutation(UPDATE_STORE_VEHICLE_PREF, {
    refetchQueries: [
      { query: GET_STORE_VEHICLE_PREF, variables: { store_id } },
    ],
    onCompleted: () =>
      toastr.success('Saved', '', {
        positionClass: 'toast-top-center',
        proggressBar: true,
        timeOut: 1000,
      }),
  });

  const isUS = country === 'US';

  const handleUpdatePref = () => {
    const convert = {
      max_year: Number,
      min_year: Number,
      max_km: Number,
      min_km: Number,
      max_price: Number,
      min_price: Number,
      min_mi: Number,
      max_mi: Number,
    };

    let vals = { ...filterValues };

    if (isUS) {
      vals.max_km = vals.max_mi * 1.6;
      vals.min_km = vals.min_mi * 1.6;
    }

    vals.makes = selectedMakes && selectedMakes.map((x) => x.value);
    vals = omit(['max_mi', 'min_mi'], vals);

    updateStorePref({ variables: { store_id, ...evolve(convert, vals) } });
  };

  const handleChange = (field, val) => {
    const newVal = {};
    newVal[field] = val;
    setFilterValues((prev) => {
      return { ...prev, ...newVal };
    });
  };

  return (
    <>
      <Flex
        flexDirection="column"
        sx={{
          '& > div:not(:first-of-type)': {
            marginTop: 'lg',
          },
        }}
      >
        <FieldItem
          header="Year"
          name={{ from: 'min_year', to: 'max_year' }}
          values={{
            from: filterValues.min_year,
            to: filterValues.max_year,
          }}
          onChange={handleChange}
        />
        <FieldItem
          header={isUS ? 'Miles' : 'Kilometres'}
          name={
            isUS
              ? { from: 'min_mi', to: 'max_mi' }
              : { from: 'min_km', to: 'max_km' }
          }
          values={
            isUS
              ? { from: filterValues.min_mi, to: filterValues.max_mi }
              : { from: filterValues.min_km, to: filterValues.max_km }
          }
          onChange={handleChange}
        />
        <FieldItem
          header="Price"
          name={{ from: 'min_price', to: 'max_price' }}
          values={{
            from: filterValues.min_price,
            to: filterValues.max_price,
          }}
          onChange={handleChange}
        />
        <Select
          label="Makes"
          key={store_id}
          onChange={(e) => setSelectedMakes(e)}
          isMulti
          value={selectedMakes}
          options={makeOptions}
        />
      </Flex>
      <Button mt="lg" text="Save" onClick={handleUpdatePref} />
    </>
  );
};
