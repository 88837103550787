import React from 'react';

import { H2 } from './Headings';
import { Button, ButtonIcon } from './button';
import Flex from './Flex';
import Portal from './Portal';
import Scrollable from './Scrollable';
import { useEventListener, useWindowSpec } from 'lib';

const Modal = ({
  cancel = 'Cancel',
  children,
  confirm = 'Confirm',
  header,
  isConfirmDisabled = false,
  isLoading = false,
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  sx,
  trigger,
  ...props
}) => {
  const { checkResponsive } = useWindowSpec();

  useEventListener(
    'keyup',
    (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    {
      deps: [isOpen],
      isMuted: !isOpen,
    }
  );

  onCancel = onCancel || onClose;
  const isResponsive = checkResponsive('lg');

  return (
    <>
      {trigger}
      {isOpen && (
        <Portal>
          <Flex
            {...props}
            sx={{
              ...sx,
              alignItems: 'stretch',
              height: '100%',
              width: isResponsive ? 'full' : '27.5%',
              justifyContent: 'start',
              left: 0,
              position: 'fixed',
              top: 0,
            }}
          >
            <Flex
              maxHeight="full"
              flexDirection="column"
              sx={{
                backgroundColor: 'baseLight2',
                boxShadow: 'md',
                width: 'full',
              }}
            >
              <Flex
                maxHeight="full"
                width="full"
                flexDirection="column"
                sx={{
                  backgroundColor: 'baseLight2',
                }}
              >
                <Flex alignItems="center" pl="xl" pr="md" py="lg">
                  {header && <H2>{header}</H2>}
                  <ButtonIcon
                    ml="auto"
                    icon="close"
                    variant="secondary"
                    onClick={onClose}
                  />
                </Flex>
                <Scrollable
                  px="xl"
                  sx={{
                    minHeight: 0,
                    flex: '1',
                  }}
                >
                  {children}
                </Scrollable>
                <Flex
                  justifyContent="center"
                  px="xl"
                  pb="xl"
                  pt="2xl"
                  rowResponsive={{
                    screen: 'xl',
                    isReverse: true,
                  }}
                >
                  <Button
                    onClick={onCancel}
                    text={cancel}
                    variant="secondary"
                  />
                  {onConfirm && (
                    <Button
                      isDisabled={isConfirmDisabled}
                      isLoading={isLoading}
                      onClick={onConfirm}
                      text={confirm}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Portal>
      )}
    </>
  );
};

export default Modal;
