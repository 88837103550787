import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import toastr from 'toastr';
import * as dayjs from 'dayjs';

import { GET_STORE_BOT_OPTION } from 'graphql/queries/Stores';
import { UPDATE_STORE_BOT_OPTION } from 'graphql/mutations/Stores';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  H2,
  Input,
  Switch,
  Text,
  Textarea,
} from 'ui';

const formatHour = (hour) => {
  const d = new Date();
  d.setUTCHours(hour);
  d.setUTCMinutes(0);
  return dayjs(d).format('HH:mm');
};

const Week = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
function capitalize(word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export const AutoSMS = ({ store_id }) => {
  const [autoSmsData, setAutoSmsData] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isOutEnabled, setIsOutEnabled] = useState(false);
  const { handleSubmit, register, reset } = useForm();
  const offset = new Date().getTimezoneOffset() / 60;

  const [updateBotOption] = useMutation(UPDATE_STORE_BOT_OPTION, {
    refetchQueries: [
      { query: GET_STORE_BOT_OPTION, variables: { _eq: store_id } },
    ],
    onCompleted: () => {
      toastr.success('Saved', '', {
        positionClass: 'toast-top-center',
        proggressBar: true,
        timeOut: 1000,
      });
    },
  });

  useQuery(GET_STORE_BOT_OPTION, {
    variables: { _eq: store_id },
    onCompleted: (data) => {
      if (data.store_bot_option_by_pk == null) {
        return;
      }
      setAutoSmsData(data.store_bot_option_by_pk);
      const {
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        pause,
        start_sms_hours,
        finish_sms_hours,
        sendout_interval_minutes,
        send_limit,
        out_office_message,
        out_office_enabled,
      } = data.store_bot_option_by_pk;
      setIsPaused(pause);
      setIsOutEnabled(out_office_enabled);
      reset({
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        pause,
        sendout_interval_minutes,
        send_limit,
        start_sms_hours: formatHour(start_sms_hours, offset),
        finish_sms_hours: formatHour(finish_sms_hours, offset),
        out_office_message,
        out_office_enabled,
      });
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const handleUpdateOptions = handleSubmit((values) => {
    const { start_sms_hours, finish_sms_hours, ...rest } = values;

    const [sh] = start_sms_hours.split(':');
    const [fh] = finish_sms_hours.split(':');
    const sd = new Date();
    const ed = new Date();
    sd.setHours(sh);
    ed.setHours(fh);

    updateBotOption({
      variables: {
        _eq: store_id,
        _set: {
          start_sms_hours: sd.getUTCHours(),
          finish_sms_hours: ed.getUTCHours(),
          ...rest,
        },
      },
    });
  });
  return (
    <Box as="form" onSubmit={handleUpdateOptions}>
      <Flex mb="lg" alignItems="center">
        <Switch
          name="pause"
          handleToggle={() => setIsPaused(!isPaused)}
          isOn={isPaused}
          ref={register}
        />
        <Text ml="xs">Pause Auto SMS</Text>
      </Flex>
      <Flex flexDirection="column">
        <Flex
          justifyContent="space-between"
          flexDirection="row-reverse"
          rowResponsive={{ screen: 'xl' }}
        >
          <Flex flexDirection="column" flexBasis="65%">
            <H2>Hour range for sending SMS</H2>
            <Flex alignItems="center" mt="lg">
              <Input
                label="Daily send from"
                type="time"
                name="start_sms_hours"
                ref={register}
              />
              <Text mx="lg" mt="lg">
                -
              </Text>
              <Input
                label="Daily send until"
                type="time"
                name="finish_sms_hours"
                ref={register}
              />
            </Flex>
            <H2 mt="lg">Sendout interval and volume</H2>
            {autoSmsData && (
              <Text isBlock mt="lg">
                Every {autoSmsData.sendout_interval_minutes} minutes within the{' '}
                <strong>Hour range for sending sms </strong>
                {autoSmsData.send_limit}, sellers will be contacted
              </Text>
            )}
            <Flex alignItems="flex-end" mt="lg">
              <Input
                mr="md"
                label="Interval between sendouts"
                type="number"
                name="sendout_interval_minutes"
                ref={register}
              />
              <Input
                label="Sellers to contact by interval"
                type="number"
                name="send_limit"
                ref={register}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column">
            <H2 mb="lg">Send auto SMS on days</H2>
            {Week.map((day) => (
              <Checkbox
                key={day}
                label={capitalize(day)}
                id={day}
                name={day}
                ref={register}
              />
            ))}
          </Flex>
        </Flex>
        <H2 my="lg">Out of office message</H2>
        <Flex alignItems="center" mb="lg">
          <Switch
            name="out_office_enabled"
            handleToggle={() => setIsOutEnabled(!isOutEnabled)}
            isOn={isOutEnabled}
            ref={register}
          />
          <Text ml="xs">Enable out of office message</Text>
        </Flex>
        <Textarea
          isDisabled={!isOutEnabled}
          label="This will be sent to sellers if they message us outside of Hour
          range (working hours)"
          name="out_office_message"
          ref={register}
        />
      </Flex>
      <Button my="lg" text="Save" />
    </Box>
  );
};
