/* eslint-disable default-case */
import React, { useState, useLayoutEffect } from 'react';
import { uniq } from 'ramda';

import { Select } from 'ui';

const createOption = (label) => ({
  label,
  value: label,
});

const components = {
  DropdownIndicator: null,
};

const customStyles = {
  DropdownIndicator: () => ({
    diplay: 'none',
  }),
};

const SelectMultiple = ({ updateOptions, existingOptions, ...props }) => {
  const [tags, setTags] = useState({ inputValue: '', value: [] });

  const handleChange = (value) => {
    if (!value) {
      return updateOptions([]);
    }

    updateOptions(uniq(value.map((x) => x.label)));
  };

  const handleInputChange = (inputValue) => {
    if (!tags.value) {
      setTags({ inputValue, value: [] });
    }
    if (tags.value) {
      setTags({ inputValue, value: [...tags.value] });
    }
  };

  const handleKeyDown = (event) => {
    const { inputValue, value } = tags;
    if (!inputValue) {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        setTags({
          inputValue: '',
          value: [...value, createOption(inputValue)],
        });

        event.preventDefault();
        const vals = value.map((t) => t.label);
        vals.push(inputValue);
        updateOptions(uniq(vals));
      }
    }
  };

  useLayoutEffect(() => {
    setTags({ inputValue: '', value: existingOptions });
  }, [existingOptions]);

  const { value, inputValue } = tags;

  return (
    <Select
      {...props}
      components={components}
      inputValue={inputValue}
      isClearable={false}
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Add tag..."
      styles={customStyles}
      value={value}
    />
  );
};

export default SelectMultiple;
