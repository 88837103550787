import { useState } from 'react';

import { useEventListener } from './useEventListener';
import { MediaBreakpoints } from 'theme';

export const useWindowSpec = (defaultValue, { isMuted } = {}) => {
  const getWindowSpec = () => {
    if (typeof window === 'undefined') {
      return {
        width: 0,
        height: 0,
      };
    }

    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  const [value, setValue] = useState(defaultValue || getWindowSpec());

  const handleResize = () => {
    const spec = getWindowSpec();
    setValue(spec);
  };

  useEventListener('resize', handleResize, { isMuted });

  const checkResponsive = (size, isMax = true) => {
    const breakpoint = MediaBreakpoints[size];
    if (!breakpoint) {
      return console.error(`Invalid size ${size}.`);
    }

    const { width } = value;
    const breakpointNum = parseInt(breakpoint);
    return isMax ? width <= breakpointNum : width >= breakpointNum;
  };

  return {
    ...value,
    checkResponsive,
  };
};
