import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { Input as InputRF } from '@rebass/forms';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import FormControl from './FormControl';

const Input = forwardRef(
  (
    {
      defaultValue,
      error,
      id,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      post,
      pre,
      type,
      value,
      ...props
    },
    ref
  ) => (
    <FormControl {...props} label={label} error={error}>
      <Flex
        alignItems="center"
        sx={{
          backgroundColor: 'white',
          borderRadius: 'md',
          borderWidth: 'md',
          borderStyle: 'solid',
          borderColor: 'baseDark',
          overflow: 'hidden',
          position: 'relative',
          height: 'control',
          ':focus-within': {
            borderColor: 'primary',
          },
        }}
      >
        {pre && (
          <Box py="sm" pl="sm">
            {pre}
          </Box>
        )}
        <InputRFStyled
          id={id}
          name={name}
          type={type}
          defaultValue={defaultValue}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
          ref={ref}
          value={value}
          sx={{
            backgroundColor: 'transparent',
            color: 'black',
            flexGrow: '1',
            fontSize: 'md',
            fontFamily: 'primary',
            fontStyle: 'normal',
            fontWeight: 'md',
            letterSpacing: 'normal',
            lineHeight: 'normal',
            border: 0,
            height: 'full',
            padding: 'md',
          }}
        />
        {post && (
          <Box py="sm" pr="sm">
            {post}
          </Box>
        )}
      </Flex>
    </FormControl>
  )
);

Input.propTypes = {
  ...FormControl.propTypes,
  pre: PropTypes.node,
  post: PropTypes.node,
};

export default Input;

const InputRFStyled = styled(InputRF)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
  }

  ${({ theme }) => css`
    &:placeholder {
      color: ${theme.colors.baseDark};
      font-style: normal;
      font-family: ${theme.fonts.primary};
      font-weight: ${theme.fontWeights.md};
      font-size: ${theme.fontSizes.md};
      letter-spacing: normal;
      line-height: normal;
    }
  `}
`;
