import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import IconArrowLeft from './IconArrowLeft';
import IconCalendar from './IconCalendar';
import IconCar from './IconCar';
import IconChevronDown from './IconChevronDown';
import IconChevronUp from './IconChevronUp';
import IconClipboardCheck from './IconClipboardCheck';
import IconClose from './IconClose';
import IconDotsVertical from './IconDotsVertical';
import IconEdit from './IconEdit';
import IconEmail from './IconEmail';
import IconError from './IconError';
import IconExternalLink from './IconExternalLink';
import IconFlag from './IconFlag';
import IconInfo from './IconInfo';
import IconMenu from './IconMenu';
import IconPlus from './IconPlus';
import IconSearch from './IconSearch';
import IconSend from './IconSend';
import IconSettings from './IconSettings';
import IconSpinner from './IconSpinner';
import IconTime from './IconTime';

const IconType = {
  ArrowLeft: 'arrow-left',
  Calendar: 'calendar',
  Car: 'car',
  ChevronDown: 'down',
  ChevronUp: 'up',
  ClipboardCheck: 'clipboard-check',
  Close: 'close',
  DotsVertical: 'dots-vertical',
  Edit: 'edit',
  Email: 'email',
  Error: 'error',
  ExternalLink: 'external-link',
  Flag: 'flag',
  Info: 'info',
  Menu: 'menu',
  Plus: 'plus',
  Search: 'search',
  Send: 'send',
  Settings: 'settings',
  Spinner: 'spinner',
  Time: 'time',
};

const IconSize = {
  MD: 'md',
  SM: 'sm',
  LG: 'lg',
};

const IconColor = {
  Dark: 'dark',
  Error: 'error',
  Gray: 'gray',
  Important: 'important',
  Light: 'light',
  Primary: 'primary',
  PrimaryText: 'primaryText',
};

const Icon = ({
  color = IconColor.Dark,
  icon,
  size = IconSize.MD,
  ...props
}) => {
  const renderIcon = () => {
    switch (icon) {
      case IconType.Car:
        return <IconCar />;
      case IconType.ChevronDown:
        return <IconChevronDown />;
      case IconType.ChevronUp:
        return <IconChevronUp />;
      case IconType.Close:
        return <IconClose />;
      case IconType.Error:
        return <IconError />;
      case IconType.Plus:
        return <IconPlus />;
      case IconType.Search:
        return <IconSearch />;
      case IconType.Send:
        return <IconSend />;
      case IconType.Spinner:
        return <IconSpinner />;
      case IconType.Edit:
        return <IconEdit />;
      case IconType.Email:
        return <IconEmail />;
      case IconType.Info:
        return <IconInfo />;
      case IconType.ArrowLeft:
        return <IconArrowLeft />;
      case IconType.Time:
        return <IconTime />;
      case IconType.Settings:
        return <IconSettings />;
      case IconType.Calendar:
        return <IconCalendar />;
      case IconType.ClipboardCheck:
        return <IconClipboardCheck />;
      case IconType.Menu:
        return <IconMenu />;
      case IconType.DotsVertical:
        return <IconDotsVertical />;
      case IconType.Flag:
        return <IconFlag />;
      case IconType.ExternalLink:
        return <IconExternalLink />;
      default:
        console.error(`Unknown icon ${icon}`);
        return null;
    }
  };

  return (
    <IconStyled {...props} size={size} color={color}>
      {renderIcon()}
    </IconStyled>
  );
};

export default Icon;

const IconStyled = styled.span`
  svg {
    ${({ theme, size }) => {
      const defaultStyle = css`
        width: ${theme.fontSizes.lg};
        height: ${theme.fontSizes.lg};
      `;

      switch (size) {
        case IconSize.MD:
          return defaultStyle;
        case IconSize.SM:
          return css`
            width: ${theme.fontSizes.md};
            height: ${theme.fontSizes.md};
          `;
        case IconSize.LG:
          return css`
            width: ${theme.fontSizes['2xl']};
            height: ${theme.fontSizes['2xl']};
          `;
        default:
          console.error(`Unknown icon size ${size}`);
          return defaultStyle;
      }
    }}
  }

  color: ${({ theme, color }) => {
    switch (color) {
      case IconColor.Dark:
        return theme.colors.black;
      case IconColor.Light:
        return theme.colors.white;
      case IconColor.PrimaryText:
        return theme.colors.primaryText;
      case IconColor.Primary:
        return theme.colors.primary;
      case IconColor.Important:
        return theme.colors.importantDark;
      case IconColor.Error:
        return theme.colors.error;
      case IconColor.Gray:
        return theme.colors.baseDark2;
      default:
        console.error(`Unknown icon color ${color}`);
        return theme.colors.black;
    }
  }};
`;
