import { gql } from '@apollo/client';

export const GET_STORE_PROFILE = gql`
  query getStoreProfile($id: Int!) {
    stores_by_pk(id: $id) {
      id
      name
      area {
        name
      }
      store_communication {
        twilio_phone
      }
      appointment_target
      active
    }
  }
`;

export const UPDATE_STORE_ACTIVE = gql`
  mutation UpdateStoreActive($id: Int!, $active: Boolean!) {
    update_stores_by_pk(pk_columns: { id: $id }, _set: { active: $active }) {
      id
      active
    }
  }
`;

export const UPDATE_APPOINTMENT_TARGET = gql`
  mutation UpdateStoreAppointmentTarget($id: Int!, $appointment_target: Int!) {
    update_stores_by_pk(
      pk_columns: { id: $id }
      _set: { appointment_target: $appointment_target }
    ) {
      id
      appointment_target
    }
  }
`;
export const GET_STORES = gql`
  query getStores {
    stores(order_by: { created_at: asc }) {
      name
      id
    }
  }
`;
export const GET_STORE_BOT_OPTION = gql`
  query StoreBotOption($_eq: Int!) {
    store_bot_option_by_pk(store_id: $_eq) {
      store_id
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      pause
      sendout_interval_minutes
      send_limit
      start_sms_hours
      finish_sms_hours
      out_office_message
      out_office_enabled
    }
  }
`;

export const GET_STORE_VEHICLE_PREF = gql`
  query StoreVehiclePreference($store_id: Int!) {
    store_vehicle_preference_by_pk(store_id: $store_id) {
      store_id
      store {
        id
        country
      }
      max_km
      max_price
      max_year
      min_km
      min_price
      min_year
      max_mi
      min_mi
      makes
    }
  }
`;
