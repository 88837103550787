import React from 'react';

import Input from './Input';
import { Icon } from './icon';
import { ButtonIcon } from './button';
import { useDebouncedState } from 'lib';

const InputSearch = ({
  minLength = 0,
  onChange,
  value: valueProp,
  ...props
}) => {
  const handleChange = (val) => {
    if (!val.length || val.length >= minLength) {
      onChange?.(val);
    }
  };

  const [value, setValue] = useDebouncedState(valueProp, {
    onChange: handleChange,
  });

  return (
    <Input
      {...props}
      onChange={(e) => setValue(e.target.value)}
      onFocus={(e) => e.target.select()}
      pre={<Icon icon="search" />}
      post={
        value && (
          <ButtonIcon
            icon="close"
            onClick={() => setValue('')}
            variant="secondary"
          />
        )
      }
      value={value}
    />
  );
};

export default InputSearch;
