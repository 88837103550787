import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const IconSpinner = (props) => (
  <IconSpinnerStyled
    {...props}
    xmlns="http://www.w3.org/2000/IconSpinnerStyled"
    fill="none"
    height="24"
    width="24"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </IconSpinnerStyled>
);

IconSpinner.propTypes = {
  isReverse: PropTypes.bool,
};

export default IconSpinner;

const IconSpinnerStyled = styled.svg`
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  circle {
    ${({ isReverse, theme }) =>
      css`
        stroke: ${isReverse ? theme.colors.primaryText : theme.colors.primary};
      `}
  }

  path {
    ${({ theme }) =>
      css`
        fill: ${theme.colors.base};
      `}
  }
`;
