import React from 'react';
import { useHistory } from 'react-router-dom';

import Flex from './Flex';
import Logo from './Logo';
import MainLayoutNavigation from './MainLayoutNavigation';
import { useWindowSpec } from 'lib';

const MainLayoutHeader = () => {
  const history = useHistory();
  const { checkResponsive } = useWindowSpec();
  const isResponsive = checkResponsive('sm');

  const handleLogoClick = () => history.push('/');

  return (
    <Flex
      bg="baseLight"
      alignItems="center"
      justifyContent="space-between"
      px="lg"
      py={isResponsive ? 'xs' : 'lg'}
      sx={{
        borderBottomColor: 'base',
        borderBottomStyle: 'solid',
        borderBottomWidth: 'md',
        position: 'relative',
      }}
    >
      <Logo onClick={handleLogoClick} />
      <MainLayoutNavigation isResponsive={isResponsive} />
    </Flex>
  );
};

export default MainLayoutHeader;
