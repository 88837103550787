import React, { forwardRef } from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Scrollable = forwardRef(({ sx, isFullHeight, ...props }, ref) => (
  <ScrollableStyled
    {...props}
    {...(isFullHeight && { height: 'full' })}
    ref={ref}
    sx={{
      ...sx,
      overflow: 'auto',
    }}
  />
));

export default Scrollable;

const ScrollableStyled = styled(Box)`
  --drvtrd-scrollable-sz: 0.375rem;
  --drvtrd-scrollable-len: 1.5rem;
  --drvtrd-scrollable-ff-wid: thin;
  --drvtrd-scrollable-track-clr: transparent;

  ${({ theme }) => css`
    --drvtrd-scrollable-clr: ${theme.colors.base};
    --drvtrd-scrollable-clr-hov: ${theme.colors.baseDark};
    --drvtrd-scrollable-clr-act: ${theme.colors.baseDark};
  `}

  overscroll-behavior: contain;

  &--overscroll {
    overscroll-behavior: auto;
  }

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: var(--drvtrd-scrollable-ff-wid);
  scrollbar-color: var(--drvtrd-scrollable-clr)
    var(--drvtrd-scrollable-track-clr);

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: var(--drvtrd-scrollable-sz);
    width: var(--drvtrd-scrollable-sz);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--drvtrd-scrollable-track-clr);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--drvtrd-scrollable-clr);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--drvtrd-scrollable-clr-hov);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: var(--drvtrd-scrollable-clr-act);
  }

  &::-webkit-scrollbar-thumb:vertical {
    min-height: var(--drvtrd-scrollable-len);
  }

  &::-webkit-scrollbar-thumb:horizontal {
    min-width: var(--drvtrd-scrollable-len);
  }
`;
