import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Switch = forwardRef(({ name, isOn, handleToggle }, ref) => (
  <>
    <SwitchInput
      checked={isOn}
      id={name}
      name={name}
      onChange={handleToggle}
      ref={ref}
      type="checkbox"
    />
    <SwitchLabel htmlFor={name} isOn={isOn}>
      <SwitchButton isOn={isOn} />
    </SwitchLabel>
  </>
));

export default Switch;

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  display: none;

  &:checked + label span {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: ${({ theme, isOn }) =>
    isOn ? theme.colors.primary : theme.colors.base};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

const SwitchButton = styled.span`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  transition: 0.2s;

  ${({ theme, isOn }) => css`
    background: ${isOn ? theme.colors.primaryText : theme.colors.white};
    box-shadow: 0 0 2px 0 ${theme.colors.baseDark};
  `}
`;
