import React, { useState } from 'react';

import VehicleActionsAssignUserModal from '../VehicleActionsAssignUserModal';
import { Button, Flex, Text } from 'ui';

const MessageAlertOwner = ({ hasOwnerId, vehicleId, ownerId, storeId }) => {
  const [showAssignUser, setShowAssignUser] = useState(false);

  return (
    <>
      {!hasOwnerId && (
        <Flex
          alignItems="center"
          bg="primary"
          justifyContent="space-between"
          p="lg"
          rowResponsive={{
            screen: 'xl',
            gap: 'md',
          }}
        >
          <Text color="primaryText">Please assign to a user first</Text>
          <Button
            onClick={() => setShowAssignUser(true)}
            size="xs"
            text="Assign"
            variant="secondary"
          />
        </Flex>
      )}
      <VehicleActionsAssignUserModal
        isOpen={showAssignUser}
        onClose={() => setShowAssignUser(false)}
        ownerId={ownerId}
        storeId={storeId}
        vehicleId={vehicleId}
      />
    </>
  );
};

export default MessageAlertOwner;
