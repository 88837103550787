import { gql } from '@apollo/client';

export const CREATE_NEW_NOTE = gql`
  mutation CreateNewNote($id: Int!, $notes: String) {
    update_vehicles_by_pk(pk_columns: { id: $id }, _set: { notes: $notes }) {
      id
      notes
    }
  }
`;
