import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Routes } from '../routes';
import Flex from './Flex';
import { ButtonIcon, ButtonText } from './button';
import { useEventListener } from 'lib';

const MainLayoutNavigation = ({ isResponsive }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const menuRef = useRef(null);
  const menuToggleRef = useRef(null);

  const handleNavigationChange = ({ path }) => history.push(path);
  const handleMenuToggle = () => setIsOpen(!isOpen);
  const handleExternalClick = (event) => {
    if (
      !menuToggleRef.current?.contains(event.target) &&
      !menuRef.current?.contains(event.target)
    ) {
      handleMenuToggle();
    }
  };

  useEventListener('click', handleExternalClick, {
    isMuted: !isOpen,
    deps: [menuRef, menuToggleRef],
  });

  const renderedMenuItems = Routes.map(({ path, text }) => (
    <ButtonText
      isActive={pathname === path}
      key={path}
      onClick={() => handleNavigationChange({ path, text })}
      text={text}
    />
  ));

  if (isResponsive) {
    return (
      <>
        <ButtonIcon
          ref={menuToggleRef}
          icon="menu"
          onClick={handleMenuToggle}
          title="Show Menu Items"
          variant="secondary"
        />
        {isOpen && (
          <Flex
            ref={menuRef}
            width="full"
            flexDirection="column"
            p="lg"
            sx={{
              position: 'absolute',
              left: 0,
              top: '100%',
              boxShadow: 'md',
              zIndex: 1,
            }}
            bg="baseLight"
          >
            {renderedMenuItems}
          </Flex>
        )}
      </>
    );
  }

  return (
    <Flex justifyContent="space-around" alignItems="center">
      {renderedMenuItems}
    </Flex>
  );
};

export default MainLayoutNavigation;
