import { useEffect } from 'react';

/**
 * Attach then cleanup an element's event listener
 *
 * @param {string} type - Event type
 * @param {function} listener - Event handler callback function
 * @param {object} ref - Element to attach event listener, defaults to window
 */
export const useEventListener = (
  type,
  listener,
  { ref, deps = [], isMuted } = {}
) => {
  useEffect(() => {
    if (isMuted) {
      return;
    }

    const target = ref?.current ?? window;
    const handler = (event) => listener(event);

    target.addEventListener(type, handler);
    return () => target.removeEventListener(type, handler);
  }, [type, ref, isMuted, ...deps]);
};
