import React, { useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { uniq } from 'ramda';
import styled from '@emotion/styled';

import VehicleActions from '../VehicleActions';
import ContactsLoader from './ContactsLoader';
import ContactsFilter from './ContactsFilter';
import { useSelection } from 'lib';
import {
  Box,
  ButtonText,
  CardVehicle,
  ErrorPlaceholder,
  Flex,
  InputSearch,
  Scrollable,
} from 'ui';

const convertTagsToOptions = (tags) => {
  const all = [];
  const valid = tags.stores.filter((t) => {
    return t.tags !== null;
  });

  valid.forEach((t) => {
    t.tags.forEach((x) => {
      all.push(x);
    });
  });

  return uniq(all).map((x) => {
    return { label: x, value: x };
  });
};

const convertStoreToOptions = (stores) => {
  return stores.stores.map((x) => {
    return { label: x.name, value: x.id };
  });
};

const convertAgentsToOptions = (agents_with_vehicles) => {
  const agents = agents_with_vehicles.map((x) => {
    return { label: x.name, value: x.id };
  });
  return [{ label: 'Unassigned', value: 'Unassigned' }, ...agents];
};

const options = [
  { label: 'Active', value: 'active' },
  { label: 'Appointment', value: 'appointment' },
  { label: 'Deployed to Compliance', value: 'deployed_compliance' },
  { label: 'Dismissed', value: 'dismissed' },
  { label: 'Disqualified', value: 'disqualified' },
  { label: 'Follow Up', value: 'follow_up' },
  { label: 'In CRM', value: 'sent_to_crm' },
  { label: 'Inspection Complete', value: 'inspection_completed' },
  { label: 'Not contacted', value: 'dormant' },
  { label: 'Unavailable', value: 'unavailable' },
];

const orderOptions = [
  { label: 'Newest', value: 'desc_nulls_last' },
  { label: 'Oldest', value: 'asc_nulls_last' },
];

export const Contacts = ({
  error,
  handleLoadMoreVehicles,
  handleSearch,
  isActive,
  isResponsive,
  loading,
  onNext,
  order,
  query,
  setOrder,
  setStatus,
  setStore,
  setTag,
  setAgent,
  agent,
  agents,
  status,
  store,
  stores,
  tag,
  tags,
  vehicles = [],
  users = [],
}) => {
  const containerRef = useRef(null);
  const params = useParams();
  const vehicleId = Number(params.vehicle_id);
  const [selected, { onShiftClick, checkSelected, onSelectClear }] =
    useSelection();

  const setQueryChangeHandler =
    (func) =>
    (...args) => {
      onSelectClear();
      func(...args);
    };

  return (
    <Flex
      flexDirection="column"
      height="full"
      bg="baseLight2"
      display={isResponsive && !isActive && 'none !important'}
      sx={{
        borderRightWidth: 'md',
        borderRightStyle: 'solid',
        borderRightColor: 'base',
      }}
    >
      <Flex flexDirection="column" alignItems="center">
        <Box px="lg" pt="lg" pb="sm" width="100%">
          <Box width="33%">
            <ContactsFilter
              isClearable
              mr="sm"
              name="Agent"
              menuTitle="Filter by Agent"
              onChange={setQueryChangeHandler(setAgent)}
              options={agents && convertAgentsToOptions(agents)}
              placeholder="Agent"
              containerRef={containerRef}
              value={agent}
            />
          </Box>
          <InputSearch
            value={query}
            onChange={setQueryChangeHandler(handleSearch)}
            minLength={3}
            mb="md"
            mt="md"
          />
          <Flex
            ref={containerRef}
            rowResponsive={{
              gap: 'sm',
              screen: 'xl',
            }}
          >
            <Flex flex="1">
              <ContactsFilter
                isClearable
                mr="sm"
                name="Store"
                menuTitle="Filter by Store"
                onChange={setQueryChangeHandler(setStore)}
                options={stores && convertStoreToOptions(stores)}
                placeholder="Store"
                containerRef={containerRef}
                value={store}
              />
              <ContactsFilter
                isClearable
                name="Status"
                menuTitle="Filter by Status"
                onChange={setQueryChangeHandler(setStatus)}
                options={options}
                placeholder="Status"
                containerRef={containerRef}
                value={status}
              />
            </Flex>
            <Flex flex="1">
              <ContactsFilter
                isClearable
                mr="sm"
                name="Tag"
                menuTitle="Filter by Tag"
                onChange={setQueryChangeHandler(setTag)}
                options={tags && convertTagsToOptions(tags)}
                placeholder="Tag"
                containerRef={containerRef}
                value={tag}
              />
              <ContactsFilter
                isClearable
                name="Order"
                menuTitle="Sort by"
                onChange={setQueryChangeHandler(setOrder)}
                options={orderOptions}
                placeholder="Order"
                containerRef={containerRef}
                value={order}
              />
            </Flex>
          </Flex>
          {selected.length >= 1 && (
            <Flex mt="md" justifyContent="flex-end">
              <ButtonText
                text={`Clear (${selected.length})`}
                onClick={onSelectClear}
                size="sm"
              />
              <VehicleActions vehicles={selected.map((val) => ({ id: val }))} />
            </Flex>
          )}
        </Box>
      </Flex>
      <Scrollable
        id="infinte-scroll-container"
        minHeight="0"
        pl="lg"
        pr="md"
        py="xs"
        sx={{ flex: '1' }}
      >
        {error && !loading && (
          <ErrorPlaceholder
            headline="Failed to load vehicles"
            message="Try to setup filters to narrow down results"
          />
        )}
        <InfiniteScroll
          dataLength={!vehicles ? 20 : vehicles}
          hasMore
          id="contacts-scroll"
          next={!loading && handleLoadMoreVehicles}
          onScroll={(e) => localStorage.setItem('scroll', e.target.scrollTop)}
          scrollableTarget="infinte-scroll-container"
          scrollThreshold={0.8}
        >
          {vehicles &&
            vehicles.map((vehicle) => {
              const ownerName =
                users.find(({ id }) => id === vehicle.owner_id)?.name ?? '';

              return (
                <LinkStyled
                  tabIndex="-1"
                  onClick={(e) => {
                    onShiftClick(e, vehicle.id);

                    if (!e.getModifierState('Shift')) {
                      onNext(e);
                    }
                  }}
                  to={(location) => ({
                    ...location,
                    pathname: `/chat/contact/${vehicle.contact_id}/vehicle/${vehicle.id}`,
                  })}
                  key={vehicle.id}
                  style={{
                    textDecoration: 'none',
                    color: 'unset',
                    width: '100%',
                  }}
                >
                  <CardVehicle
                    {...vehicle}
                    isSelected={vehicleId === vehicle.id}
                    isMultiSelected={checkSelected(vehicle.id)}
                    viewingStatus={status}
                    ownerName={ownerName}
                  />
                </LinkStyled>
              );
            })}
          <ContactsLoader isLoading={loading} isClean={!vehicles} />
        </InfiniteScroll>
      </Scrollable>
    </Flex>
  );
};

const LinkStyled = styled(Link)`
  &:focus-visible {
    outline: none;
  }
`;
