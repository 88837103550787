import React from 'react';

import VehicleActions from '../VehicleActions';
import { Box, ButtonIcon, Flex, Text } from 'ui';
import { useWindowSpec } from 'lib';

const MessagesTitleBar = ({
  vehicle,
  contactHandle,
  hasResponsiveControls,
  onBack,
  onNext,
  handleMoveTo,
  toggle,
}) => {
  const { checkResponsive } = useWindowSpec();
  const isResponsive = checkResponsive('xl');

  const { mobile, name } = vehicle?.contact ?? {};
  const contactName = contactHandle || name;

  const renderedName = contactName && (
    <Text weight="semiBold" isOverflow>
      {contactName}
    </Text>
  );

  const renderedMobile = mobile && (
    <Text weight="semiBold" isOverflow>
      {mobile}
    </Text>
  );

  const renderedVehicleActions = vehicle && (
    <VehicleActions
      handleMoveTo={handleMoveTo}
      hasAssignUser
      onScheduleClick={toggle}
      vehicles={[
        {
          id: vehicle?.id,
          storeId: vehicle?.store?.id,
          storeName: vehicle?.store?.name,
          ownerId: vehicle?.owner_id,
        },
      ]}
    />
  );

  const containerStyle = {
    p: 'lg',
    sx: {
      borderBottomWidth: 'md',
      borderBottomStyle: 'solid',
      borderBottomColor: 'base',
      backgroundColor: 'baseLight2',
    },
    minHeight: 80,
  };

  if (isResponsive && hasResponsiveControls) {
    return (
      <Flex {...containerStyle} flexDirection="column">
        <Flex>
          {hasResponsiveControls && (
            <>
              <ButtonIcon
                icon="arrow-left"
                onClick={onBack}
                title="Back to Contacts List"
                variant="secondary"
              />
              <ButtonIcon
                icon="info"
                onClick={onNext}
                title="View Vehicle Details"
                variant="secondary"
                ml="auto"
              />
            </>
          )}
          <Box ml={!hasResponsiveControls && 'auto'}>
            {renderedVehicleActions}
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          horizontalGap="xs"
          p="xs"
        >
          {renderedName}
          {renderedMobile}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      {...containerStyle}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        flexDirection="column"
        sx={{ textAlign: 'left', flex: '1' }}
        verticalGap="xs"
        marginLeft="xs"
        marginRight="xs"
      >
        {renderedName}
        {renderedMobile}
      </Flex>
      {renderedVehicleActions}
    </Flex>
  );
};

export default MessagesTitleBar;
