import React from 'react';
import ReactTimeAgo from 'react-time-ago';

import {
  Box,
  Flex,
  Text,
  Image as RebassImage,
  Badge,
  Icon as IconUI,
} from 'ui';
import { displayCurrency } from 'lib';
import calendarIcon from 'assets/icons/calendar.svg';
import odometer from 'assets/icons/oddometer.svg';

const CardVehicle = ({
  appraised_price,
  asking_price,
  contact_handle,
  contact,
  isMultiSelected,
  isSelected,
  km,
  make,
  message_last,
  mi,
  model,
  ownerName,
  source_platform,
  store: { country },
  tags,
  year,
}) => {
  const isUSStore = country === 'US';
  const isWidgetLead = ['website', 'widget'].includes(source_platform);

  const renderName = () => {
    if (contact_handle || contact?.name) {
      return (
        <CardVehicleText isReverse={isSelected} isOverflow>
          {contact_handle || contact?.name}
        </CardVehicleText>
      );
    }
    return (
      <CardVehicleText
        style={{ textTransform: 'capitalize' }}
        isReverse={isSelected}
        isOverflow
      >
        {`${contact?.first_name} ${contact?.last_name}`.toLowerCase()}
      </CardVehicleText>
    );
  };

  return (
    <CardVehicleBox
      isMultiSelected={isMultiSelected}
      isSelected={isSelected}
      message_last={message_last}
      sx={{ cursor: 'pointer' }}
    >
      <Flex
        flexDirection="column"
        justifyContent="start"
        mr="auto"
        verticalGap="xs"
      >
        {renderName()}
        <CardVehicleText isReverse={isSelected} weight="semiBold" isOverflow>
          {make} {model}
        </CardVehicleText>
        <Flex>
          <Flex alignItems="center" mr="md">
            <Icon src={calendarIcon} />
            <CardVehicleText isReverse={isSelected}>{year}</CardVehicleText>
          </Flex>
          <Flex alignItems="center">
            <Icon src={odometer} />
            <CardVehicleText isReverse={isSelected}>
              {Number(isUSStore ? mi : km).toLocaleString()}
            </CardVehicleText>
          </Flex>
        </Flex>
        <Flex
          sx={{
            flexFlow: 'wrap',
            // TODO use negative themed props
            mb: -6,
            mr: -6,
          }}
        >
          {tags?.map((x, i) => (
            <Badge key={i} mr="xs" name={x} isReverse={isSelected} mb="xs" />
          ))}
        </Flex>
      </Flex>
      <Flex
        alignItems="flex-end"
        flexDirection="column"
        textAlign="right"
        verticalGap="xs"
      >
        <LastMessageAt message_last={message_last} isReverse={isSelected} />
        <CardVehicleText isReverse={isSelected}>
          {displayCurrency(asking_price)}
        </CardVehicleText>
        <PriceDifference
          appraised_price={appraised_price}
          asking_price={asking_price}
          isReverse={isSelected}
        />
        {message_last && (
          <MessageNotification
            isReverse={isSelected}
            isSelected={isSelected}
            last_message_direction={message_last.direction}
            seen={message_last.seen}
          />
        )}
        {isWidgetLead && (
          <Flex>
            {isWidgetLead && (
              <IconUI
                color="important"
                icon="flag"
                title="Widget or Website Lead"
              />
            )}
            <CardVehicleOwner ownerName={ownerName} />
          </Flex>
        )}
      </Flex>
    </CardVehicleBox>
  );
};

export default CardVehicle;

const CardVehicleOwner = ({ ownerName = '' }) => {
  if (!ownerName) {
    return null;
  }

  const [firstName, lastName] = ownerName.split(' ');

  // If last name is provided, use first letters of both first and last name
  // Otherwise, use first two letters of first name
  const initials = lastName
    ? `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`
    : firstName.substring(0, 2);

  return (
    <Flex
      width={22}
      height={22}
      alignItems="center"
      justifyContent="center"
      bg="baseDark"
      sx={{ borderRadius: 50 }}
    >
      <Text
        size="xs"
        color="dark"
        isUppercase
        title={`Assigned to ${ownerName}`}
      >
        {initials}
      </Text>
    </Flex>
  );
};

const CardVehicleText = ({ isReverse, ...props }) => (
  <Text {...props} color={isReverse ? 'primaryText' : 'dark'} />
);

const Icon = ({ src }) => (
  <Flex
    minWidth="24px"
    minHeight="24px"
    color="black"
    alignItems="center"
    justifyContent="center"
    mr={1}
    backgroundColor="primary"
    sx={{
      borderRadius: '50%',
    }}
  >
    <RebassImage width="50%" height="50%" src={src} />
  </Flex>
);

const MessageNotification = ({
  seen,
  last_message_direction,
  isSelected,
  isReverse,
}) => {
  return (
    last_message_direction === 'inbound' &&
    !seen &&
    !isSelected && (
      <Box
        bg={isReverse ? 'primaryText' : 'success'}
        height={14}
        mr="2xs"
        sx={{ borderRadius: 50 }}
        width={14}
      />
    )
  );
};

const PriceDifference = ({ appraised_price, asking_price, isReverse }) => {
  const difference = asking_price - appraised_price;
  const within20 = asking_price * 0.2 > difference;
  const percent = (difference / ((asking_price + appraised_price) / 2)) * 100;

  if (within20) {
    return (
      <CardVehicleText isReverse={isReverse}>
        &uarr;{Math.round(percent)}%
      </CardVehicleText>
    );
  }

  if (!within20 && appraised_price) {
    return (
      <CardVehicleText isReverse={isReverse}>
        &darr;{Math.round(percent)}%
      </CardVehicleText>
    );
  }

  return null;
};

export const CardVehicleBox = ({
  children,
  isMultiSelected,
  isSelected,
  sx,
  ...props
}) => {
  const selectedSx = isSelected
    ? {
        cursor: 'default',
        backgroundColor: 'primary',
        borderColor: 'primary',
      }
    : {
        cursor: 'pointer',
        backgroundColor: 'base',
        borderColor: 'base',
        ':hover': {
          backgroundColor: 'baseLight',
        },
      };

  const multiSelectedSx = isMultiSelected && {
    borderColor: 'black',
    borderLeftWidth: 'lg',
  };

  return (
    <Flex
      {...props}
      mb="md"
      p="sm"
      minHeight={105}
      sx={{
        borderWidth: 'md',
        borderStyle: 'solid',
        borderRadius: 'md',
        ...selectedSx,
        ...multiSelectedSx,
        ...sx,
      }}
    >
      {children}
    </Flex>
  );
};

const LastMessageAt = ({ message_last, isReverse }) => {
  // TODO don't display time ago if message last null, needs ui work and there's a bug
  // it doesn't hightlight the first contact in the list
  if (!message_last?.dispatch_at) {
    return null;
  }

  return (
    <CardVehicleText isReverse={isReverse} size="xs">
      <ReactTimeAgo date={new Date(message_last.dispatch_at)} locale="en-US" />
    </CardVehicleText>
  );
};
