export const roundMiles = (miles) => {
  return miles / 1000 >= 100 ? `${Math.round(miles / 1000)} K` : `${miles} mi`;
};

export const kmToMiles = (km) => {
  return Math.round(Number(km) / 1.609);
};

export const milesToKm = (miles) => {
  return Math.round(Number(miles) * 1.609);
};
