import React, { useState, useEffect } from 'react';

import { Login } from './Login';
import { useWindowSpec } from 'lib';
import { Box, MainLayout } from 'ui';
import {
  ContactsContainer,
  ContactVehiclesContainer,
  MessagesContainer,
} from 'containers';

const Panel = {
  Contacts: 1,
  Messages: 2,
  Details: 3,
};

export const MainPage = () => {
  const [activePanel, setActivePanel] = useState(Panel.Contacts);
  const { checkResponsive } = useWindowSpec();
  const isMobileView = checkResponsive('lg');
  const isEqualGrid = checkResponsive('xl');

  useEffect(() => setActivePanel(Panel.Contacts), [isMobileView]);

  if (!localStorage.getItem('token')) {
    return <Login />;
  }

  const handleBack = () => isMobileView && setActivePanel(activePanel - 1);
  const handleNext = () => isMobileView && setActivePanel(activePanel + 1);

  return (
    <MainLayout>
      <Box
        sx={{
          height: '100%',
          ...(!isMobileView && {
            display: 'grid',
            gridTemplateColumns: isEqualGrid
              ? 'repeat(3, 1fr)'
              : 'minmax(128px, 0.7fr) minmax(128px, 1.6fr) minmax(128px, 0.7fr)',
            gridTemplateRows: '100%',
          }),
        }}
      >
        <ContactsContainer
          isActive={activePanel === Panel.Contacts}
          isResponsive={isMobileView}
          onNext={handleNext}
        />
        <MessagesContainer
          isActive={activePanel === Panel.Messages}
          isResponsive={isMobileView}
          onBack={handleBack}
          onNext={handleNext}
        />
        <ContactVehiclesContainer
          isActive={activePanel === Panel.Details}
          isResponsive={isMobileView}
          onBack={handleBack}
        />
      </Box>
    </MainLayout>
  );
};
