import { gql } from '@apollo/client';

export const USED_VEHICLES = gql`
  query UsedVehicles(
    $country: CountryEnum = UNITED_STATES
    $uvc: String
    $make: String
    $model: String
    $trim: String
    $style: String
    $year: String
    $mileage: Int = 0
  ) {
    usedvehicles(
      make: $make
      model: $model
      series: $trim
      style: $style
      year: $year
      country: $country
      uvc: $uvc
      mileage: $mileage
    ) {
      usedvehicles {
        adjusted_whole_avg
        adjusted_whole_clean
        adjusted_whole_rough
        adjusted_whole_xclean
        # mileage_cat
        # msrp
        # mileage_retail_avg
        # price_includes
      }
    }
  }
`;
