import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SelectRS, { components as componentsRS } from 'react-select';
import { useTheme } from 'emotion-theming';

import FormControl from './FormControl';
import { Icon } from './icon';

const Select = forwardRef(
  (
    {
      components = {},
      defaultValue,
      error,
      id,
      inputValue,
      isClearable = false,
      isMulti,
      label,
      menuIsOpen,
      name,
      onChange,
      onInputChange,
      onKeyDown,
      options,
      placeholder = '',
      styles,
      value,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <FormControl {...props} label={label} error={error} disabled>
        <SelectRS
          components={{
            ClearIndicator: SelectClearIndicator,
            DropdownIndicator: SelectDropDownIndicator,
            ...components,
          }}
          defaultValue={defaultValue}
          id={id}
          inputValue={inputValue}
          isClearable={isClearable}
          isMulti={isMulti}
          menuIsOpen={menuIsOpen}
          menuPortalTarget={document.body}
          name={name}
          onChange={onChange}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          options={options}
          placeholder={placeholder}
          ref={ref}
          styles={{ ...SelectStyles, ...styles }}
          theme={theme}
          value={value}
        />
      </FormControl>
    );
  }
);

Select.propTypes = {
  ...FormControl.propTypes,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default Select;

const SelectDropDownIndicator = (iconProps) => (
  <componentsRS.DropdownIndicator {...iconProps}>
    <Icon icon="down" />
  </componentsRS.DropdownIndicator>
);

const SelectClearIndicator = ({ innerProps: { ref, ...restInnerProps } }) => (
  <div {...restInnerProps} ref={ref}>
    <Icon icon="close" />
  </div>
);

const setSelectTextStyles = (theme) => ({
  fontFamily: theme.fonts.primary,
  fontStyle: 'normal',
  fontWeight: theme.fontWeights.normal,
  fontSize: theme.fontSizes.md,
  letterSpacing: 'normal',
  lineHeight: 'normal',
});

const SelectStyles = {
  container: (s) => ({
    ...s,
    width: '100%',
  }),
  singleValue: (s, { selectProps: { theme } }) => ({
    ...s,
    color: theme.colors.black,
    ...setSelectTextStyles(theme),
  }),
  multiValue: (s, { selectProps: { theme } }) => ({
    ...s,
    fontSize: theme.fontSizes.sm,
    backgroundColor: theme.colors.primary,
    color: theme.colors.primaryText,
    borderRadius: theme.radii.md,
    padding: theme.space.xs,
    textTransform: 'uppercase',
  }),
  input: (s, { selectProps: { theme } }) => ({
    ...s,
    color: theme.colors.black,
    ...setSelectTextStyles(theme),
  }),
  control: (s, { isFocused, isSelected, isMulti, selectProps: { theme } }) => ({
    ...s,
    minWidth: 100,
    // boxShadow - remove blue border
    boxShadow: 'none',
    borderRadius: theme.radii.md,
    borderWidth: theme.borderWidths.md,
    borderStyle: 'solid',
    borderColor: theme.colors.baseDark,
    backgroundColor: theme.colors.white,
    cursor: 'pointer',
    caretColor: 'transparent',
    height: theme.sizes.control,
    ...(isMulti && {
      height: 'auto',
      minHeight: theme.sizes.control,
    }),
    ...((isFocused || isSelected) && {
      borderColor: theme.colors.primary,
      borderWidth: theme.borderWidths.md,
      outline: 'none',
    }),
  }),
  placeholder: (s, { selectProps: { theme } }) => ({
    ...s,
    color: theme.colors.baseDark,
    fontFamily: theme.fonts.primary,
    fontStyle: 'normal',
    fontWeight: theme.fontWeights.normal,
    fontSize: theme.fontSizes.md,
    letterSpacing: 'normal',
    lineHeight: 'normal',
  }),
  valueContainer: (s) => ({
    ...s,
    height: '100%',
  }),
  indicatorsContainer: (s, { selectProps: { theme } }) => ({
    ...s,
    color: theme.colors.black,
    paddingRight: theme.space.sm,
    paddingLeft: theme.space.sm,
  }),
  indicatorSeparator: (s) => ({
    ...s,
    display: 'none',
  }),
  dropdownIndicator: (s) => ({
    ...s,
    padding: 0,
  }),
  option: (s, { isSelected, isFocused, selectProps: { theme } }) => ({
    ...s,
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    cursor: 'pointer',
    padding: theme.space.md,
    ...(isSelected && {
      backgroundColor: theme.colors.primary,
      color: theme.colors.primaryText,
      cursor: 'default',
    }),
    ...(!isSelected &&
      isFocused && {
        backgroundColor: theme.colors.baseLight,
      }),
  }),
  menu: (s, { selectProps: { theme } }) => ({
    ...s,
    backgroundColor: theme.colors.white,
    borderRadius: theme.radii.md,
    boxShadow: `${theme.colors.baseDark} 0 0 1px, ${theme.colors.baseDark2} 0 8px 10px -4px`,
    margin: 0,
    overflow: 'hidden',
  }),
  menuList: (s) => ({
    ...s,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  noOptionsMessage: (s, { selectProps: { theme } }) => ({
    ...s,
    color: theme.colors.black,
  }),
};
