import { useLayoutEffect } from 'react';
import { useReactiveVar, makeVar } from '@apollo/client';

const colorVar = makeVar();

export const useColor = () => {
  const color = useReactiveVar(colorVar);

  useLayoutEffect(() => {
    const browserColor = localStorage.getItem('color');
    colorVar(browserColor);
  }, []);

  const setCurrentColor = (newColor) => {
    localStorage.setItem('color', newColor);
    colorVar(newColor);
  };

  return [color, setCurrentColor];
};
