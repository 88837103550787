import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as DrivablyLogo } from 'assets/images/logo.svg';

const Logo = ({ onClick }) => <LogoStyled width={100} onClick={onClick} />;

export default Logo;

const LogoStyled = styled(DrivablyLogo)`
  fill: ${({ theme }) =>
    theme.colorMode === 'dark' ? theme.colors.black : theme.colors.primary};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;
