import React, { useState, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';

import { useAssignedAgentsQuery } from '../hooks';
import { Alert, Modal, Select } from 'ui';

const VehicleActionsAssignUserModal = ({
  isOpen,
  onClose,
  ownerId: ownerIdProp,
  storeId,
  vehicleId,
}) => {
  const [alertDetails, setAlertDetails] = useState({});
  const [ownerId, setOwnerId] = useState(ownerIdProp);
  const { usersByStore } = useAssignedAgentsQuery({ storeId });
  const [updateOwner, { loading }] = useMutation(VehicleUpdateOwnerGql, {
    onCompleted: () => {
      setAlertDetails({
        message: 'Assigned to user successfully',
        type: 'success',
      });
      onClose?.();
    },
    onError: () =>
      setAlertDetails({
        message: 'Failed to assign user',
        type: 'error',
      }),
  });

  useEffect(() => setOwnerId(ownerIdProp), [ownerIdProp]);

  const options = usersByStore.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const selectedOption = options.filter(({ value }) => value === ownerId);

  const handleConfirm = () => {
    updateOwner({
      variables: {
        id: vehicleId,
        owner_id: ownerId,
      },
    });
  };

  return (
    <>
      <Alert message={alertDetails.message} type={alertDetails.type} />
      <Modal
        cancel="Close"
        confirm="Save"
        header="Assign to User"
        isConfirmDisabled={!ownerId}
        isLoading={loading}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleConfirm}
      >
        <Select
          label="User"
          value={selectedOption}
          onChange={(e) => setOwnerId(e.value)}
          options={options}
        />
      </Modal>
    </>
  );
};

export default VehicleActionsAssignUserModal;

const VehicleUpdateOwnerGql = gql`
  mutation VehicleUpdateOwner($id: Int!, $owner_id: Int!) {
    update_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: { owner_id: $owner_id }
    ) {
      id
      owner_id
    }
  }
`;
