import { ButtonStyle, ButtonIconStyle } from 'ui';

export const ColorMode = {
  Light: 'light',
  Dark: 'dark',
};

const ColorLight = {
  // brand
  primary: '#2159f2',
  primaryDark: '#192d75',
  primaryLight: '#4073fd',
  primaryText: '#fff',
  // monochrome
  base: '#e6e6e6',
  baseAlpha: '#e6e6e68e',
  baseDark: '#c4c4c4',
  baseDark2: '#898989',
  baseLight: '#f0f0f0',
  baseLight2: '#f8f8f8',
  black: '#333',
  blackAlpha: '#33333380',
  white: '#fff',
  // status
  error: '#f33',
  errorDark: '#801a1a',
  errorLight: '#ff4747',
  errorText: '#fff',
  pending: '#959864',
  pendingDark: '#70724b',
  pendingLight: '#a7ab71',
  pendingText: '#fff',
  success: '#4fa86a',
  successDark: '#3f8655',
  successLight: '#72b988',
  successText: '#fff',
  important: '#fffb05',
  importantDark: '#ccc904',
  importantLight: '#fffb1e',
  importantText: '#333',
};

const ColorDark = {
  // brand
  primary: '#2159f2',
  primaryDark: '#4073fd',
  primaryLight: '#192d75',
  primaryText: '#fff',
  // monochrome
  base: '#5c5c5c',
  baseAlpha: '#5c5c5c8e',
  baseDark: '#6c6c6c',
  baseDark2: '#7d7d7d',
  baseLight: '#404040',
  baseLight2: '#2e2e2e',
  black: '#fff',
  blackAlpha: '#ffffff80',
  white: '#333',
  // status
  error: '#f33',
  errorDark: '#ff4747',
  errorLight: '#801a1a',
  errorText: '#fff',
  pending: '#959864',
  pendingDark: '#a7ab71',
  pendingLight: '#70724b',
  pendingText: '#fff',
  success: '#4fa86a',
  successDark: '#72b988',
  successLight: '#3f8655',
  successText: '#fff',
  important: '#fffb05',
  importantDark: '#fffb1e',
  importantLight: '#ccc904',
  importantText: '#333',
};

export const MediaBreakpoints = {
  sm: '567px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const SpacingNormal = {
  '2xs': '3px',
  xs: '6px',
  sm: '10px',
  md: '14px',
  lg: '18px',
  xl: '30px',
  '2xl': '38px',
  '3xl': '46px',
  '4xl': '62px',
  '5xl': '74px',
};

const SpacingSmall = {
  '2xs': '3px',
  xs: '4px',
  sm: '6px',
  md: '10px',
  lg: '14px',
  xl: '24px',
  '2xl': '30px',
  '3xl': '36px',
  '4xl': '50px',
  '5xl': '60px',
};

export const getTheme = (colorMode = ColorMode.Light, hasSmallSpacing) => {
  const colors = colorMode === ColorMode.Dark ? ColorDark : ColorLight;
  const space = hasSmallSpacing ? SpacingSmall : SpacingNormal;

  return {
    colorMode,
    colors,
    space,
    fonts: {
      primary:
        'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
    },
    fontSizes: {
      '2xs': '8px',
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '20px',
      xl: '28px',
      '2xl': '40px',
    },
    fontWeights: {
      normal: '400',
      semiBold: '600',
      bold: '700',
    },
    radii: {
      md: '6px',
      lg: '10px',
    },
    borderWidths: {
      md: '1px',
      lg: '10px',
    },
    sizes: {
      full: '100%',
      control: '50px',
    },
    shadows: {
      md: `${colors.baseDark} 0 1px 3px 0, ${colors.baseDark2} 0 1px 2px 0`,
    },
    mediaBreakpoints: MediaBreakpoints,
    ...ButtonStyle,
    ...ButtonIconStyle,
  };
};
