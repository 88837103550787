import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Sync value as URL query parameter
 *
 * @param {object} props
 * @param {string} props.name - URL query param key
 * @param {function} props.onChange - Change event handler
 * @returns {function} Change handler to reflect changes on URL
 */
export const useRouteValue = ({ name, onChange, isNumber }) => {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const activeValue = searchParams.get(name);
    if (!activeValue) {
      return;
    }

    const value = isNumber ? Number(activeValue) : activeValue;
    onChange?.(value);
  }, []);

  const handleChange = (newValue) => {
    const searchParams = new URLSearchParams(search);
    const currentValue = searchParams.get(name);

    if (newValue !== currentValue) {
      if (newValue) {
        searchParams.set(name, newValue);
      } else {
        searchParams.delete(name);
      }

      history.push({ search: searchParams.toString() });
    }

    onChange?.(newValue);
  };

  return handleChange;
};
