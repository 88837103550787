import React from 'react';

const IconCar = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    viewBox="206 -206 512 512"
    height="32"
    width="32"
  >
    <g>
      <path
        d="M680.6,20.7L641.4,2.8l-34.6-55.2c-10-16-27.6-25.7-46.5-25.6H412.9c-17.1,0-33.3,7.9-43.7,21.5
		L324.5,2L250,22.4c-18.9,5.2-32,22.5-32,42.1V93c0,24.1,19.2,44,43.3,44h6.4c7.7,29.7,38,47.5,67.7,39.8
		c19.5-5.1,34.8-20.3,39.8-39.8h168c7.7,29.7,38,47.5,67.7,39.8c19.5-5.1,34.8-20.3,39.8-39.8h12c24.1,0,43.3-19.9,43.3-44V60.5
		C706.1,43.4,696.1,27.8,680.6,20.7z M321.4,158.4c-19.6,0-35.5-15.9-35.5-35.5s15.9-35.5,35.5-35.5s35.5,15.9,35.5,35.5
		c0,0,0,0,0,0C357,142.4,341.1,158.3,321.4,158.4z M596.9,158.4c-19.6,0-35.5-15.9-35.5-35.5s15.9-35.5,35.5-35.5
		c19.6,0,35.5,15.9,35.5,35.5c0,0,0,0,0,0C632.5,142.4,616.6,158.3,596.9,158.4L596.9,158.4z M686,93c0,13.1-10.2,24-23.3,24h-10.6
		c-3.3-30.5-30.8-52.5-61.3-49.1c-25.9,2.8-46.3,23.3-49.1,49.1H376.6c-3.3-30.5-30.8-52.5-61.3-49.1c-25.9,2.8-46.3,23.3-49.1,49.1
		h-5c-13.1,0-23.3-10.9-23.3-24V64.5c-0.1-10.7,7-20,17.3-22.9l77.9-21.3c2.1-0.6,4-1.8,5.3-3.6l46.6-61.1C391.7-53,402-58,412.9-58
		h147.4c12-0.1,23.3,6,29.6,16.2L626.1,16c1,1.7,2.6,3,4.3,3.8l41.8,19.1c8.4,3.9,13.8,12.3,13.7,21.6V93z"
      />
    </g>
  </svg>
);

export default IconCar;
