import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonR } from 'rebass';

import { Icon } from '../icon';

const ButtonVariant = {
  Primary: 'primary',
  Secondary: 'secondary',
  Danger: 'danger',
};

const ButtonSize = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
};

const Button = forwardRef(
  (
    {
      children,
      isDisabled,
      isLoading,
      onClick,
      size = ButtonSize.MD,
      sx,
      text,
      variant = ButtonVariant.Primary,
      ...props
    },
    ref
  ) => {
    const setButtonSize = () => {
      switch (size) {
        case ButtonSize.MD:
          return {
            fontSize: 'md',
            height: 'control',
            minWidth: 150,
          };
        case ButtonSize.SM:
          return {
            fontSize: 'sm',
            height: 45,
            minWidth: 130,
          };
        case ButtonSize.XS:
          return {
            fontSize: 'xs',
            height: 30,
            minWidth: 100,
          };
        default:
          console.error(`Unknown button size ${size}.`);
          return {};
      }
    };

    const isVoid = isDisabled || isLoading;

    return (
      <ButtonR
        {...props}
        disabled={isVoid}
        onClick={() => !isVoid && onClick?.()}
        ref={ref}
        sx={{
          ...sx,
          ...setButtonSize(),
        }}
        variant={variant}
      >
        {isLoading ? <Icon icon="spinner" /> : text}
      </ButtonR>
    );
  }
);

Button.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(ButtonVariant)),
};

export default Button;

export const ButtonStyleBase = {
  ':focus': { outline: 'none' },

  paddingTop: '0',
  paddingBottom: '0',
  paddingLeft: 'lg',
  paddingRight: 'lg',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  textTransform: 'uppercase',
  fontFamily: 'primary',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineSpacing: 'normal',

  borderWidth: 'md',
  borderStyle: 'solid',
  borderRadius: 'md',

  transition: '300ms',
  cursor: 'pointer',
};

export const ButtonStyle = {
  buttons: {
    primary: {
      ...ButtonStyleBase,
      color: 'primaryText',
      backgroundColor: 'primary',
      borderColor: 'primary',
      ':hover': {
        borderColor: 'primaryLight',
        backgroundColor: 'primaryLight',
      },
    },
    secondary: {
      ...ButtonStyleBase,
      color: 'black',
      backgroundColor: 'base',
      borderColor: 'base',
      ':hover': {
        borderColor: 'baseDark',
        backgroundColor: 'baseDark',
      },
    },
    danger: {
      ...ButtonStyleBase,
      color: 'errorText',
      backgroundColor: 'error',
      borderColor: 'error',
      ':hover': {
        borderColor: 'errorLight',
        backgroundColor: 'errorLight',
      },
    },
  },
};
