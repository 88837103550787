import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';

import { Flex, Input, Checkbox, Loader, Alert } from 'ui';

export const AssignedAgents = ({ store_id }) => {
  const [query, setQuery] = useState(null);
  const [lastAgentChanged, setLastAgentChanged] = useState(null);

  const {
    data: allUsers,
    loading: loadingAll,
    error: errorUsers,
  } = useQuery(
    gql`
      query AllUsers {
        users {
          id
          name
          user_roles {
            store_id
          }
        }
      }
    `
  );

  const [addUser, { loading: isAddUserLoading, error: errorAddUser }] =
    useMutation(
      gql`
        mutation MyMutation($user_id: Int!, $store_id: Int!, $role: String!) {
          insert_user_roles_one(
            object: { user_id: $user_id, store_id: $store_id, role: $role }
          ) {
            user_id
            store_id
          }
        }
      `,
      { refetchQueries: ['AllUsers'] }
    );

  const [removeUser, { loading: isRemoveUserLoading, error: errorRemoveUser }] =
    useMutation(
      gql`
        mutation MyMutation($store_id: Int!, $user_id: Int!) {
          delete_user_roles_by_pk(store_id: $store_id, user_id: $user_id) {
            role
            user_id
          }
        }
      `,
      { refetchQueries: ['AllUsers'] }
    );

  const users = allUsers?.users || [];

  const isAssigned = (user_id) => {
    for (const u of users) {
      for (const r of u.user_roles) {
        if (r.store_id === store_id && u.id === user_id) {
          return true;
        }
      }
    }
    return false;
  };

  const assignAction = (user_id) => {
    setLastAgentChanged(user_id);

    if (isAssigned(user_id)) {
      removeUser({ variables: { store_id, user_id } });
    } else {
      addUser({
        variables: { store_id, user_id, role: 'agent' },
      });
    }
  };

  const renderedUsers = () =>
    users
      .filter((u) => {
        if (u.name !== 'Master' && !query) {
          return true;
        } else if (u.name !== 'Master' && query) {
          const re = new RegExp(query, 'gi');

          if (u.name.match(re)) {
            return true;
          }
        }

        return false;
      })
      .map((u, index) => (
        <Flex key={index} alignItems="center">
          <Checkbox
            checked={isAssigned(u.id)}
            disabled={u.name === 'Master'}
            id={u.name}
            label={u.name}
            name={u.name}
            onChange={() => assignAction(u.id)}
          />
          {(isAddUserLoading || isRemoveUserLoading) &&
            lastAgentChanged === u.id && <Loader size="sm" ml="md" />}
        </Flex>
      ));

  return (
    <Flex flexDirection="column" height="full">
      <Alert
        message={
          errorUsers?.message ||
          errorAddUser?.message ||
          errorRemoveUser?.message
        }
        type="error"
      />
      <Input label="Filter" onChange={(e) => setQuery(e.target.value)} />
      {loadingAll ? (
        <Loader mt="lg" />
      ) : (
        <Flex flexDirection="column" mt="lg">
          {renderedUsers()}
        </Flex>
      )}
    </Flex>
  );
};
