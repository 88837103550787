import React from 'react';
import styled from '@emotion/styled';

import { Flex, Icon, Image } from 'ui';

const VehicleDetailsImage = ({
  isLoadingPhoto,
  vehicleImage,
  vehicleImageData,
}) => {
  const renderContent = () => {
    if (isLoadingPhoto) {
      return <Icon icon="spinner" />;
    }

    if (vehicleImageData) {
      return (
        <VehicleDetailsImageStyled
          src={`data:image/png;base64, ${vehicleImageData}`}
          alt="vehicle"
        />
      );
    }

    if (vehicleImage) {
      return (
        <VehicleDetailsImageAnchor
          href={vehicleImage}
          target="_blank"
          rel="noreferrer"
        >
          <VehicleDetailsImageHrefStyled
            height="100%"
            noLazyLoad
            src={vehicleImage}
            width="100%"
          />
        </VehicleDetailsImageAnchor>
      );
    }

    return <Icon icon="car" color="dark" size="lg" />;
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{
        width: 110,
        height: 110,
        flexShrink: '0',
        borderRadius: 'md',
        backgroundColor: vehicleImageData || vehicleImage ? '#fff' : 'base',
      }}
    >
      {renderContent()}
    </Flex>
  );
};

export default VehicleDetailsImage;

const VehicleDetailsImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const VehicleDetailsImageHrefStyled = styled(Image)`
  object-fit: cover;
`;

const VehicleDetailsImageAnchor = styled.a`
  width: 100%;
  height: 100%;
`;
